import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormField } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';
import { Audit, AuditCheckpoint, AuditCheckpointTextInput } from "../../../../backend_api_2";
import Icon from '../../../../base/components/basic/Icon';
import Input from '../../../../base/components/basic/Input';
import { getLocaleLanguageString } from '../../../../base/utils';
import { useAppDispatch } from "../../../../store";
import { updateCheckpointTextInputs } from "../../slices/auditSlice";
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { AuditCheckpointTitleAndActionMenu, isAuditImmutable } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';
import { doesTextInputCheckpointRequireInput } from "./mandatory";

export function TextInputsCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const { audit, checkpoint, triedToSubmit } = props;
    const dispatch = useAppDispatch();
    const requiresInput = doesTextInputCheckpointRequireInput(checkpoint);

    function updateTextInputs(index: number, newValue: string) {
        const updatedInput = {
            name: checkpoint.text_inputs.inputs[index].name, value: newValue
        }
        dispatch(updateCheckpointTextInputs({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            inputs: checkpoint.text_inputs.inputs.map((input, i) => {
                if (i === index) {
                    return updatedInput;
                }

                return input
            })
        }))
    }
    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
            {
                triedToSubmit && requiresInput &&
                <div className='text-status-rejected text-sm mb-2 flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.provide_inputs' />
                </div>
            }
            <div className='mb-4'>
                {checkpoint.text_inputs.inputs.map((input, index) =>
                    <TextInput audit={audit} index={index} input={input} doUpdate={updateTextInputs} />
                )}
            </div>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />

        </div>
    )
}

function TextInput(props: {
    audit: Audit,
    input: AuditCheckpointTextInput,
    index: number,
    doUpdate: (index: number, newValue: string) => void
}) {
    const { audit, input, index, doUpdate } = props;
    const [value, setValue] = React.useState(input.value);
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const elementId = uuidv1();

    return (
        <FormField key={index}>
            <div className='flex flex-col justify-between py-1 w-full sm:max-w-[40rem]'>
                <label className='text-sm text-secondary mb-1' htmlFor={elementId}>{input.name[lang] || input.name.C}</label>
                <Input
                    id={elementId}
                    inputClass='w-full sm:max-w-[40rem]'
                    type="text"
                    name={input.name[lang] || input.name.C}
                    value={value}
                    iconPosition="left"
                    iconClass="text-brand w-12 justify-center flex"
                    onChange={(e) => setValue(e.currentTarget.value)}
                    onBlur={e => doUpdate(index, e.currentTarget.value)}
                    disabled={isAuditImmutable(audit)}
                />
            </div>
        </FormField>
    )
}