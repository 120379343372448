import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { AuditReportCustomField, AuditReportCustomFieldsDate, AuditReportCustomFieldsDateTypeEnum, AuditReportCustomFieldsMultipleChoice, AuditReportCustomFieldsMultipleChoiceTypeEnum, AuditReportCustomFieldsNumber, AuditReportCustomFieldsNumberTypeEnum, AuditReportCustomFieldsDocumentation, AuditReportCustomFieldsDocumentationTypeEnum, AuditReportCustomFieldsDocumentationDocument, AuditReportCustomFieldsDocumentationDocumentStatusEnum, AuditReportCustomFieldsText, AuditReportCustomFieldsTextTypeEnum, SupplierDocument, SupplierDocumentExtendedStatusEnum } from '../../../../../../backend_api/models';
import Tag from '../../../../../../base/components/basic/Tag';
import { translateTextWithTranslation } from '../../../../../../base/components/basic/TextWithTranslation';
import { getPrettyDate, isDateExpired, twMerge } from '../../../../../../base/utils';
import Icon from '../../../../../../base/ui/components/icons/Icon';
import { Sizes } from '../../../../../../base/components/types';
import DocumentationTypeTooltipContent from '../../../../../../modules/supplierDocuments/components/DocumentationTypeTooltipContent';
import { ArrowTypeEnum, ListItem, ListItems } from '../../../../../../base/ui/components/listitems/ListItem';
import { useAppDispatch } from '../../../../../../store';
import { AppDispatch } from '../../../../../../store';
import { supplierDocumentsSliceActions } from '../../../../../../modules/supplierDocuments/supplierDocumentsSlice';
import { getSupplierDocumentStatusColor, renderDocumentationStateDescription } from '../../../../../productionUnits/components/SupplierDocumentItemViewMode';

export function renderCustomFieldValueRow(
    customFieldValue: AuditReportCustomField,
    locale: string | null,
    isLastItem: boolean = false
) {
    const isDocumentationType = customFieldValue.value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation;
    const dispatch = useAppDispatch();

    return (
        <tr>
            <td className={twMerge('w-[220px] gap-1', !isLastItem && 'border-b')} style={{ borderTop: 0 }}>
                <div className="flex items-center font-bold gap-1">
                    {translateTextWithTranslation(customFieldValue.name, locale)}
                    {isDocumentationType && (
                        <Popup trigger={<Icon size={Sizes.Medium} name='operation_get-info' />} on="hover" className='cursor-default'>
                            <DocumentationTypeTooltipContent documentationType={customFieldValue.value as AuditReportCustomFieldsDocumentation} />
                        </Popup>
                    )}
                </div>
            </td>
            {
                isDocumentationType
                    ?
                    renderSupplierDocumentsFieldValue(customFieldValue.value as AuditReportCustomFieldsDocumentation, dispatch, isLastItem)
                    :
                    <td className={twMerge('border-r', !isLastItem && 'border-b')} style={{ borderTop: 0 }}>
                        {renderContent(customFieldValue, locale)}
                    </td>
            }
        </tr>
    );
}

const mapToSupplierDocumentStatus = (documentation: AuditReportCustomFieldsDocumentationDocument): SupplierDocumentExtendedStatusEnum => {
    if (documentation.status == AuditReportCustomFieldsDocumentationDocumentStatusEnum.Deleted) {
        return SupplierDocumentExtendedStatusEnum.Deleted;
    }

    if (documentation.status == AuditReportCustomFieldsDocumentationDocumentStatusEnum.Archived) {
        return SupplierDocumentExtendedStatusEnum.Archived;
    }

    if (isDateExpired(documentation.expiry_date)) {
        return SupplierDocumentExtendedStatusEnum.Expired;
    }

    const status = documentation.status;
    switch (status) {
        case AuditReportCustomFieldsDocumentationDocumentStatusEnum.FileAdded:
            return SupplierDocumentExtendedStatusEnum.FileAdded;
        case AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval:
            return SupplierDocumentExtendedStatusEnum.WaitingForApproval;
        case AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved:
            return SupplierDocumentExtendedStatusEnum.Approved;
        case AuditReportCustomFieldsDocumentationDocumentStatusEnum.Rejected:
            return SupplierDocumentExtendedStatusEnum.Rejected;
    }
};



const mapToSupplierDocument = (document: AuditReportCustomFieldsDocumentationDocument, documentation_type_id: string): SupplierDocument => {
    return {
        approval_required: document.approval_required,
        approver_id: null,
        documentation_type_id: documentation_type_id,
        expiry_date: document.expiry_date,
        expiry_required: document.expiry_required,
        extended_status: mapToSupplierDocumentStatus(document),
        media_resources: document.media_resources,
        rejection_reason: null,
        rejector_id: null,
        start_date: document.start_date,
        supplier_document_id: document.supplier_document_id,
        uploader_id: null,
        permissions: document.permissions
    };
};


const handleViewDocument = (document: AuditReportCustomFieldsDocumentationDocument, documentation_type_id: string, dispatch: AppDispatch) => {
    if (document.status == AuditReportCustomFieldsDocumentationDocumentStatusEnum.Deleted) {
        return;
    }
    dispatch(supplierDocumentsSliceActions.showCustomFieldDocumentViewer({
        supplierDocument: mapToSupplierDocument(document, documentation_type_id)
    }));
}

function renderSupplierDocumentsFieldValue(value: AuditReportCustomFieldsDocumentation, dispatch: AppDispatch, isLastItem: boolean = false) {
    if (value.documents.length === 0) {
        return (
            <div className={twMerge("p-3 whitespace-nowrap text-secondary italic", !isLastItem && "border-b")}><FormattedMessage id="production_units.supplier_document.no_document_added" /></div>
        );
    }

    return (
        <ListItems className={!isLastItem && 'border-b'}>
            {value.documents.map((document, index) => renderSupplierDocument(document, value, dispatch, index == value.documents.length - 1 && value.documents.length > 1))}
        </ListItems>
    )
}

function renderSupplierDocument(reportDocument: AuditReportCustomFieldsDocumentationDocument, documentationType: AuditReportCustomFieldsDocumentation, dispatch: AppDispatch, isLastItem: boolean = false) {
    const textOnRight = reportDocument.previously_added ? <div className="flex whitespace-nowrap text-secondary p-3">
        <FormattedMessage id={"production_units.supplier_document.previously_added"} />
    </div> : <div className="flex whitespace-nowrap text-status-approved p-3">
        <Icon className='text-status-approved mt-0.5' name='callout_document-added' size={Sizes.Small} />
        <FormattedMessage id={"production_units.supplier_document.document_added"} />
    </div>

    return (
        <ListItem
            className={!isLastItem ? "border-b" : "border-b-0"}
            arrow={{ type: ArrowTypeEnum.Open }}
            slim={true}
            graphicItemClass='min-w-10'
            arrowNextToText={true}
            textOnRight={textOnRight}
        >
            <div className="flex items-center relative bg-default p-1" onClick={() => handleViewDocument(reportDocument, documentationType.documentation_type_id, dispatch)}>
                <div className="flex gap-4">
                    <div className={`${getSupplierDocumentStatusColor(reportDocument.status, false)} text-normal text-sm text-on-status px-3 py-1 flex items-center justify-center rounded-md`}>
                        <FormattedMessage id={"production_units.supplier_document.status." + reportDocument.status} />
                    </div>

                    <div className="flex items-center">
                        <span className="text-normal text-base text-primary">
                            {renderDocumentationStateDescription({ detailedSupplierDocument: reportDocument as any })}
                        </span>
                    </div>
                </div>
            </div>
        </ListItem>
    )
}

function renderContent(
    customFieldValue: AuditReportCustomField,
    locale: string | null
) {
    if (didAuditorChangeCustomFieldValue(customFieldValue)) {
        return renderWhenChanged(customFieldValue, locale);
    }
    if (didAuditorDidConfirmCustomFieldValue(customFieldValue)) {
        return renderWhenConfirmed(customFieldValue, locale);
    }
    return renderWhenNotTouched(customFieldValue, locale);
}

function renderWhenChanged(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderPreviousCustomFieldValue(customFieldValue.value, locale)}
            <span> &rarr; </span>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_changed'} />
        </Popup>
    )
}

function renderWhenNotTouched(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_not_changed'} />
        </Popup>
    )
}

function renderWhenConfirmed(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
            <span> &#10003; </span>
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_confirmed'} />
        </Popup>
    )
}

export function didAuditorDidConfirmCustomFieldValue(wrapper: AuditReportCustomField) {

    if (!wrapper.set_by_auditor) {
        return false;
    }

    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.every(option => option.selected === option.previously_selected)

    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date === value.previous_date;

    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text === value.previous_text;

    } else if (value.type === AuditReportCustomFieldsNumberTypeEnum.Number) {
        return value.number === value.previous_number;
    } else if (value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        return false // no confirmation for this custom field type
    }
}

export function didAuditorChangeCustomFieldValue(wrapper: AuditReportCustomField) {
    if (!wrapper.set_by_auditor) {
        return false;
    }
    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.some(function (option) {
            return option.previously_selected !== option.selected;
        });
    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date !== value.previous_date;
    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text !== value.previous_text;
    } else if (value.type === AuditReportCustomFieldsNumberTypeEnum.Number) {
        return value.number !== value.previous_number;
    } else if (value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        return false // no confirmation for this custom field type
    }
}

export function renderPreviousCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber | AuditReportCustomFieldsDocumentation,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.previously_selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.previous_date) {
            return (<span>{getPrettyDate(value.previous_date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.previous_text) {
            return (<span>{value.previous_text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.previous_number) {
            return (<span>{value.previous_number}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        const previousDocuments = value.documents.filter(doc => doc.previously_added);
        return (
            <div className="flex flex-col">
                {previousDocuments.length === 0 ? (
                    <span className="text-gray-500 italic">No active document</span>
                ) : (
                    previousDocuments.map((document) => (
                        <div key={document.media_resources[0].id} className="flex items-center justify-between py-1">
                            <div className="flex items-center gap-2">
                                <span>{translateTextWithTranslation(value.documentation_type_name, locale)}</span>
                                <Tag className={
                                    document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'bg-green-500 text-white' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'bg-yellow-500 text-white' :
                                            'bg-gray-500 text-white'
                                }>
                                    {document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'Approved' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'In review' :
                                            document.status}
                                </Tag>
                            </div>
                            {document.expiry_date && (
                                <div className="flex items-center gap-1">
                                    <span>Expires {getPrettyDate(document.expiry_date)}</span>
                                    <span className="text-gray-500">Previously added</span>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        );
    }

    return renderEmptyValue();
}

export function renderCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber | AuditReportCustomFieldsDocumentation,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.date) {
            return (<span>{getPrettyDate(value.date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.text) {
            return (<span>{value.text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.number) {
            return (<span>{value.number}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
        const currentDocuments = value.documents.filter(doc => !doc.previously_added);
        return (
            <div className="flex flex-col">
                {currentDocuments.length === 0 ? (
                    <span className="text-gray-500 italic">No active document</span>
                ) : (
                    currentDocuments.map((document) => (
                        <div key={document.media_resources[0].id} className="flex items-center justify-between py-1">
                            <div className="flex items-center gap-2">
                                <Tag className={
                                    document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'bg-green-500 text-white' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'bg-yellow-500 text-white' :
                                            'bg-gray-500 text-white'
                                }>
                                    {document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.Approved ? 'Approved' :
                                        document.status === AuditReportCustomFieldsDocumentationDocumentStatusEnum.WaitingForApproval ? 'In review' :
                                            document.status}
                                </Tag>
                            </div>
                            <div className="flex items-center gap-1">
                                {document.expiry_date && (
                                    <span>Expires {getPrettyDate(document.expiry_date)}</span>
                                )}
                                <span className="text-status-approved">Document added</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    }

    return renderEmptyValue();
}

function renderEmptyValue() {
    return <span className='italic text-secondary'><FormattedMessage id='globals.no_value' /></span>
}