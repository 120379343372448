/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 114.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileResource,
    FileResourceFromJSON,
    FileResourceFromJSONTyped,
    FileResourceToJSON,
} from './';

/**
 * A supplier document.
 * @export
 * @interface SupplierDocument
 */
export interface SupplierDocument {
    /**
     * 
     * @type {boolean}
     * @memberof SupplierDocument
     */
    approval_required: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    approver_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    documentation_type_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    expiry_date: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierDocument
     */
    expiry_required: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    extended_status: SupplierDocumentExtendedStatusEnum;
    /**
     * 
     * @type {Array<FileResource>}
     * @memberof SupplierDocument
     */
    media_resources: Array<FileResource>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierDocument
     */
    permissions: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    rejection_reason: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    rejector_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    supplier_document_id: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDocument
     */
    uploader_id: string;
}

/**
* @export
* @enum {string}
*/
export enum SupplierDocumentExtendedStatusEnum {
    FileAdded = 'file_added',
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Active = 'active',
    Expired = 'expired',
    Archived = 'archived',
    Deleted = 'deleted'
}

export function SupplierDocumentFromJSON(json: any): SupplierDocument {
    return SupplierDocumentFromJSONTyped(json, false);
}

export function SupplierDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approval_required': json['approval_required'],
        'approver_id': json['approver_id'],
        'documentation_type_id': json['documentation_type_id'],
        'expiry_date': json['expiry_date'],
        'expiry_required': json['expiry_required'],
        'extended_status': json['extended_status'],
        'media_resources': ((json['media_resources'] as Array<any>).map(FileResourceFromJSON)),
        'permissions': json['permissions'],
        'rejection_reason': json['rejection_reason'],
        'rejector_id': json['rejector_id'],
        'start_date': json['start_date'],
        'supplier_document_id': json['supplier_document_id'],
        'uploader_id': json['uploader_id'],
    };
}

export function SupplierDocumentToJSON(value?: SupplierDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approval_required': value.approval_required,
        'approver_id': value.approver_id,
        'documentation_type_id': value.documentation_type_id,
        'expiry_date': value.expiry_date,
        'expiry_required': value.expiry_required,
        'extended_status': value.extended_status,
        'media_resources': ((value.media_resources as Array<any>).map(FileResourceToJSON)),
        'permissions': value.permissions,
        'rejection_reason': value.rejection_reason,
        'rejector_id': value.rejector_id,
        'start_date': value.start_date,
        'supplier_document_id': value.supplier_document_id,
        'uploader_id': value.uploader_id,
    };
}


