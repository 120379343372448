import React, { DragEvent } from 'react';
import { FileDrop } from 'react-file-drop';

type DropzoneProps = {
    onDrop(files: FileList | null, event: DragEvent);
    children?: React.ReactNode;
}
const DropZone = (props: DropzoneProps): React.ReactElement => {
    return (<FileDrop draggingOverTargetClassName='bg-secondary' className='w-full' onDrop={props.onDrop}>
        {props.children}
    </FileDrop>);
}

export default DropZone;
