import { DisplayDetailedSupplierDocument, DisplayDetailedSupplierDocumentStateEnum } from '../../../backend_api/models/DisplayDetailedSupplierDocument';
import React from 'react';
import { TranslateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { getLocaleLanguageString, getPrettyDate, getTranslation, isDateExpired, twMerge } from '../../../base/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu, { ContextMenuOverflowItemType } from '../../../base/ui/components/contextMenu/ContextMenu';
import Button from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import Popup from '../../../base/ui/components/popup/Popup';

type Props = {
    className?: string;
    detailedSupplierDocument: DisplayDetailedSupplierDocument;
    documentationTypeTooltipContent?: React.ReactElement;
    allowedToEdit: boolean;
    viewDocument?: () => void;
    addNewDocument?: () => void;
    archiveDocument?: () => void;
    unarchiveDocument?: () => void;
    deleteDocument?: () => void;
    showAllDocuments?: () => void;
};


const SupplierDocumentItemViewMode = (props: Props): React.ReactElement => {
    const { className, detailedSupplierDocument, documentationTypeTooltipContent, viewDocument, showAllDocuments, archiveDocument, unarchiveDocument, deleteDocument, addNewDocument } = props;
    const { formatMessage, locale } = useIntl();
    const lang = getLocaleLanguageString(locale);
    const canEditDocument = detailedSupplierDocument?.permissions?.includes('edit_supplier_documents');

    const addNewDocumentContextMenuItem: ContextMenuOverflowItemType = !!addNewDocument && canEditDocument && {
        label: `${formatMessage({ id: 'production_units.supplier_document.context_menu.add_new' })} ${getTranslation(detailedSupplierDocument.documentation_type_name, lang)}`,
        icon: 'operation_add',
        onPress: addNewDocument
    }
    const archiveDocumentContextMenuItem: ContextMenuOverflowItemType = !!archiveDocument && detailedSupplierDocument.state !== DisplayDetailedSupplierDocumentStateEnum.Archived && !detailedSupplierDocument.archived && canEditDocument && {
        label: 'production_units.supplier_document.context_menu.archive_document',
        icon: 'operation_archive',
        onPress: archiveDocument
    }
    const unarchiveDocumentContextMenuItem: ContextMenuOverflowItemType = !!unarchiveDocument && (detailedSupplierDocument.state === DisplayDetailedSupplierDocumentStateEnum.Archived || detailedSupplierDocument.archived) && canEditDocument && {
        label: 'production_units.supplier_document.context_menu.unarchive_document',
        icon: 'operation_archive',
        onPress: unarchiveDocument
    }
    const viewAllContextMenuItem: ContextMenuOverflowItemType = !!showAllDocuments && {
        label: 'production_units.supplier_document.context_menu.view_all',
        onPress: showAllDocuments
    }
    const deleteContextMenuItem: ContextMenuOverflowItemType = !!deleteDocument && canEditDocument && {
        label: 'production_units.supplier_document.context_menu.delete',
        icon: 'operation_delete',
        className: 'text-danger',
        onPress: deleteDocument
    }

    const splitterContextMenuItem: ContextMenuOverflowItemType = {
        isSplitter: true
    }

    const contextMenuItems = [addNewDocumentContextMenuItem, addNewDocument && canEditDocument && splitterContextMenuItem, archiveDocumentContextMenuItem, unarchiveDocumentContextMenuItem, deleteContextMenuItem, showAllDocuments && canEditDocument && splitterContextMenuItem, viewAllContextMenuItem].filter(item =>  !!item);

    return (
        <button className={twMerge(`flex items-center relative w-full bg-default p-3`, className)} onClick={viewDocument}>
            <div className="flex gap-4">
                <div className={`${getSupplierDocumentStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-on-status px-3 py-3 min-w-[100px] flex items-center justify-center rounded-md`}>
                    <FormattedMessage id={"production_units.supplier_document.status." + (detailedSupplierDocument?.archived ? "archived" : detailedSupplierDocument.state)} />
                </div>

                <div className="items-start flex flex-col">
                    <Popup trigger={<div className="text-normal text-base font-bold"><TranslateTextWithTranslation twt={detailedSupplierDocument.documentation_type_name} /></div>} on="hover">
                        {documentationTypeTooltipContent}
                    </Popup>
                    <span className="text-normal text-sm text-primary">
                        {renderDocumentationStateDescription({ detailedSupplierDocument })}
                    </span>
                </div>
            </div>
            <div className="flex gap-14px ml-auto">
                <ContextMenu 
                    trigger={<Button stopPropagation ghost className='px-2 py-1 bg-surface-interactive rounded-md'><Icon name='microinteraction_context-menu' /></Button>} 
                    overflowItems={contextMenuItems} 
                    className='h-fit-content'
                ></ContextMenu>
            </div>
        </button>
    );
}

export function renderDocumentationStateDescription(props: {
    detailedSupplierDocument: DisplayDetailedSupplierDocument
}) {
    const { detailedSupplierDocument } = props;
    if (!detailedSupplierDocument) {
        return null;
    }

    if (!detailedSupplierDocument.valid_from_date || (detailedSupplierDocument?.expiry_required && !detailedSupplierDocument.valid_to_date)) {
        return <FormattedMessage id="production_units.documentation.validity_not_set" />;
    }

    if (detailedSupplierDocument.valid_to_date) {
        const isExpired = isDateExpired(detailedSupplierDocument.valid_to_date);
        if (isExpired) {
            return <FormattedMessage id="production_units.list.documentation.date.expired" values={{ date: getPrettyDate(detailedSupplierDocument.valid_to_date) }} />;
        }
        return <FormattedMessage id="production_units.list.documentation.date.expires" values={{ date: getPrettyDate(detailedSupplierDocument.valid_to_date) }} />;
    }

    if (!detailedSupplierDocument?.expiry_required && detailedSupplierDocument.valid_from_date) {
        return <FormattedMessage id="production_units.list.documentation.date.active_from" values={{ date: getPrettyDate(detailedSupplierDocument.valid_from_date) }} />;
    }
}

export const getSupplierDocumentStatusColor = (status: string, archived: boolean) => {
    if (archived) {
        return "bg-status-ignored";
    }

    switch (status) {
        case 'approved':
            return "bg-status-approved"

        case 'waiting_for_approval':
            return "bg-status-pending"

        case 'file_added':
            return "bg-status-in-progress"

        case 'rejected':
            return "bg-status-rejected"

        case 'active':
            return "bg-status-approved"

        case 'expired':
            return "bg-status-rejected"

        case 'archived':
            return "bg-status-ignored"

        case 'deleted':
            return "bg-status-rejected"

    }
};

export default SupplierDocumentItemViewMode;