import React, { Fragment, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { Audit, AuditFeaturesEnum, AuditHistoryEvent, AuditReportCustomFieldsDocumentationTypeEnum, SupplierDocument } from '../../../backend_api/models';
import LightBox from '../../../base/components/LightBox';
import Loader from '../../../base/components/Loader';
import ToTop from '../../../base/components/ToTop';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Icon from '../../../base/components/basic/Icon';
import { getLayoutSelector } from '../../../base/selectors';
import { useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { Score } from '../../checklists/types';
import PageContainer from '../../globals/components/PageContainer';
import AuditCorrectiveActionList from '../../inspections/components/report/AuditCorrectiveActionList';
import AuditReportApproval from '../../inspections/components/report/AuditReportApproval';
import CheckpointsSummary from '../../inspections/components/report/checkpoints/CheckpointsSummary';
import DownloadReport from '../../inspections/components/report/report2/DownloadReport';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { HasFeature } from '../../profile/containers';
import { getAuditReportImagesSelector, getAuditReportSelector, getAuditsLoadingSelector } from '../selectors/auditSelectors';
import { deleteAudit, getAuditComments, getAuditReport } from '../slices/auditSlice';
import AuditCheckpoints from './AuditCheckpoints';
import AuditInfo from './AuditInfo';
import AuditTypeTag from './AuditTypeTag';
import AuditWatchers from './AuditWatchers';
import AuditsCommentsAndHistory from './AuditsCommentsAndHistory';
import { FileResource } from '../../../backend_api_2';
import SupplierDocumentViewer, { DocumentViewerAction } from '../../../base/ui/components/documentViewer/SupplierDocumentViewer';
import { activateSupplierDocument, approveSupplierDocument, archiveSupplierDocument, deleteSupplierDocument, fetchSupplierDocument, getViewingSupplierDocumentSelector, rejectSupplierDocument, resetSupplierDocumentStatus, submitForApproval, supplierDocumentsSliceActions, unarchiveSupplierDocument, unsubmitSupplierDocument, updateValidityInterval } from '../../../modules/supplierDocuments/supplierDocumentsSlice';
import { showSnackbar } from '../../../base/ui/uiSlice';
import { getDocumentationTypeById } from '../../../base/ui/components/documentViewer/SupplierDocumentApprovalAndValidity';
import Button2 from '../../../base/ui/components/buttons/Button';
import Button from '../../../base/components/basic/Button';
import { getSupplierDocumentTypeGroupsSelector } from '../../suppliers/slice/suppliersSlice';
import { AuditReportState } from '../types';


type AuditReportProps = {
    auditId: string;
};

const AuditReport = (props: AuditReportProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const auditReport = useSelector(getAuditReportSelector);
    const auditReportLoading = useSelector(getAuditsLoadingSelector);
    const auditReportImages = useSelector(getAuditReportImagesSelector) as any;
    const lightboxMedias = auditReportImages?.images || [];
    const scrollToTop = !useSelector(getLayoutSelector).dontScrollToTopAfterAction;
    const isMobile = useIsMobileDevice();
    const { auditId } = props;
    const location = useLocation();
    const intl = useIntl();
    const documentTypeGroups = useSelector(getSupplierDocumentTypeGroupsSelector);
    const documentation = useSelector(getViewingSupplierDocumentSelector);
    const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);
    const documentationBelongsTo = doesDocumentBelongsToProductionUnitOrSupplier(documentation, auditReport);

    const closeDocumentViewer = () => {
        dispatch(supplierDocumentsSliceActions.hideDocumentViewer());
    };

    const openDocumentViewer = () => {
        dispatch(fetchSupplierDocument({ id: documentation?.supplier_document_id }));
    };

    const documentActions: DocumentViewerAction = {
        archive: () => {
            dispatch(archiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        unarchive: () => {
            dispatch(unarchiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        remove: () => {
            dispatch(deleteSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.deleted' /></div>,
            }))
        },
        approve: () => {
            dispatch(approveSupplierDocument({ id: documentation?.supplier_document_id }));
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.approved' /></div>,
                action: <Button2 onPress={openDocumentViewer} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button2>,
                icon: 'status_approved-circle'
            }))
        },
        reject: (reason: string) => {
            dispatch(rejectSupplierDocument({ id: documentation?.supplier_document_id, body: { reason } }));
            closeDocumentViewer();
        },
        updateValidity: (startDate, expiredDate) => {
            dispatch(updateValidityInterval({ id: documentation?.supplier_document_id, interval: { start_date: startDate, expiry_date: expiredDate } }));
        },
        reset: () => {
            dispatch(resetSupplierDocumentStatus({ id: documentation?.supplier_document_id }));
        },
        activate: () => {
            dispatch(activateSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
        },
        submit: () => {
            dispatch(submitForApproval({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='production_units.document_viewer.submitted_for_review' /></div>,
                action: <Button2 onPress={openDocumentViewer} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button2>
            }))
        },
        unsubmit: () => {
            dispatch(unsubmitSupplierDocument({ id: documentation.supplier_document_id }));
            closeDocumentViewer();
        },
    };



    useEffect(() => {
        dispatch(getAuditReport(auditId));
        dispatch(getAuditComments({ auditId }));
    }, []);

    useEffect(() => {
        location.hash ? document.location.href = location.hash : scrollToTop && window.scrollTo(0, 0);
    }, [auditReport]);

    useEffect(() => {
        if (location.hash) {
            document.location.href = location.hash;
        }
    }, [location.hash]);

    if (auditReport) {
        const checkpointsSummary = <ContentSection
            headerText={<FormattedMessage id='inspections.summary' />}
            content={<CheckpointsSummary isAudit={true} summaryScore={auditReport.audit_report_data.summary_score as Score} id={auditId} />}
        />;
        const checkpoints = <ContentSection
            headerText={<FormattedMessage id='audit_report.checkpoints_header' />}
            headerOutside={true}
            content={<AuditCheckpoints auditId={auditId} checkpointHeaders={auditReport.checkpoint_headers} />}
        />;

        const userHasDeleteFeature = auditReport.audit.features.includes(AuditFeaturesEnum.Delete) || false;
        const reportApproval = <AuditReportApproval audit={auditReport.audit} auditorComment={auditReport.audit_report_data.auditor_comment} />;
        const reportCorrectiveActions = <HasFeature feature='u_corrective_actions'><AuditCorrectiveActionList auditName={(auditReport.audit.target_supplier && auditReport.audit.target_supplier.name) || '-'} auditId={auditId} /></HasFeature>
        const info = <AuditInfo audit={auditReport.audit} />;
        const deleteButton = userHasDeleteFeature && <div className={twMerge(isMobile && 'pt-2')}><DeleteConfirm deleteFunction={() => dispatch(deleteAudit({ auditId, isReport: true }))} alert trigger={<Button alert><FormattedMessage id='audits.audit_report.delete_audit' /></Button>} /></div>;

        return <PageContainer headerClassName='' header={<div className='flex space-x-2 justify-between'>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.audit_report' }, { name: chooseHeading(auditReport.audit) })} />
            <div className='flex items-center leading-none space-x-1 auditReport'>
                <AuditTypeTag className='py-2' auditStatus={auditReport.audit.status} auditType={auditReport.audit.audit_type} auditConclusion={auditReport.audit.final_conclusion} />
                <Icon name='domain' /><div>{chooseHeading(auditReport.audit)}</div>
            </div>
            {isMobile && <DownloadReport inspectionId={auditReport.audit.id} context='audit' showLabel={false} />}
        </div>}>
            <SupplierDocumentViewer
                actions={documentActions}
                linkedSupplier={{
                    id: auditReport.audit.target_production_unit && documentationBelongsTo === 'production_unit' ? auditReport.audit.target_production_unit.id : auditReport.audit.target_supplier?.id,
                    name: auditReport.audit.target_production_unit && documentationBelongsTo === 'production_unit' ? auditReport.audit.target_production_unit.name : auditReport.audit.target_supplier?.name,
                    number: auditReport.audit.target_production_unit && documentationBelongsTo === 'production_unit' ? auditReport.audit.target_production_unit.number : auditReport.audit.target_supplier?.number,
                    type: documentationBelongsTo
                }}
                close={closeDocumentViewer}
                fromAuditReport={true}
            />
            <Loader active={auditReportLoading}>
                <LightBox images={lightboxMedias} metaItems={[]} />
                <div className=''>
                    {isMobile && <Fragment>
                        {reportApproval}
                        {info}
                        {reportCorrectiveActions}
                        {checkpointsSummary}
                        {checkpoints}
                        {deleteButton}
                    </Fragment>}
                    {!isMobile && <div className='flex justify-between space-x-4'>
                        <div className='w-2/3'>
                            {info}
                            {checkpointsSummary}
                            {checkpoints}
                            {deleteButton}
                        </div>
                        <div className='w-1/3'>
                            {reportApproval}
                            {reportCorrectiveActions}
                            <AuditWatchers auditId={auditId} watchers={auditReport.audit.watchers} isReport={true} />
                            <AuditsCommentsAndHistory auditId={auditId} loadComments={false} />
                        </div>
                        <ToTop />
                    </div>}
                </div>
            </Loader>
        </PageContainer>
    }
    return null;
};

function doesDocumentBelongsToProductionUnitOrSupplier(document: SupplierDocument, audit: AuditReportState): 'production_unit' | 'supplier' | null {
    if(!document) {
        return null;
    }
    const documentMap: { [key: string]: 'production_unit' | 'supplier' } = {};
    audit?.checkpoint_headers?.forEach(header => {
        header?.checkpoints?.forEach(checkpoint => {
            checkpoint?.production_unit_custom_fields?.custom_fields?.forEach(field => {
                if(field.value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
                    field.value.documents.forEach(doc => {
                        documentMap[doc.supplier_document_id] = 'production_unit';
                    });
                }
            });
            checkpoint?.supplier_custom_fields?.custom_fields?.forEach(field => {
                if(field.value.type === AuditReportCustomFieldsDocumentationTypeEnum.Documentation) {
                    field.value.documents.forEach(doc => {
                        documentMap[doc.supplier_document_id] = 'supplier';
                    });
                }
            });
        });
    });
    return documentMap[document.supplier_document_id];
}

function getAttachmentsFromAuditHistoryEvent(history: AuditHistoryEvent[]): FileResource[] {
    let attachments = [];
    history?.forEach(event => {
        event?.comment_event?.attachments?.forEach(attachment => {
            attachments.push(attachment);
        });
    });
    return attachments;
}

function chooseHeading(audit: Audit) {

    if (!audit) {
        return ' - ';
    }

    const hasSupplierName = !!(audit.target_supplier && audit.target_supplier.name)
    const supplierName = audit.target_supplier && audit.target_supplier.name

    const hasSupplierNumber = !!(audit.target_supplier && audit.target_supplier.number)
    const supplierNumber = audit.target_supplier && audit.target_supplier.number

    const hasProductionUnitName = !!(audit.target_production_unit && audit.target_production_unit.name)
    const productionUnitName = audit.target_production_unit && audit.target_production_unit.name

    const hasProductionUnitNumber = !!(audit.target_production_unit && audit.target_production_unit.number)
    const productionUnitNumber = audit.target_production_unit && audit.target_production_unit.number


    if (hasProductionUnitName && hasProductionUnitNumber) {
        return `${productionUnitNumber} - ${productionUnitName}`;
    }

    if (hasProductionUnitNumber) {
        return productionUnitNumber;
    }

    if (hasProductionUnitName) {
        return productionUnitName;
    }

    if (hasSupplierName && hasSupplierNumber) {
        return `${supplierNumber} - ${supplierName}`;
    }

    if (hasSupplierNumber) {
        return supplierNumber;
    }

    if (hasSupplierName) {
        return supplierName;
    }

    return ' - ';
}

export default AuditReport;
