import React from 'react';
import { TranslateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { FormattedMessage } from 'react-intl';
import ContextMenu from '../../../base/ui/components/contextMenu/ContextMenu';
import DetailedSupplierDocument from './DetailedSupplierDocument';
import { DocumentationType } from '../../../backend_api/models/DocumentationType';
import { ListItem, ListItems } from '../../../base/ui/components/listitems/ListItem';
import Popup from '../../../base/ui/components/popup/Popup';
import Icon from '../../../base/ui/components/icons/Icon';
import { Sizes } from '../../../base/ui/components/types';
import FileUploadModal from '../../files/components/FileUploadModal';
import { useIsMobileDevice } from '../../../base/utils';
import { FileResource, SupplierFeaturesDto } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import DocumentationTypeTooltipContent from '../../supplierDocuments/components/DocumentationTypeTooltipContent';
import { fetchSupplierDocument, createSupplierDocumentOnProductionUnit, createSupplierDocumentOnSupplier } from '../../supplierDocuments/supplierDocumentsSlice';
import { getDetailedProductionUnitListSelector } from '../slice/productionUnitsSlice';
import { useSelector } from 'react-redux';
import { getDetailedSupplierSelector } from '../../suppliers/slice/suppliersSlice';

type Props = {
    className?: string;
    allowedToEdit: boolean;
    showEmptyFields: boolean;
    isEditMode: boolean;
    documentationType: DocumentationType;
    productionUnitId: string;
    type: 'productionUnit' | 'supplier';
};

const DocumentationTypeWithSupplierDocuments = (props: Props): React.ReactElement => {
    const { className, allowedToEdit, isEditMode, documentationType, productionUnitId, type } = props;
    const dispatch = useAppDispatch();


    const isEmptyOrAllArchived = documentationType.detailed_supplier_documents.length === 0 ||
        documentationType.detailed_supplier_documents.every(doc => doc.archived);

    const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
    const isMobile = useIsMobileDevice();

    const handleCreateSupplierDocument = async (file: FileResource) => {
        if (type === 'productionUnit') {
            dispatch(createSupplierDocumentOnProductionUnit({
                documentation_type_id: documentationType.id,
                media_resource_id: file.id,
                production_unit_id: productionUnitId
            }));
        } else {
            dispatch(createSupplierDocumentOnSupplier({
                documentation_type_id: documentationType.id,
                media_resource_id: file.id,
                supplier_id: productionUnitId
            }));
        }
        setShowFileUploadModal(false);
    }

    function handleViewSupplierDocument(id: string) {
        dispatch(fetchSupplierDocument({ id }))
    }

    const documentationTypeTooltipContent = <DocumentationTypeTooltipContent documentationType={documentationType} />
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const supplier = useSelector(getDetailedSupplierSelector);
    const canEditProductionUnit = productionUnit?.features?.includes("edit_additional_data");
    const canEditSupplier = supplier?.features?.includes(SupplierFeaturesDto.Modify);
    const canEditDocument = type === 'productionUnit' ? canEditProductionUnit : canEditSupplier;

    const editMode = isEmptyOrAllArchived ? (
        <div className={`flex flex-col relative w-full bg-default py-3 `}>
            <div className="flex mb-1">
                <div className="text-normal text-sm text-secondary"><TranslateTextWithTranslation twt={documentationType.name} /></div>
            </div>
            <ListItems className={'border rounded-md ' + (canEditDocument ? 'cursor-pointer' : '')}>
                <ListItem onPress={() => setShowFileUploadModal(true)} iconOnRight={true} icon={<Popup trigger={<Icon size={Sizes.XLarge} name='operation_get-info' />} on="hover" >
                    {documentationTypeTooltipContent}
                </Popup>} slim={true} graphicItemClass='min-w-10'>
                    <div className="flex items-center w-full px-3">
                        <div className='flex items-center text-secondary italic'>
                            <FormattedMessage id={"production_units.supplier_document.status.no_active_document"} />
                        </div>
                        <div className='ml-auto'>
                            {allowedToEdit ? (
                                <ContextMenu className='rounded-md border border-default'
                                    actions={[
                                        { icon: 'operation_add', className: "bg-surface-plain px-3 py-0 text-on-surface-plain text-normal text-xs", labelLeft: true, label: 'production_units.supplier_document.context_menu.add', onPress: () => setShowFileUploadModal(true) }
                                    ]} />
                            ) : (<></>)}
                        </div>
                    </div>
                </ListItem>
            </ListItems>
        </div>
    ) : (
        <>
            <div className="text-normal mb-1 text-sm text-secondary"><TranslateTextWithTranslation twt={documentationType.name} /></div>
            <ListItems className={'border rounded-md ' + (canEditDocument ? 'cursor-pointer' : '')}>
                <ListItem onPress={() => setShowFileUploadModal(true)} iconOnRight={true} icon={<Popup trigger={<Icon size={Sizes.XLarge} name='operation_get-info' />} on="hover">
                    {documentationTypeTooltipContent}
                </Popup>} slim={true} graphicItemClass='min-w-10'>
                    <div className="flex items-center w-full px-3">
                        <div className='flex items-center'>
                            {`${documentationType.detailed_supplier_documents.length}` + ' '}
                            <FormattedMessage id={"production_units.supplier_document.active_documents"} />
                        </div>
                        <div className='ml-auto'>
                            {allowedToEdit ? (
                                <ContextMenu className='rounded-md border border-default'
                                    actions={[
                                        { icon: 'operation_add', className: "bg-surface-plain px-3 py-0 text-on-surface-plain text-normal text-xs", labelLeft: true, label: 'production_units.supplier_document.context_menu.add', onPress: () => setShowFileUploadModal(true) }
                                    ]} />
                            ) : (<></>)}
                        </div>
                    </div>
                </ListItem>
                {documentationType.detailed_supplier_documents.map((detailedSupplierDocument, i) => {
                    return <>
                        <DetailedSupplierDocument
                            documentationTypeTooltipContent={documentationTypeTooltipContent}
                            viewDocument={() => handleViewSupplierDocument(detailedSupplierDocument.id)}
                            detailedSupplierDocument={detailedSupplierDocument}
                            isEditMode={isEditMode}
                            allowedToEdit={allowedToEdit}
                        />
                    </>
                })}
            </ListItems>
        </>
    );

    const viewMode = isEmptyOrAllArchived ? (
        <div className={`flex items-center relative w-full bg-default p-3 border-b ${canEditDocument ? 'cursor-pointer' : ''}`} onClick={canEditDocument ? () => setShowFileUploadModal(true) : undefined}>
            <div className="flex gap-4">
                <div className={`bg-default text-secondary text-normal border border-status-not-set text-sm italic px-3 py-1 min-w-[100px] flex items-center justify-center rounded-md`}>
                    <FormattedMessage id={"production_units.supplier_document.status.none"} />
                </div>
                <div className='flex items-center'>
                    <Popup trigger={<div className="text-normal text-base text-secondary font-bold">
                        <TranslateTextWithTranslation twt={documentationType.name} />
                    </div>} on="hover">
                        {documentationTypeTooltipContent}
                    </Popup>
                </div>
            </div>
            <div className="flex gap-14px ml-auto">
                {allowedToEdit &&
                    <ContextMenu className='rounded-md'
                        actions={[
                            { icon: 'operation_add', label: 'add', onPress: () => setShowFileUploadModal(true) }
                        ]} />
                }
            </div>
        </div>
    ) : (
        <>
            {documentationType.detailed_supplier_documents.map((detailedSupplierDocument, i) => {
                return <DetailedSupplierDocument
                    documentationTypeTooltipContent={documentationTypeTooltipContent}
                    viewDocument={() => handleViewSupplierDocument(detailedSupplierDocument.id)}
                    detailedSupplierDocument={detailedSupplierDocument}
                    isEditMode={isEditMode}
                    allowedToEdit={allowedToEdit}
                />
            })}
        </>
    )

    return (
        <div className='w-full'>
            <FileUploadModal
                open={showFileUploadModal}
                onClose={() => setShowFileUploadModal(false)}
                onUploadComplete={(file) => {
                    handleCreateSupplierDocument(file);
                }}
                isMobile={isMobile}
                allowMultiUpload={false}
            />
            {isEditMode && canEditDocument ? editMode : viewMode}
        </div>
    )
}

export default DocumentationTypeWithSupplierDocuments