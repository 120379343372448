/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 114.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentationStatus {
    FileAdded = 'file_added',
    Active = 'active',
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Archived = 'archived',
    Deleted = 'deleted'
}

export function DocumentationStatusFromJSON(json: any): DocumentationStatus {
    return DocumentationStatusFromJSONTyped(json, false);
}

export function DocumentationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentationStatus {
    return json as DocumentationStatus;
}

export function DocumentationStatusToJSON(value?: DocumentationStatus | null): any {
    return value as any;
}

