import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Dropdown, Menu } from 'semantic-ui-react';
import { GetProfileResponse } from '../../../backend_api/models/GetProfileResponse';
import base from '../../../base';
import { getSiteTypeSelector, setLayoutAttribute } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import { useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import history from '../../../store/history';
import { TEMPORARY_USER_MODE, temporaryUserlogout } from '../../authentication/authenticationSlice';
import Login from '../../authentication/containers/Login';
import globals from '../../globals';
import { LocaleSelector } from '../../globals/components';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { getLastViewedInspectionPage } from '../../inspections/selectors/selectors';
import { getListViewDefaultDateSelector } from '../../inspections/slices/inspectionsListSlice';
import ProfileName from '../../profile/components/ProfileComponents';
import { getProfile } from '../../profile/selectors';
import GlobalSearch from '../../search/components/GlobalSearch';
import Search from '../../search/components/Search';
import MobileMenu from './MobileMenu';
import OrgMenuItem from './OrgMenuItem';
import { useCheckHasPermission } from '../../../base/hooks';
import { getPermissionsSelector } from '../../../base/selectors';


const IsSavingInfo = globals.components.Utils.IsSavingInfo;

type MenuProps = {
    isAuthenticated: boolean;
    isSaving: boolean;
};

const TopMenu = (props: MenuProps): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const isTemporaryUserMode = !!sessionStorage.getItem(TEMPORARY_USER_MODE);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const isSaving = props.isSaving;
    const currentPath = location.pathname;
    const formatMessage = intl.formatMessage;
    const defaultDate = useSelector(getListViewDefaultDateSelector);
    const profile: GetProfileResponse = useSelector(getProfile);
    const multipleOrgs = profile && profile.other_organizations.length > 0;
    const hasAuditFeature = profile.features.includes('org_audits')
    const siteType = useSelector(getSiteTypeSelector);
    const permissions = useSelector(getPermissionsSelector);
    const isCompliance = siteType === 'compliance';
    let defaultDateStr = '';
    if (defaultDate) {
        defaultDateStr = '&date=' + defaultDate;
    }
    const features: string[] = useSelector(base.selectors.getFeaturesSelector);
    let lastViewedInspectionPage = useSelector(getLastViewedInspectionPage);
    if (lastViewedInspectionPage === '/inspections') {
        lastViewedInspectionPage = '/inspections?status=planned' + defaultDateStr;
    }
    const showInspPath = currentPath === '/inspections' || currentPath === '/inspections/week';
    const isAuditCompletedPage = currentPath == '/audits/completed';

    const inspectionMenuItems = [];
    const auditMenuItems = [];
    const cf = (): void => null;
    const getItem = (labelId: string, isSaving: boolean, link: string, click?): React.ReactElement => {
        const label = <FormattedMessage id={labelId} />;
        const clickAll = (): void => {
            closeMobile();
            if (click) {
                click();
            }
        };
        if (!isSaving) {
            return (<Link onClick={clickAll} data-test-id={labelId} to={link}><FormattedMessage id={labelId} /></Link>);
        }
        return (<IsSavingInfo
            on='click'
            trigger={<div className='inActive'>{label}</div>}
            position='bottom center'
        />);
    };

    if (features.includes('u_inspections') && !features.includes('u_planning_view')) {
        const obj = currentPath === '/inspections' ?
            { item: getItem('top_menu.item.inspections', isSaving, '/inspections?status=planned' + defaultDateStr, cf), path: '/inspections' }
            :
            { item: getItem('top_menu.item.inspections', isSaving, lastViewedInspectionPage), path: '/inspections/week' };
        inspectionMenuItems.push(obj);
    }
    if (features.includes('u_planning_view')) {
        const obj = showInspPath ? { item: getItem('top_menu.item.inspections.list', isSaving, '/inspections?status=planned' + defaultDateStr, cf), path: '/inspections' }
            :
            { item: getItem('top_menu.item.inspections.list', isSaving, lastViewedInspectionPage, cf), path: '/inspections' };
        inspectionMenuItems.push(obj);
        inspectionMenuItems.push({ item: getItem('top_menu.item.inspections.week', isSaving, '/inspections/week'), path: '/inspections/week' });
    }
    if (features.includes('u_corrective_actions_inspection')) {
        inspectionMenuItems.push({ item: getItem('top_menu.item.inspections.ca', isSaving, '/inspections/ca'), path: '/inspections/ca' });
    }
    if (features.includes('u_audits')) {
        auditMenuItems.push({ item: getItem('top_menu.item.audits', isSaving, '/audits?status=planned'), path: '/audits' });
    }
    if (features.includes('u_corrective_actions')) {
        auditMenuItems.push({ item: getItem('top_menu.item.audits.ca', isSaving, '/ca'), path: '/ca' })
    }
    if (features.includes('u_manage_booking_info')) {
        const obj = { item: getItem('top_menu.item.bookings', isSaving, '/bookings'), path: '/bookings' };
        inspectionMenuItems.push(obj);
    }

    const closeMobile = (): void => {
        dispatch(setLayoutAttribute('mobileMenuOpen', false));
    };
    const productsItem =
        <Menu.Item as={'span'} key='products'>
            {getItem('top_menu.item.compliance.products', isSaving, '/compliance/products')}
        </Menu.Item>;

    const requirementsItem =
        <Menu.Item as={'span'} key='requirements'>
            {getItem('top_menu.item.compliance.requirements', isSaving, '/compliance/requirements')}
        </Menu.Item>;

    const orderListItem =
        <Menu.Item as={'span'} key='orderlist'>
            {getItem('top_menu.item.compliance.order_list', isSaving, '/compliance/orders')}
        </Menu.Item>;

    const globalSearchItem =
        <Menu.Item as={'span'} key='globalSearch'>
            <GlobalSearch />
        </Menu.Item>;

    const dashboardItem = <HasFeatureAccess feature='u_dashboard'>
        <Menu.Item as={'span'} key='menuItem_dashboard'>
            {getItem('top_menu.item.dashboard', isSaving, '/dashboard')}
        </Menu.Item>
    </HasFeatureAccess>;

    const searchItem = <HasFeatureAccess feature='u_inspections' key='menuItem_inspections'>
        <Menu.Item as={'span'} name='search'>
            <Search />
        </Menu.Item>
    </HasFeatureAccess>;

    const checklistsItem = <HasFeatureAccess feature='u_inspection_checklists'><Menu.Item as={'span'} key='menuItem_checklists'>
        {getItem('top_menu.item.checklists', isSaving, '/checklists')}
    </Menu.Item></HasFeatureAccess>;

    const dataExportItem = <HasFeatureAccess feature='u_statistics'><Menu.Item as={'span'} key='menuItem_data_export'>
        {getItem('top_menu.item.data_export', isSaving, '/data_export')}
    </Menu.Item></HasFeatureAccess>;
   
    const documentationApprovalItem = useCheckHasPermission(permissions, 'u_manage_documentation_types') &&
        <Dropdown.Item key='rm_documentation_approval'>
            {getItem('top_menu.item.documentation_approval', isSaving, '/system_settings/documentation_approval')}
        </Dropdown.Item>

    const userRolesItem = <HasFeatureAccess feature='u_manage_user_roles'>
        <Dropdown.Item key='rm_user_roles'>
            {getItem('top_menu.item.roles', isSaving, '/roles')}
        </Dropdown.Item>
    </HasFeatureAccess>;

    const complianceItems = (
        <Fragment>
            <HasFeatureAccess feature='u_requirements_menu'>
                {requirementsItem}
            </HasFeatureAccess>
            {productsItem}
            {orderListItem}
            {globalSearchItem}
        </Fragment>
    );

    const groupsItem = <HasFeatureAccess feature='u_groups_admin'>
        <Dropdown.Item key='rm_groups'>
            {getItem('top_menu.item.groups', isSaving, '/groups')}
        </Dropdown.Item>
    </HasFeatureAccess>;

    const supplierItem = <HasFeatureAccess feature='u_supplier_admin'>
        <Dropdown.Item key='rm_suppliers'>
            {getItem('top_menu.item.suppliers', isSaving, '/suppliers')}
        </Dropdown.Item>
    </HasFeatureAccess>;

    const productionUnitItem =
        <HasFeatureAccess feature='u_production_unit_list'>
            <Dropdown.Item key='rm_productionUnits'>
                {getItem('top_menu.item.production_units', isSaving, '/production_units')}
            </Dropdown.Item>
        </HasFeatureAccess>


    const profileItem = <Dropdown.Item key='rm_profile'>
        {getItem('top_menu.item.profile', isSaving, '/profile')}
    </Dropdown.Item>;

    const usersItem = <HasFeatureAccess feature='u_users_admin'>
        <Dropdown.Item key='rm_users'>
            {getItem('top_menu.item.users', isSaving, '/users')}
        </Dropdown.Item>
    </HasFeatureAccess>;

    const activeOrg = multipleOrgs && profile ? <Dropdown.Item key={profile.organization.name}>
        <div className='p-menu-item leading-menu-item text-menu-item cursor-default bg-surface-selected'>{profile.organization.name}</div>
    </Dropdown.Item> : <Fragment />

    const organisationItems = multipleOrgs && profile ? profile.other_organizations.map((org) => {
        return <OrgMenuItem key={org.organization_id} org={org} />
    }) : <Fragment />

    const orgList = multipleOrgs ? <Fragment>
        <Dropdown.Divider />
        {activeOrg}
        {organisationItems}
    </Fragment> : <Fragment />

    const loginButton = isAuditCompletedPage &&
        <Button
            className="justify-center w-full text-base my-2"
            onClick={() => {
                dispatch(temporaryUserlogout());
                history.push('/login');
            }}
        >
            <FormattedMessage id="web_based_audit.open_in_app" />
            <Icon className="text-base pl-2" name="login" />
        </Button>
    return (
        <Fragment>
            {isMobile && !isCompliance && searchItem}
            {isMobile && <MobileMenu>
                <Menu secondary className='top z-0' size='large'>
                    {isCompliance && complianceItems}
                    {!isCompliance && !isTemporaryUserMode && <Fragment>
                        {dashboardItem}
                        {inspectionMenuItems.length === 1 && <Menu.Item as={'span'} key={'menuItem_' + inspectionMenuItems[0].item.path}>
                            {inspectionMenuItems[0].item}
                        </Menu.Item>}
                        {inspectionMenuItems.length > 1 &&
                            inspectionMenuItems.map((menuItem) => {
                                return <Menu.Item active={currentPath === menuItem.path} key={'menuItem_' + menuItem.path}>
                                    {menuItem.item}
                                </Menu.Item>;
                            })
                        }
                        {auditMenuItems.length === 1 && hasAuditFeature && <Menu.Item as={'span'} key={'menuItem_' + auditMenuItems[0].item.path}>
                            {auditMenuItems[0].item}
                        </Menu.Item>}
                        {auditMenuItems.length > 1 && hasAuditFeature &&
                            auditMenuItems.map((menuItem) => {
                                return <Menu.Item active={currentPath === menuItem.path} key={'menuItem_' + menuItem.path}>
                                    {menuItem.item}
                                </Menu.Item>;
                            })
                        }
                        {checklistsItem}
                    </Fragment>}
                    <Divider />
                    {!isTemporaryUserMode && profileItem}
                    <Dropdown.Divider />
                    {usersItem}
                    {groupsItem}
                    {supplierItem}
                    {productionUnitItem}
                    {documentationApprovalItem}
                    {userRolesItem}
                    {dataExportItem}
                    <Divider />
                    <LocaleSelector isMobile={true} />
                    <Divider />
                    <HasFeatureAccess feature='u_inspections' key='menuItem_inspections'>
                        <Menu secondary className='top' size='large'><Menu.Item as={'span'} name='login'>
                            <Login />
                        </Menu.Item></Menu>
                    </HasFeatureAccess>
                </Menu>
            </MobileMenu>}
            {!isMobile &&
                <Fragment>
                    {
                        !isTemporaryUserMode &&
                        (
                            isCompliance ?
                                <Menu secondary className='top z-0 items-center' size='large'>
                                    {complianceItems}
                                </Menu> :
                                <Menu secondary className='top' size='large'>
                                    {dashboardItem}
                                    {inspectionMenuItems.length === 1 && <Menu.Item as={'span'} key={'menuItem_' + inspectionMenuItems[0].item.path}>
                                        {inspectionMenuItems[0].item}
                                    </Menu.Item>}
                                    {inspectionMenuItems.length > 1 &&
                                        <Dropdown className='dm' pointing='top' item text={formatMessage({ id: 'top_menu.item.inspections' })} id='top_menu.item.inspection_menu' data-test-id='top_menu.item.inspection_menu' closeOnBlur={true} closeOnChange={true}>
                                            <Dropdown.Menu>
                                                {inspectionMenuItems.map((menuItem) => {
                                                    return <Dropdown.Item active={currentPath === menuItem.path} key={'menuItem_' + menuItem.path}>
                                                        {menuItem.item}
                                                    </Dropdown.Item>;
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                    {auditMenuItems.length === 1 && hasAuditFeature && <Menu.Item as={'span'} key={'menuItem_' + auditMenuItems[0].item.path}>
                                        {auditMenuItems[0].item}
                                    </Menu.Item>}
                                    {auditMenuItems.length > 1 && hasAuditFeature &&
                                        <Dropdown className='dm' pointing='top' item text={formatMessage({ id: 'top_menu.item.audits' })} id='top_menu.item.audits_menu' data-test-id='top_menu.item.audits_menu' closeOnBlur={true} closeOnChange={true}>
                                            <Dropdown.Menu>
                                                {auditMenuItems.map((menuItem) => {
                                                    return <Dropdown.Item active={currentPath === menuItem.path} key={'menuItem_' + menuItem.path}>
                                                        {menuItem.item}
                                                    </Dropdown.Item>;
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                    {<Fragment>
                                        {checklistsItem}
                                    </Fragment>}
                                    {globalSearchItem}
                                </Menu>
                        )
                    }
                    <Menu secondary className='top' size='large'>
                        <Menu.Menu position='right' className='rm item' key='rm'>
                            <Dropdown closeOnBlur pointing='top right' item className='username' data-test-id='top-right-menu' trigger={<ProfileName profile={profile} />}>
                                <Dropdown.Menu>

                                    {!isTemporaryUserMode && profileItem}
                                    <Dropdown.Divider />
                                    {usersItem}
                                    {groupsItem}
                                    {supplierItem}
                                    {productionUnitItem}
                                    {documentationApprovalItem}
                                    {userRolesItem}
                                    {dataExportItem}
                                    {orgList}
                                    <Dropdown.Divider />
                                    {props.isAuthenticated &&
                                        <Dropdown.Item key='login'>
                                            <Login />
                                        </Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item>
                                <LocaleSelector />
                            </Menu.Item>
                            {loginButton}
                        </Menu.Menu>
                    </Menu>
                </Fragment>
            }
        </Fragment >
    );
};

export default TopMenu;
