import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { AddCommentBodyEntityTypeEnum, Feature, FileResource, SupplierFeaturesDto, SupplierServiceDetailedSupplier } from '../../../backend_api/models';
import { setLayoutAttribute } from '../../../base/baseSlice';
import Loader from '../../../base/components/Loader';
import BackLink from '../../../base/components/basic/BackLink';
import CommentInput from '../../../base/components/comments/CommentInput';
import { getFeaturesSelector, getLayoutSelector, getPermissionsSelector } from '../../../base/selectors';
import { twMerge, useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ContentSection from '../../pageLayouts/components/ContentSection';
import DetailedProductionUnitCustomFields from '../../productionUnits/components/DetailedProductionUnitCustomFields';
import { ProductionUnitOrSupplierHistoryEntry } from '../../productionUnits/components/HistorySection';
import { NewProductionUnitParams } from '../../productionUnits/components/NewProductionUnitParams';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';
import { createProductionUnitWithSupplier, deleteProductionUnit, getProductionUnitDocumentTypeGroupsSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { linkProductionUnitAndSupplier, unlinkProductionUnitAndSupplier } from '../../supplierAndPuRelations/slice/relationSlice';
import { addCommentToSupplier, getDetailedSupplier, getDetailedSupplierLoadingSelector, getDetailedSupplierSelector, getSupplierHistory, getSupplierHistoryLoadingSelector, getSupplierHistorySelector, removeDetailedSupplier, resetSupplierDetail, setDetailedSupplierGroups } from '../slice/suppliersSlice';
import DetailedSupplierAudits from './DetailedSupplierAudits';
import DetailedSupplierGroups from './DetailedSupplierGroups';
import DetailedSupplierInfo from './DetailedSupplierInfo';
import DetailedSupplierProductionUnits from './DetailedSupplierProductionUnits';
import DetailedSupplierUsers from './DetailedSupplierUsers';
import { activateSupplierDocument, approveSupplierDocument, archiveSupplierDocument, deleteSupplierDocument, fetchSupplierDocument, getViewingSupplierDocumentSelector, rejectSupplierDocument, resetSupplierDocumentStatus, submitForApproval, supplierDocumentsSliceActions, unarchiveSupplierDocument, unsubmitSupplierDocument, updateValidityInterval } from '../../supplierDocuments/supplierDocumentsSlice';
import { getDocumentationTypeById } from '../../../base/ui/components/documentViewer/SupplierDocumentApprovalAndValidity';
import SupplierDocumentViewer, { DocumentViewerAction } from '../../../base/ui/components/documentViewer/SupplierDocumentViewer';
import { showSnackbar } from '../../../base/ui/uiSlice';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import ContextMenu, { ContextMenuActionItemType, ContextMenuOverflowItemType } from '../../../base/ui/components/contextMenu/ContextMenu';
import Header from '../../../base/ui/components/headers/Header';
import { MicroInteractionContextMenu } from '../../../base/ui/components/MicroInteractions';
import FileUploadModal from '../../files/components/FileUploadModal';
import CreateSupplierDocumentModal from '../../productionUnits/components/CreateSupplierDocumentModal';
import { ViewAllDocumentsModal } from '../../productionUnits/components/ViewAllDocumentsModal';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';

type Props = {
    className?: string;
    supplierId: string;
};

const CREATE_PRODUCTION_UNIT = 'create_production_units';


const DetailedSupplier = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const supplierIds = location.pathname.split('/')[2];
    const supplierId = supplierIds;

    const features = useSelector(getFeaturesSelector);
    const supplierData = useSelector(getDetailedSupplierSelector);
    const supplierHistory = useSelector(getSupplierHistorySelector);

    const permissions = useSelector(getPermissionsSelector);
    const customFieldsEnabled = features.includes(Feature.SupplierCompliance);

    const [data, setData] = useState<SupplierServiceDetailedSupplier>(supplierData);
    const scrollToTop = !useSelector(getLayoutSelector).dontScrollToTopAfterAction;
    const loading = useSelector(getDetailedSupplierLoadingSelector);
    const loadingHistory = useSelector(getSupplierHistoryLoadingSelector);

    const allowedToCreateProductionUnits = supplierData && (supplierData.features || []).includes(CREATE_PRODUCTION_UNIT);
    const allowedToModifySupplier = supplierData && (supplierData.features || []).includes(SupplierFeaturesDto.Modify);
    const userIsAllowedToShowAudits = features.includes(Feature.SupplierCompliance) && permissions.includes('u_audits');
    const userHasDocumentationTypes = permissions.includes('u_supplier_documentation_types');
    const userIsAllowedToDelete = userIsAllowedTo(supplierData, "delete");

    const [showAllDocuments, setShowAllDocuments] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [tempFileResource, setTempFileResource] = useState<FileResource>(null);
    const [showCreateSupplierDocumentModal, setShowCreateSupplierDocumentModal] = useState(false);

    const isMobile = useIsMobileDevice();

    const independProductionUnits = useHasIndependentProductionUnits();

    useEffect(() => {
        const getSupplierPromise = dispatch(getDetailedSupplier(supplierId));
        const getHistoryPromise = dispatch(getSupplierHistory({ id: props.supplierId }));
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        return () => {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', false));
            dispatch(resetSupplierDetail());
            getHistoryPromise.abort?.();
            getSupplierPromise.abort?.();
        }
    }, [supplierId]);

    useEffect(() => {
        setData(supplierData);
        scrollToTop && window.scrollTo(0, 0);
    }, [supplierData]);

    /* TODO: Block the page while reloading data! */
    function onUserWantsToCreateAndLinkProductionUnit(params: NewProductionUnitParams) {
        /* Create production unit on supplier */
        dispatch(createProductionUnitWithSupplier({
            production_unit_address: [],
            production_unit_group_ids: params.groups.map(g => g.id),
            production_unit_name: params.name,
            production_unit_number: params.number,
            supplier_id: props.supplierId,
            production_unit_contact_person: undefined,
            production_unit_location: undefined
        })).then(() => {
            dispatch(getDetailedSupplier(supplierId))
        })
    }

    function addComment(text: string) {
        dispatch(addCommentToSupplier({
            entity_id: props.supplierId,
            entity_type: AddCommentBodyEntityTypeEnum.Supplier,
            text
        }))
    }

    function confirmDeleteTextId() {
        if (independProductionUnits) {
            return 'globals.confirmation.remove'
        } else {
            return 'globals.confirmation.delete'
        }
    }

    function onUserWantsToUnlinkProductionUnit(productionUnitId: string) {
        if (independProductionUnits) {
            /* unlink */
            dispatch(unlinkProductionUnitAndSupplier({
                production_unit_id: productionUnitId,
                supplier_id: props.supplierId
            })).then(() => {
                dispatch(getDetailedSupplier(supplierId))
            })
        } else {
            /* delete production unit */
            dispatch(deleteProductionUnit({
                production_unit_id: productionUnitId
            })).then(() => {
                dispatch(getDetailedSupplier(supplierId))
            })
        }
    }

    function onUserWantsToLinkProductionUnit(productionUnitId: string) {
        dispatch(linkProductionUnitAndSupplier({
            production_unit_id: productionUnitId,
            supplier_id: props.supplierId
        })).then(() => {
            dispatch(getDetailedSupplier(supplierId))
        })
    }

    const reloadSupplier = () => {
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        dispatch(getDetailedSupplier(supplierId));
    }

    const userWantsToDeleteSupplier = () => {
        dispatch(removeDetailedSupplier({ supplierId }));
    }

    const info = supplierData && <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
        <DetailedSupplierInfo
            name={supplierData.name}
            number={supplierData.number}
            supplierId={supplierId}
            allowedToEdit={userIsAllowedTo(supplierData, SupplierFeaturesDto.Modify)}
        />
    </HasFeatureAccess>;

    const historySection = <ContentSection
        headerText={intl.formatMessage({ id: 'production_units.detailed_page.history_and_comments' })}
        content={
            <>
                <CommentInput handleNewComment={addComment} showUpload={false} />
                <div className={loadingHistory && (!supplierHistory || supplierHistory?.length == 0) ? "-m-4" : ""}>
                    <Loader active={loadingHistory} spinner={<Icon name='circle notch' loading color='green' size='large' />}>
                        {
                            supplierHistory?.map(entry => <ProductionUnitOrSupplierHistoryEntry entry={entry} />)
                        }
                    </Loader>
                </div>

            </>
        }
    />

    const audits = userIsAllowedToShowAudits && <div>
        <h2 className="text-primary !mt-6 !mb-2 font-bold text-2xl"><FormattedMessage id='supplier.audits.label' /></h2>
        <ContentSection
            className=''
            content={<div className={twMerge('', className)}>
                {data && <DetailedSupplierAudits audits={data.audits} supplierId={supplierId} onAuditsUpdated={() => {
                    dispatch(getDetailedSupplier(supplierId));
                    dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                }} />}
            </div>}
        />
    </div>;
    const productionUnits = <div>
        <h2 className="text-primary !mt-6 !mb-2 font-bold text-2xl"><FormattedMessage id='supplier.production_units.label' /></h2>
        <ContentSection
            className=''
            content={<div className={twMerge('', className)}>
                {data && <DetailedSupplierProductionUnits
                    /* Right now, there is no specific feature for linking, so we use the modify access instead. */
                allowedToLinkProductionUnits={allowedToModifySupplier}
                confirmDeleteText={confirmDeleteTextId()}
                isUserAllowedToCreateProductionUnits={allowedToCreateProductionUnits}
                onUserWantsToUnlinkProductionUnit={onUserWantsToUnlinkProductionUnit}
                onUserWantsToCreateAndLinkProductionUnit={onUserWantsToCreateAndLinkProductionUnit}
                onUserWantsToLinkProductionUnit={onUserWantsToLinkProductionUnit}
                productionUnits={data && data.production_units} />}
        </div>}
    />
    </div>;
    const customFields =
        data && customFieldsEnabled && <DetailedProductionUnitCustomFields
            type='supplier'
            productionUnitId={data.id}
            allowedToEdit={userIsAllowedTo(supplierData, SupplierFeaturesDto.Modify)}
            customFieldGroups={data.custom_field_groups}
        />;

    const users = <div>
        <h2 className="text-primary !mt-6 !mb-2 font-bold text-2xl"><FormattedMessage id='supplier.users.label' /></h2>
        <ContentSection
            className=''
            content={<div className={twMerge('', className)}>
                <DetailedSupplierUsers
                    supplierId={supplierId}
                    users={data && data.users}
                    groups={data && data.groups}
                    supplierGroup={data && data.primary_group}
                    onUserUpdated={() => {
                        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                        reloadSupplier();
                    }} />
            </div>}
        />
    </div>

    const groups = data && <DetailedSupplierGroups
        allowedToEdit={supplierData && supplierData.features.includes(SupplierFeaturesDto.Modify)}
        onGroupsUpdated={(groups) => {
            dispatch(setDetailedSupplierGroups({ supplierId, groups: groups }))
        }}
        groups={data.groups}
        productionUnitId={data.id} />;

    const documentation = useSelector(getViewingSupplierDocumentSelector);
    const documentTypeGroups = useSelector(getProductionUnitDocumentTypeGroupsSelector);
    const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);


    const documentActions: DocumentViewerAction = {
        archive: () => {
            dispatch(archiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        unarchive: () => {
            dispatch(unarchiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        remove: () => {
            dispatch(deleteSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.deleted' /></div>,
            }))
        },
        approve: () => {
            dispatch(approveSupplierDocument({ id: documentation?.supplier_document_id }));
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.approved' /></div>,
                action: <Button onPress={openDocumentViewer} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button>,
                icon: 'status_approved-circle'
            }))
        },
        reject: (reason: string) => {
            dispatch(rejectSupplierDocument({ id: documentation?.supplier_document_id, body: { reason } }));
            closeDocumentViewer();
        },
        updateValidity: (startDate, expiredDate) => {
            dispatch(updateValidityInterval({ id: documentation?.supplier_document_id, interval: { start_date: startDate, expiry_date: expiredDate } }));
        },
        reset: () => {
            dispatch(resetSupplierDocumentStatus({ id: documentation?.supplier_document_id }));
        },
        activate: () => {
            dispatch(activateSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
        },
        submit: () => {
            dispatch(submitForApproval({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='production_units.document_viewer.submitted_for_review' /></div>,
                action: <Button onPress={openDocumentViewer} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button>
            }))
        },
        unsubmit: () => {
            dispatch(unsubmitSupplierDocument({ id: documentation.supplier_document_id }));
            closeDocumentViewer();
        },
    };

    const openDocumentViewer = () => {
        dispatch(fetchSupplierDocument({ id: documentation?.supplier_document_id }));
    };

    const closeDocumentViewer = () => {
        dispatch(supplierDocumentsSliceActions.hideDocumentViewer());
    };

    const viewAllDocumentsContextMenuItem: ContextMenuActionItemType = {
        label: 'production_units.detailed_page.context_menu.view_all_documents',
        onPress: () => {
            setShowAllDocuments(true);
        }
    }

    const splitter: ContextMenuOverflowItemType = {
        label: '',
        isSplitter: true
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const deleteContextMenuItem: ContextMenuActionItemType = {
        className: 'text-danger',
        label: 'supplier.detailed_page.context_menu.delete_supplier',
        labelLeft: true,
        icon: 'operation_delete',
        onPress: () => {
            setShowConfirmDeleteModal(true);
        },
    }

    const confirmDeleteModal = <QModal
        isOpen={showConfirmDeleteModal}
        width={511}
        header={intl.formatMessage({ id: 'supplier.detailed_page.context_menu.delete_supplier' })}
        content={
            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "supplier.detailed_page.context_menu.delete_supplier_confirmation" }) }}></div>
        }
        onClose={() => setShowConfirmDeleteModal(false)}
        actionItems={[
            {
                type: ActionTypes.Action, buttonType: ButtonTypes.Danger, text: 'supplier.detailed_page.context_menu.yes_delete_supplier_confirmation', event: () => {
                    setShowConfirmDeleteModal(false);
                    userWantsToDeleteSupplier();
                }, icon: 'operation_delete'
            },
            { type: ActionTypes.Action, buttonType: ButtonTypes.Plain, text: 'globals.confirmation.cancel', event: () => setShowConfirmDeleteModal(false) },
        ]}
    />

    function getOverflowItems() {
        if (!userHasDocumentationTypes && !userIsAllowedToDelete) {
            return [];
        }

        if (userIsAllowedToDelete && userHasDocumentationTypes) {
            return [viewAllDocumentsContextMenuItem, splitter, deleteContextMenuItem];
        }

        if (userIsAllowedToDelete) {
            return [deleteContextMenuItem];
        }

        return [viewAllDocumentsContextMenuItem];
    }

    return <PageContainer>
        <Header
            pageTitle={supplierData?.number}
            reference={supplierData?.name}
            entity={intl.formatMessage({ id: 'page_title.supplier_detail' })}
            contextAction={(userIsAllowedToDelete || documentTypeGroups?.length > 0) && <ContextMenu
                trigger={
                    <Button secondary className={"rounded-md text-sm px-3 py-2"}>
                        <MicroInteractionContextMenu className='text-on-surface-brand leading-rem-sm' />
                        <span className='visually-hidden'><FormattedMessage id='production_units.detailed_page.context_menu.hidden_label' /></span>
                    </Button>
                }
                overflowItems={getOverflowItems()} />
            }
            secondaryAction={userHasDocumentationTypes && <Button onPress={() => setShowFileUploadModal(true)} primary><FormattedMessage id='compliance.documentation.waiting_for_documentation.add' /></Button>}
        />
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.supplier' }, { supplierName: data && data.name })} />

        <Loader active={loading}>
            <SupplierDocumentViewer
                actions={documentActions}
                linkedSupplier={{
                    id: supplierData?.id,
                    name: supplierData?.name,
                    number: supplierData?.number,
                    type: 'supplier'
                }}
                close={closeDocumentViewer}
            />
            <FileUploadModal
                open={showFileUploadModal}
                onClose={() => setShowFileUploadModal(false)}
                onUploadComplete={(file) => {
                    setTempFileResource(file);
                    setShowFileUploadModal(false);
                    setShowCreateSupplierDocumentModal(true);
                }}
                isMobile={isMobile}
                allowMultiUpload={false}
            />
            {confirmDeleteModal}
            <CreateSupplierDocumentModal
                open={showCreateSupplierDocumentModal}
                close={() => setShowCreateSupplierDocumentModal(false)}
                fileResource={tempFileResource}
                supplierId={supplierData?.id}
            />
            {showAllDocuments &&
                <ViewAllDocumentsModal
                    supplierId={supplierData?.id}
                    setShowing={setShowAllDocuments}
                    showing={showAllDocuments}
                    addDocumentation={() => setShowFileUploadModal(true)}
                />
            }
            <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                <div className='w-full md:w-8/12 order-last md:order-first'>
                    {info}
                    {customFields}
                    {productionUnits}
                    {audits}
                    {users}
                    {groups}
                    <BackLink to='/suppliers' className='mb-2' />
                </div>
                <div className='w-full md:w-4/12 order-first md:order-last pt-0 pb-2'>
                    {historySection}
                </div>
            </div>
        </Loader>

    </ PageContainer >
}

function userIsAllowedTo(supplier: SupplierServiceDetailedSupplier, action: string): boolean {
    if (!supplier) {
        return false;
    }

    return supplier.features.includes(action);
}
export default DetailedSupplier;
