import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import {
    ListItems,
    ListItem,
    ArrowTypeEnum,
} from "../../../base/ui/components/listitems/ListItem";
import { FileResource } from "../../../backend_api/models";
import Button from "../../../base/ui/components/buttons/Button";
import DocumentationTypesFilter from "../../filters/components/DocumentationTypesFilter";
import { useAppDispatch } from "../../../store";
import { createSupplierDocumentOnProductionUnit, createSupplierDocumentOnSupplier } from "../../supplierDocuments/supplierDocumentsSlice";

export default function CreateSupplierDocumentModal(props: {
    supplierId?: string
    productionUnitId?: string;
    fileResource: FileResource;
    open: boolean;
    close: () => void;
}) {
    const { open, fileResource, close, productionUnitId, supplierId } = props;
    const dispatch = useAppDispatch();
     
    const [selectedTypes, setSelectedTypes] = React.useState<string[]>([]);

    const openFileInNewWindowTab = () => {
        window.open(fileResource?.preview_url, "_blank");
    };

    const createNewSupplierDocument = () => {
        if(productionUnitId) {
            dispatch(createSupplierDocumentOnProductionUnit({
                media_resource_id: fileResource.id,
                documentation_type_id: selectedTypes[0],
                production_unit_id: productionUnitId
            }));

        } else if(supplierId) {
            dispatch(createSupplierDocumentOnSupplier({
                media_resource_id: fileResource.id,
                documentation_type_id: selectedTypes[0],
                supplier_id: supplierId
            }));
        }
        close();
     };

    return (
        <Modal open={open} closeOnDimmerClick closeOnEscape size="tiny">
            <Modal.Header>
                <FormattedMessage id="audits.edit.add_attachments" />
            </Modal.Header>
            <Modal.Content className="gap-6">
                <div className="flex flex-col gap-6">
                    <ListItems className="border border-default rounded-md">
                        <ListItem
                            icon={"object_file"}
                            className="border-b-0 cursor-pointer"
                            arrow={{
                                type: ArrowTypeEnum.External,
                                onArrowClick: openFileInNewWindowTab,
                            }}
                            onPress={openFileInNewWindowTab}
                        >
                            {fileResource?.name}
                        </ListItem>
                    </ListItems>

                    <DocumentationTypesFilter
                        type={supplierId ? 'supplier' : 'production_unit'}
                        label="production_units.documentation.type_of_file"
                        placeholder="filters.select_type"
                        onFilterSet={(selectedTypes) => {
                            setSelectedTypes(selectedTypes);
                        }}
                        allowMultipleChoice={false}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions className="flex flex-row justify-end my-2 gap-4">
                <Button
                    onPress={createNewSupplierDocument}
                    primary
                >
                    <FormattedMessage id="globals.save" />
                </Button>
                <Button onPress={close}>
                    <FormattedMessage id="globals.confirmation.cancel" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
