/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DocumentationStatus {
    FILE_ADDED = 'file_added',
    ACTIVE = 'active',
    WAITING_FOR_APPROVAL = 'waiting_for_approval',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    ARCHIVED = 'archived',
    DELETED = 'deleted',
}
