import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DocumentationType } from '../../backend_api/models/DocumentationType';
import { request2 } from '../../base/api';
import { AppState } from '../../base/types';
import { sendErrorMessage, sendStatusMessage } from '../messages/actions';
import { SetApprovalResponsibleBody } from '../../backend_api_2/models/SetApprovalResponsibleBody';
import { CreateSupplierDocumentOnSupplierBody, DisplayCustomFieldGroup, DisplayDetailedSupplierDocument, DisplayDetailedSupplierDocumentStateEnum, SupplierDocument, SupplierDocumentExtendedStatusEnum, CreateSupplierDocumentOnProductionUnitBody, RejectSupplierDocumentBody, UpdateValidityIntervalBody } from '../../backend_api/models';

export interface SupplierDocumentsState {
    documentationTypes: DocumentationType[];
    isFetching: boolean;
    isUpdating: boolean;
    documentViewer: SupplierDocument;
    showingDocumentViewer: boolean;
    allDocuments: DisplayDetailedSupplierDocument[];
    fetchingSupplierDocuments: boolean;
}

const initialState: SupplierDocumentsState = {
    documentationTypes: [],
    isFetching: false,
    isUpdating: false,
    documentViewer: null,
    showingDocumentViewer: false,
    allDocuments: [],
    fetchingSupplierDocuments: false
};

export const getDocumentationTypesList = createAsyncThunk<DocumentationType[]>(
    'getDocumentationTypesList',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            // Get documentation types for production units
            const productionUnitsRq = await request2('documentation_types/list_selectable_documentation_types_for_production_units', {
                method: 'get'
            });

            // Get documentation types for suppliers
            const suppliersRq = await request2('documentation_types/list_selectable_documentation_types_for_suppliers', {
                method: 'get'
            });

            // Check if either request failed
            if (!productionUnitsRq.ok || !suppliersRq.ok) {
                console.log('There was an error getting documentation types');
                dispatch(sendErrorMessage(['error_message.documentation_types.get_list'], 3000));
                return rejectWithValue(
                    !productionUnitsRq.ok ? productionUnitsRq : suppliersRq as Response
                );
            }

            // Parse the response data
            const productionUnitsData = await productionUnitsRq.json() as DocumentationType[];
            const suppliersData = await suppliersRq.json() as DocumentationType[];

            // Combine both lists
            return [...productionUnitsData, ...suppliersData];
        } catch (error) {
            console.log('There was an error getting documentation types:', error);
            dispatch(sendErrorMessage(['error_message.documentation_types.get_list'], 3000));
            return rejectWithValue(error);
        }
    }
);

// Create the async thunk for setting approval responsibles
export const setDocumentationTypeApprovalResponsible = createAsyncThunk<
    DocumentationType,
    { documentationTypeId: string, responsibles: SetApprovalResponsibleBody }
>(
    'setDocumentationTypeApprovalResponsible',
    async ({ documentationTypeId, responsibles }, { dispatch, rejectWithValue }) => {
        const rq = await request2(`documentation_types/${documentationTypeId}/set_approval_responsible`, {
            method: 'put',
            body: JSON.stringify(responsibles)
        });

        if (!rq.ok) {
            console.log('There was an error setting approval responsibles');
            dispatch(sendErrorMessage(['error_message.documentation_types.set_approval_responsible'], 3000));
            return rejectWithValue(rq as Response);
        }

        dispatch(sendStatusMessage(['status_message.documentation_types.approval_responsible_updated'], 3000));
        dispatch(getDocumentationTypesList());

        const data = await rq.json();
        return data as DocumentationType;
    }
);

export const fetchSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'fetchSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}`, { method: 'GET' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.fetching_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const activateSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'activateSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/activate`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.activating_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const approveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'approveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/approve`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.approving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const rejectSupplierDocument = createAsyncThunk<SupplierDocument, { id: string, body: RejectSupplierDocumentBody }>(
    'rejectSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/reject`, { method: 'PUT', body: JSON.stringify(params.body) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.rejecting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const resetSupplierDocumentStatus = createAsyncThunk<SupplierDocument, { id: string }>(
    'resetSupplierDocumentStatus',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/reset_status`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.resetting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const unsubmitSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'unsubmitSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/unsubmit`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.unsubmitting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const submitForApproval = createAsyncThunk<SupplierDocument, { id: string }>(
    'submitForApproval',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/submit_for_approval`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.submitting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const updateValidityInterval = createAsyncThunk<SupplierDocument, { id: string, interval: UpdateValidityIntervalBody }>(
    'updateValidityInterval',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/update_validity_interval`, { method: 'PUT', body: JSON.stringify(params.interval) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.updating_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const archiveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'archiveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/archive`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.archiving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const unarchiveSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'unarchiveSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}/unarchive`, { method: 'PUT' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.unarchiving_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const deleteSupplierDocument = createAsyncThunk<SupplierDocument, { id: string }>(
    'deleteSupplierDocument',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/${params.id}`, { method: 'DELETE' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.deleting_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json()
        return data;
    }
);

export const createSupplierDocumentOnProductionUnit = createAsyncThunk<SupplierDocument, CreateSupplierDocumentOnProductionUnitBody>(
    'createSupplierDocumentOnProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/create_supplier_document_on_production_unit`, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.adding_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);

export const createSupplierDocumentOnSupplier = createAsyncThunk<SupplierDocument, CreateSupplierDocumentOnSupplierBody>(
    'createSupplierDocumentOnSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2(`/supplier_documents/create_supplier_document_on_supplier`, { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.adding_documentation_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    }
);


export const getAllDocumentsForProductionUnit = createAsyncThunk<DisplayDetailedSupplierDocument[], { production_unit_id: string }>(
    'getAllDocumentsForProductionUnit',
    async (params, { dispatch, rejectWithValue }) => {
        const url = `supplier_documents/get_all_for_production_unit/${params.production_unit_id}`;
        const rq = await request2(url, { method: 'get' });

        if (!rq.ok) {
            console.log('There was an error getting documents for production unit');
            dispatch(sendErrorMessage(['error_message.production_units.get_all_documents'], 3000));
            return rejectWithValue(rq as Response);
        }

        const data = await rq.json();
        return data as DisplayDetailedSupplierDocument[];
    }
);

export const getAllDocumentsForSupplier = createAsyncThunk<DisplayDetailedSupplierDocument[], { supplier_id: string }>(
    'getAllDocumentsForSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        const url = `supplier_documents/get_all_for_supplier/${params.supplier_id}`;
        const rq = await request2(url, { method: 'get' });

        if (!rq.ok) {
            console.log('There was an error getting documents for supplier');
            dispatch(sendErrorMessage(['error_message.suppliers.get_all_documents'], 3000));
            return rejectWithValue(rq as Response);
        }

        const data = await rq.json();
        return data as DisplayDetailedSupplierDocument[];
    }
);

export const supplierDocumentsSlice = createSlice({
    name: 'supplierDocuments',
    initialState,
    reducers: {
        showCustomFieldDocumentViewer: (
            state: SupplierDocumentsState,
            action: PayloadAction<{ supplierDocument: SupplierDocument }>) => {
            state.documentViewer = action.payload.supplierDocument;
            state.showingDocumentViewer = true;
        },
        hideDocumentViewer: (state) => {
            state.showingDocumentViewer = false;
        }
    },
    extraReducers: builder => {
        builder.addCase(getDocumentationTypesList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getDocumentationTypesList.fulfilled, (state, action) => {
            state.documentationTypes = action.payload;
            state.isFetching = false;
        });
        builder.addCase(getDocumentationTypesList.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.pending, (state) => {
            state.isUpdating = true;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.fulfilled, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(setDocumentationTypeApprovalResponsible.rejected, (state) => {
            state.isUpdating = false;
        });
        builder.addCase(fetchSupplierDocument.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer = action.payload;
            state.showingDocumentViewer = true;
            state.isFetching = false;
        });
        builder.addCase(fetchSupplierDocument.rejected, (state) => {
            state.isFetching = false;
        });
        builder.addCase(createSupplierDocumentOnProductionUnit.fulfilled, (state, action) => {
            state.documentViewer = action.payload;
            state.showingDocumentViewer = true;
        });
        builder.addCase(createSupplierDocumentOnSupplier.fulfilled, (state, action) => {
            state.documentViewer = action.payload;
            state.showingDocumentViewer = true;
        });
        builder.addCase(activateSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer.extended_status = action.payload.extended_status;
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });
        builder.addCase(approveSupplierDocument.fulfilled, (state, action) => {
            state.documentViewer.extended_status = action.payload.extended_status;
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });
        builder.addCase(rejectSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });
        builder.addCase(resetSupplierDocumentStatus.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });

        builder.addCase(unsubmitSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });
        builder.addCase(submitForApproval.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });
        builder.addCase(updateValidityInterval.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
                state.documentViewer.start_date = action.payload.start_date;
                state.documentViewer.expiry_date = action.payload.expiry_date;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    valid_from_date: new Date(action.payload.start_date),
                    valid_to_date: new Date(action.payload.expiry_date),
                } : doc);
            }
        });
        builder.addCase(archiveSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    archived: true
                } : doc);
            }
        });
        builder.addCase(unarchiveSupplierDocument.fulfilled, (state, action) => {
            if (state.documentViewer) {
                state.documentViewer.extended_status = action.payload.extended_status;
            }
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.map(doc => doc.id === action.payload.supplier_document_id ? {
                    ...doc,
                    archived: false,
                    state: action.payload.extended_status as any
                } : doc);
            }
        });

        builder.addCase(deleteSupplierDocument.fulfilled, (state, action) => {
            if(state.allDocuments) {
                state.allDocuments = state.allDocuments.filter(doc => doc.id !== action.payload.supplier_document_id);
            }
        });

        builder.addCase(getAllDocumentsForSupplier.pending, (state, action) => { state.fetchingSupplierDocuments = true });
        builder.addCase(getAllDocumentsForSupplier.fulfilled, (state, action) => { state.allDocuments = action.payload; state.fetchingSupplierDocuments = false });
        builder.addCase(getAllDocumentsForSupplier.rejected, (state, action) => { state.fetchingSupplierDocuments = false });

        builder.addCase(getAllDocumentsForProductionUnit.pending, (state, action) => { state.fetchingSupplierDocuments = true });
        builder.addCase(getAllDocumentsForProductionUnit.fulfilled, (state, action) => { state.allDocuments = action.payload; state.fetchingSupplierDocuments = false });
        builder.addCase(getAllDocumentsForProductionUnit.rejected, (state, action) => { state.fetchingSupplierDocuments = false });

    }
});

export const getDocumentationTypesListSelector = createSelector(
    [(state: AppState): DocumentationType[] => state.app.supplierDocuments?.documentationTypes || []],
    (documentationTypes) => documentationTypes
);

export const getDocumentationTypesFetchingSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.isFetching],
    (isFetching) => isFetching
);

export const getDocumentationTypesUpdatingSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.isUpdating],
    (isUpdating) => isUpdating
);
export const getViewingSupplierDocumentSelector = createSelector(
    [(state: AppState): SupplierDocument | null => state.app.supplierDocuments?.documentViewer],
    (documentViewer) => documentViewer
)

export const getDocumentViewerVisibilitySelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.showingDocumentViewer],
    (showingDocumentViewer) => showingDocumentViewer
)

export const getAllSupplierDocumentsSelector = createSelector(
    [(state: AppState): DisplayDetailedSupplierDocument[] => state.app.supplierDocuments?.allDocuments || []],
    (documents) => documents
);

export const getFetchingSupplierDocumentsSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.fetchingSupplierDocuments],
    (fetchingSupplierDocuments) => fetchingSupplierDocuments
);

export const getIsFetchingSupplierDocumentSelector = createSelector(
    [(state: AppState): boolean => state.app.supplierDocuments?.isFetching],
    (isFetching) => isFetching
);
const { actions } = supplierDocumentsSlice;
export const supplierDocumentsSliceActions = actions;

export default supplierDocumentsSlice.reducer;

export function updateSupplierDocumentState(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        if (group.documentation_types?.find(docType => docType.detailed_supplier_documents.find(doc => doc.id === newDocument.supplier_document_id))) {
            return {
                ...group,
                documentation_types: group.documentation_types.map(docType => {
                    if (docType.id === newDocument.documentation_type_id) {
                        return {
                            ...docType,
                            detailed_supplier_documents: docType.detailed_supplier_documents?.map(doc => {
                                if (doc.id === newDocument.supplier_document_id) {
                                    return {
                                        ...doc,
                                        state: newDocument.extended_status === SupplierDocumentExtendedStatusEnum.Archived ? doc.state : mapDisplayedDetailSupplierDocumentState(newDocument.extended_status),
                                        archived: newDocument.extended_status == SupplierDocumentExtendedStatusEnum.Archived,
                                        valid_from_date: newDocument.start_date as any,
                                        valid_to_date: newDocument.expiry_date as any,
                                        expiry_required: newDocument.expiry_required,
                                        approval_required: newDocument.approval_required,
                                        permissions: newDocument.permissions
                                    };
                                }
                                return doc;
                            })
                        };
                    }
                    return docType;
                })
            };
        }
        return group;
    });
    return updatedGroups;
}

export function addNewSupplierDocumentToProductionUnit(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        return {
            ...group,
            documentation_types: group.documentation_types.map(docType => {
                if (docType.id === newDocument.documentation_type_id) {
                    return {
                        ...docType,
                        detailed_supplier_documents: docType.detailed_supplier_documents?.concat([{
                            approver: null,
                            archived: false,
                            documentation_type_code: docType.code,
                            documentation_type_description: docType.description,
                            documentation_type_id: newDocument.documentation_type_id,
                            documentation_type_name: docType.name,
                            id: newDocument.supplier_document_id,
                            media_resources: newDocument.media_resources,
                            rejector: null,
                            responsible_groups: [],
                            responsible_users: [],
                            state: mapDisplayedDetailSupplierDocumentState(newDocument.extended_status),
                            uploader: null,
                            valid_from_date: newDocument.start_date,
                            valid_to_date: newDocument.expiry_date,
                            expiry_required: newDocument.expiry_required,
                            approval_required: newDocument.approval_required,
                            permissions: newDocument.permissions
                        } as any])
                    };
                }
                return docType;
            })
        };
    });
    return updatedGroups;
}

function mapDisplayedDetailSupplierDocumentState(state: SupplierDocumentExtendedStatusEnum): DisplayDetailedSupplierDocumentStateEnum {
    switch (state) {
        case SupplierDocumentExtendedStatusEnum.Active:
            return DisplayDetailedSupplierDocumentStateEnum.Active;
        case SupplierDocumentExtendedStatusEnum.Approved:
            return DisplayDetailedSupplierDocumentStateEnum.Approved;
        case SupplierDocumentExtendedStatusEnum.Rejected:
            return DisplayDetailedSupplierDocumentStateEnum.Rejected;
        case SupplierDocumentExtendedStatusEnum.WaitingForApproval:
            return DisplayDetailedSupplierDocumentStateEnum.WaitingForApproval;
        case SupplierDocumentExtendedStatusEnum.FileAdded:
            return DisplayDetailedSupplierDocumentStateEnum.FileAdded;
        case SupplierDocumentExtendedStatusEnum.Expired:
            return DisplayDetailedSupplierDocumentStateEnum.Expired;
        case SupplierDocumentExtendedStatusEnum.Archived:
            return DisplayDetailedSupplierDocumentStateEnum.Archived;
        case SupplierDocumentExtendedStatusEnum.Deleted:
            return DisplayDetailedSupplierDocumentStateEnum.Deleted;
    }
}

export function deleteSupplierDocumentFromProductionUnit(newDocument: SupplierDocument, customFieldGroups: DisplayCustomFieldGroup[]): DisplayCustomFieldGroup[] {
    const updatedGroups = customFieldGroups.map(group => {
        if (group.documentation_types?.find(docType => docType.detailed_supplier_documents.find(doc => doc.id === newDocument.supplier_document_id))) {
            return {
                ...group,
                documentation_types: group.documentation_types.map(docType => {
                    if (docType.id === newDocument.documentation_type_id) {
                        const documentIndex = docType.detailed_supplier_documents?.findIndex(doc => doc.id === newDocument.supplier_document_id);
                        if (documentIndex !== undefined && documentIndex !== -1) {
                            return {
                                ...docType,
                                detailed_supplier_documents: docType.detailed_supplier_documents?.filter(doc => doc.id !== newDocument.supplier_document_id)
                            };
                        }
                    }
                    return docType;
                })
            };
        }
        return group;
    });
    return updatedGroups;
}
