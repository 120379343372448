import { format } from 'date-fns';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, FormField, Popup } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';
import Button from '../../../../base/ui/components/buttons/Button';
import {
    Audit,
    AuditCheckpoint,
    AuditCustomFieldDate,
    AuditCustomFieldDateValue,
    AuditCustomFieldMultipleChoiceValue,
    AuditCustomFieldNotSet,
    AuditCustomFieldNumber,
    AuditCustomFieldNumberValue,
    AuditCustomFieldOptionIds,
    AuditCustomFieldText,
    AuditCustomFieldTextValue,
    AuditCustomFieldValue,
    SetCustomFieldDate,
    SetCustomFieldDateValue,
    SetCustomFieldNumber,
    SetCustomFieldNumberValue,
    SetCustomFieldOptionIds,
    SetCustomFieldText,
    SetCustomFieldTextValue,
    SetFieldMultipleChoiceValue,
    TextWithTranslation,
    AuditCustomFieldSupplierDocumentValue,
    AuditCustomFieldDocumentValue,
    SetFieldSupplierDocumentValue,
    SetCustomFieldSupplierDocuments,
    SetCustomFieldCheckpointDocument
} from '../../../../backend_api_2';
import { DisplayDetailedSupplierDocument } from '../../../../backend_api/models/DisplayDetailedSupplierDocument';
import DatePicker3 from '../../../../base/components/basic/DatePicker3';
import Icon from '../../../../base/components/basic/Icon';
import NewIcon from '../../../../base/ui/components/icons/Icon';
import Input from '../../../../base/components/basic/Input';
import { getLocaleLanguageString, isDateExpired, isUndefinedOrNull, isUndefinedOrNullOrEmptyString, sortArrayByKey } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { searchOptionTextAndDescription } from '../../../inspections/Utils';
import { updateProductionUnitCustomFieldCheckpoint, updateSupplierCustomFieldCheckpoint } from '../../slices/auditSlice';
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { AuditCheckpointTitleAndActionMenu, DocumentationProductionUnitMap, isAuditImmutable } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';
import { doesCheckpointRequireMandatoryInput, isDocumentValid } from './mandatory';
import { TranslateTextWithTranslation, transformTextWithTranslationV2toV1 } from '../../../../base/components/basic/TextWithTranslation';
import { ListItem, ListItems } from '../../../../base/ui/components/listitems/ListItem';
import { Sizes } from '../../../../base/components/types';
import DetailedSupplierDocument from '../../../productionUnits/components/DetailedSupplierDocument';
import { DisplayDetailedSupplierDocumentStateEnum } from '../../../../backend_api/models/DisplayDetailedSupplierDocument';
import { FileResource } from '../../../../backend_api/models/FileResource';
import { useIsMobileDevice } from '../../../../base/utils';
import FileUploadModal from '../../../files/components/FileUploadModal';
import { SupplierDocument, SupplierDocumentExtendedStatusEnum } from '../../../../backend_api/models';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import SupplierDocumentViewer, { DocumentViewerAction } from '../../../../base/ui/components/documentViewer/SupplierDocumentViewer';
import { DEFAULT_DATE_FORMAT2 } from '../../../../base/config';
import { getViewingSupplierDocumentSelector, supplierDocumentsSliceActions } from '../../../supplierDocuments/supplierDocumentsSlice';

interface FileUploadResponse extends FileResource {
    error?: boolean;
}

export function ProductionUnitCustomFieldCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean,
    documentationProductionUnitMap: DocumentationProductionUnitMap
}) {
    const { audit, checkpoint, triedToSubmit, documentationProductionUnitMap } = props;
    const customFields = checkpoint?.production_unit_custom_fields;
    const requiresInput = doesCheckpointRequireMandatoryInput(checkpoint);

    const dispatch = useAppDispatch();

    function updateCustomField(customField: AuditCustomFieldValue, newValue: (SetCustomFieldTextValue | SetCustomFieldDateValue | SetCustomFieldNumberValue | SetFieldMultipleChoiceValue)) {
        dispatch(updateProductionUnitCustomFieldCheckpoint({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            custom_field_id: customField.id,
            new_value: newValue
        }))
    }

    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
            <div>
                {
                    customFields?.map(customField => {
                        switch (customField.value.type) {
                            case AuditCustomFieldTextValue.type.TEXT:
                                return <TextCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldNumberValue.type.NUMBER:
                                return <NumberCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldDateValue.type.DATE:
                                return <DateCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldMultipleChoiceValue.type.MULTIPLE_CHOICE:
                                return <MultipleChoiceCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldSupplierDocumentValue.type.SUPPLIER_DOCUMENT:
                                return <SupplierDocumentCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput} documentationProductionUnitMap={documentationProductionUnitMap} />
                        }
                    })
                }
            </div>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
        </div>
    )
}

function TextCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldTextValue) => void,
    requiresInput: boolean
}) {
    const { audit, customField, doUpdate, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);

    const value = customField.value as AuditCustomFieldTextValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldText).text;
    const [textValue, setTextValue] = React.useState(hasNewValue ? newValue : previousValue);

    function updateCustomField(e) {
        const value = e.currentTarget.value;
        const shouldUpdateValue = !isUndefinedOrNull(value);
        if (shouldUpdateValue) {
            doUpdate(customField, {
                type: SetCustomFieldTextValue.type.TEXT,
                new_value: {
                    type: SetCustomFieldText.type.TEXT,
                    text: e.currentTarget.value
                }
            })
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldTextValue.type.TEXT,
            new_value: {
                type: SetCustomFieldText.type.TEXT,
                text: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-col w-full sm:max-w-[40rem]'>
                <div className='flex flex-row justify-between items-center'>
                    <label className='text-sm text-secondary mb-1' htmlFor={customField.id}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                    <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput} />
                </div>
                {
                    requiresInput && !previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field' />
                    </div>
                }

                {
                    requiresInput && !!previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center'>
                            <Icon name='error' className='text-lg mr-1' />
                            <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm' />
                        </div>
                        <ConfirmButton onClick={confirmCustomField} />
                    </div>
                }
            </div>

            <Input
                id={customField.id}
                inputClass='w-full sm:max-w-[40rem]'
                type="text"
                name="street"
                onBlur={updateCustomField}
                iconPosition="left"
                iconClass="text-brand w-12 justify-center flex"
                value={textValue}
                onChange={(e) => setTextValue(e.currentTarget.value)}
                disabled={isAuditImmutable(audit)}
            />
        </FormField>
    )
}

function NumberCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldNumberValue) => void,
    requiresInput: boolean
}) {
    const { audit, customField, doUpdate, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);
    const value = customField.value as AuditCustomFieldNumberValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldNumber).number;

    const [numberValue, setNumberValue] = React.useState(hasNewValue ? newValue : previousValue);
    const invalidInput = (previousValue || hasNewValue) && (isUndefinedOrNullOrEmptyString(numberValue) || isNaN(Number(numberValue)));

    const elementId = uuidv1();

    function updateCustomField(e) {
        const value = e.currentTarget.value;
        const isValidNumber = !isUndefinedOrNullOrEmptyString(value);
        if (isValidNumber) {
            doUpdate(customField, {
                type: SetCustomFieldNumberValue.type.NUMBER,
                new_value: {
                    type: SetCustomFieldNumber.type.NUMBER,
                    number: value
                }
            })
        } else {
            setNumberValue(hasNewValue ? newValue : previousValue);
        }
    }

    function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key;
        if (key == 'e' || key == 'E' || key == '+' || key == ',') {
            return e.preventDefault();
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldNumberValue.type.NUMBER,
            new_value: {
                type: SetCustomFieldNumber.type.NUMBER,
                number: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-row justify-between items-center w-full sm:max-w-[40rem]'>
                <label className='text-sm text-secondary mb-1' htmlFor={elementId}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput} />
            </div>
            {
                requiresInput && !previousValue && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field' />
                </div>
            }

            {
                requiresInput && !!previousValue && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row justify-between items-center w-full sm:max-w-[40rem]'>
                    <div className='flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm' />
                    </div>
                    <ConfirmButton onClick={confirmCustomField} />
                </div>
            }

            {
                invalidInput &&
                <div className='flex flex-row items-center text-status-rejected text-sm w-full sm:max-w-[40rem]'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.invalid_number' />
                </div>
            }

            <Input
                id={elementId}
                inputClass='w-full sm:max-w-[40rem]'
                type="number"
                onChange={(e) => setNumberValue(e.target.value)}
                onKeyDown={handleOnKeyDown}
                value={numberValue}
                onBlur={updateCustomField}
                disabled={isAuditImmutable(audit)}
            />
        </FormField>
    )
}

function DateCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldDateValue) => void,
    requiresInput: boolean
}) {
    const { audit, customField, doUpdate, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);

    const value = customField.value as AuditCustomFieldDateValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldDate)?.date;
    const elementId = uuidv1();

    function updateCustomField(date: Date) {
        if (date) {
            const newDate = format(date, 'yyyy-MM-dd');
            doUpdate(customField, {
                type: SetCustomFieldDateValue.type.DATE,
                new_value: {
                    type: SetCustomFieldDate.type.DATE,
                    date: newDate
                }
            })
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldDateValue.type.DATE,
            new_value: {
                type: SetCustomFieldDate.type.DATE,
                date: previousValue
            }
        })
    }

    return (
        <FormField>
            <div className='flex flex-col w-full sm:max-w-[40rem] pb-4'>
                <div className='flex flex-row justify-between items-center'>
                    <label className='text-sm text-secondary mb-1' htmlFor={elementId}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                    <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput} />
                </div>
                {
                    requiresInput && !previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field' />
                    </div>
                }

                {
                    requiresInput && !!previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center'>
                            <Icon name='error' className='text-lg mr-1' />
                            <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm' />
                        </div>
                        <ConfirmButton onClick={confirmCustomField} />
                    </div>
                }

                <DatePicker3
                    id={elementId}
                    pastDates={true}
                    date={hasNewValue ? newValue : previousValue}
                    handleChange={updateCustomField}
                    disabled={isAuditImmutable(audit)}
                    name="pu-cf-date"
                />
            </div>
        </FormField>
    )
}

function MultipleChoiceCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetFieldMultipleChoiceValue) => void,
    requiresInput: boolean
}) {
    const { audit, doUpdate, customField, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);
    const value = customField.value as AuditCustomFieldMultipleChoiceValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldOptionIds).option_ids;

    const needsConfirm = requiresInput && previousValue?.length > 0 && !hasNewValue;

    const selectedOptionIds = hasNewValue ? newValue : previousValue;
    const multipleSelectionAllowed = value.multiple_choices_allowed;
    let options = value.options.map(o => {
        return {
            key: o.option_id,
            value: o.option_id,
            text: o.name.text,
            id: o.option_id
        }
    })
    sortArrayByKey(options, 'text');

    function updateCustomField(options: string[]) {
        doUpdate(customField, {
            type: SetFieldMultipleChoiceValue.type.MULTIPLE_CHOICE,
            new_value: {
                type: SetCustomFieldOptionIds.type.OPTION_IDS,
                option_ids: options
            }
        })
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetFieldMultipleChoiceValue.type.MULTIPLE_CHOICE,
            new_value: {
                type: SetCustomFieldOptionIds.type.OPTION_IDS,
                option_ids: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-row justify-between items-center w-full sm:max-w-[40rem]'>
                <label className='text-sm text-secondary mb-1' htmlFor={customField.id}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                <CFConfirmButton hasPreviousValue={previousValue?.length > 0} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput} />
            </div>
            {
                requiresInput && previousValue?.length == 0 && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field' />
                </div>
            }

            {
                needsConfirm &&
                <div className='text-status-rejected text-sm flex flex-row justify-between items-center w-full sm:max-w-[40rem]'>
                    <div className='flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm' />
                    </div>
                    <ConfirmButton onClick={confirmCustomField} />
                </div>
            }
            <Dropdown
                id={customField.id}
                className='flex flex-row w-full sm:max-w-[40rem]'
                selection
                disabled={isAuditImmutable(audit)}
                options={options}
                value={multipleSelectionAllowed ? selectedOptionIds : selectedOptionIds[0]}
                multiple={multipleSelectionAllowed}
                search={searchOptionTextAndDescription}
                placeholder={selectedOptionIds.length === 0 && intl.formatMessage({ id: 'globals.select' })}
                renderLabel={(item) => {
                    return ({
                        style: { color: 'white' },
                        className: 'selectLabel justify-between flex-0',
                        content: <div className='flex text-white'>{item.text}</div>,
                    });
                }}
                onChange={(e, option) => {
                    let value;
                    if (option.value !== null) {
                        value = multipleSelectionAllowed ? option.value : [option.value];
                    } else {
                        value = [];
                    }
                    updateCustomField(value)
                }}
            />
        </FormField>
    )
}

function SupplierDocumentCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: any) => void,
    requiresInput: boolean,
    documentationProductionUnitMap: DocumentationProductionUnitMap
}) {
    const { audit, customField, doUpdate, requiresInput, documentationProductionUnitMap } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);
    const [showModal, setShowModal] = React.useState(false);
    const isMobile = useIsMobileDevice();
    const fileUploadRef = React.useRef(null);
    const dispatch = useAppDispatch();

    const value = customField.value as AuditCustomFieldSupplierDocumentValue;

    const documentationTypeTooltipContent = <div className='p-1'>
        <div className="text-normal text-base font-bold text-primary pb-3"><TranslateTextWithTranslation twt={value.documentation_type_name} /></div>
        <div className="text-secondary text-normal text-sm"><FormattedMessage id={"production_units.supplier_document.document_description"} /></div>
        <div className="text-normal text-base text-primary pb-3"><TranslateTextWithTranslation twt={value.documentation_type_description} /></div>
    </div>

    const isEmpty = Array.isArray(value.existing_documents) && value.existing_documents.length === 0 && Array.isArray(value.new_documents) && value.new_documents.length === 0;

    const handleUploadComplete = (file: FileUploadResponse, allFiles?: FileUploadResponse[]) => {
        if (file.error) {
            return;
        }

        const transformedExistingDocuments: SetCustomFieldCheckpointDocument[] = (value.new_documents || []).map(doc => ({
            start_date: doc.start_date || '',
            expiration_date: doc.expiration_date || '',
            file_resource_id: doc.file_resource?.id,
            status: SetCustomFieldCheckpointDocument.status.FILE_ADDED
        }));

        const newDocument: SetCustomFieldCheckpointDocument = {
            start_date: format(new Date(), DEFAULT_DATE_FORMAT2),
            expiration_date: '',
            file_resource_id: file?.id,
            status: SetCustomFieldCheckpointDocument.status.FILE_ADDED
        };

        const allDocuments = [...transformedExistingDocuments, newDocument];

        doUpdate(customField, {
            type: SetFieldSupplierDocumentValue.type.SUPPLIER_DOCUMENT,
            new_value: {
                type: SetCustomFieldSupplierDocuments.type.SUPPLIER_DOCUMENTS,
                supplier_documents: allDocuments
            }
        });

        dispatch(supplierDocumentsSliceActions.showCustomFieldDocumentViewer({
            supplierDocument: {
                approval_required: value.requires_expiry,
                approver_id: null,
                documentation_type_id: value.documentation_type_id,
                expiry_date: '',
                expiry_required: value.requires_expiry,
                extended_status: SupplierDocumentExtendedStatusEnum.FileAdded,
                media_resources: [file],
                rejection_reason: null,
                rejector_id: null,
                start_date: format(new Date(), DEFAULT_DATE_FORMAT2),
                supplier_document_id: null,
                uploader_id: null,
                permissions: []
            }
        }));

        setShowModal(false);
    };

    const handleUpdateNewDocumentWithValidity = (startDate: string, expirationDate: string) => {
        // const existingDocuments = (customField.value as AuditCustomFieldSupplierDocumentValue).existing_documents;
        const newDocuments = (customField.value as AuditCustomFieldSupplierDocumentValue).new_documents;

        // const allDocuments = existingDocuments.concat(newDocuments);

        const newDocument: SetCustomFieldCheckpointDocument = {
            start_date: startDate,
            expiration_date: expirationDate,
            file_resource_id: documentation?.media_resources[0]?.id,
            status: SetCustomFieldCheckpointDocument.status.FILE_ADDED
        };

        doUpdate(customField, {
            type: SetFieldSupplierDocumentValue.type.SUPPLIER_DOCUMENT,
            new_value: {
                type: SetCustomFieldSupplierDocuments.type.SUPPLIER_DOCUMENTS,
                supplier_documents: newDocuments.map(doc => {
                    if (doc.file_resource?.id == newDocument.file_resource_id) {
                        return newDocument;
                    }

                    return {
                        start_date: doc.start_date,
                        expiration_date: doc.expiration_date,
                        file_resource_id: doc.file_resource?.id,
                        status: doc.status
                    };
                })
            }
        });
    }

    const handleDelete = (documentIndex: number) => {
        const updatedDocuments = value.new_documents.filter((_, index) => index !== documentIndex);

        doUpdate(customField, {
            type: SetFieldSupplierDocumentValue.type.SUPPLIER_DOCUMENT,
            new_value: {
                type: SetCustomFieldSupplierDocuments.type.SUPPLIER_DOCUMENTS,
                supplier_documents: updatedDocuments.map(doc => ({
                    start_date: doc.start_date || '',
                    expiration_date: doc.expiration_date || '',
                    file_resource_id: doc.file_resource?.id,
                    status: SetCustomFieldCheckpointDocument.status.FILE_ADDED
                }))
            }
        });
    };

    const mapToSupplierDocument = (document: AuditCustomFieldDocumentValue): SupplierDocument => {
        return {
            approval_required: value.requires_approval,
            approver_id: null,
            documentation_type_id: value.documentation_type_id,
            expiry_date: document.expiration_date,
            expiry_required: value.requires_expiry,
            extended_status: mapToSupplierDocumentStatus(document),
            media_resources: document.file_resource ? [document.file_resource as any] : [],
            rejection_reason: null,
            rejector_id: null,
            start_date: document.start_date,
            supplier_document_id: document.file_resource?.id,
            uploader_id: null,
            permissions: ['edit_supplier_documents']
        };
    };

    const mapToSupplierDocumentStatus = (documentation: AuditCustomFieldDocumentValue): SupplierDocumentExtendedStatusEnum => {
        if (documentation.status == AuditCustomFieldDocumentValue.status.ARCHIVED) {
            return SupplierDocumentExtendedStatusEnum.Archived;
        }

        if (documentation.status == AuditCustomFieldDocumentValue.status.DELETED) {
            return SupplierDocumentExtendedStatusEnum.Deleted;
        }

        if (isDateExpired(documentation.expiration_date)) {
            return SupplierDocumentExtendedStatusEnum.Expired;
        }

        const status = documentation.status;
        switch (status) {
            case AuditCustomFieldDocumentValue.status.FILE_ADDED:
                return SupplierDocumentExtendedStatusEnum.FileAdded;
            case AuditCustomFieldDocumentValue.status.WAITING_FOR_APPROVAL:
                return SupplierDocumentExtendedStatusEnum.WaitingForApproval;
            case AuditCustomFieldDocumentValue.status.APPROVED:
                return SupplierDocumentExtendedStatusEnum.Approved;
            case AuditCustomFieldDocumentValue.status.REJECTED:
                return SupplierDocumentExtendedStatusEnum.Rejected;
        }
    };


    const mapToDetailedSupplierDocument = (document: AuditCustomFieldDocumentValue): DisplayDetailedSupplierDocument => {
        let state: DisplayDetailedSupplierDocumentStateEnum;
        switch (document.status) {
            case AuditCustomFieldDocumentValue.status.FILE_ADDED:
                state = DisplayDetailedSupplierDocumentStateEnum.FileAdded;
                break;
            case AuditCustomFieldDocumentValue.status.WAITING_FOR_APPROVAL:
                state = DisplayDetailedSupplierDocumentStateEnum.WaitingForApproval;
                break;
            case AuditCustomFieldDocumentValue.status.APPROVED:
                state = DisplayDetailedSupplierDocumentStateEnum.Approved;
                break;
            case AuditCustomFieldDocumentValue.status.REJECTED:
                state = DisplayDetailedSupplierDocumentStateEnum.Rejected;
                break;
            case AuditCustomFieldDocumentValue.status.ACTIVE:
                state = DisplayDetailedSupplierDocumentStateEnum.Active;
                break;
            default:
                state = DisplayDetailedSupplierDocumentStateEnum.FileAdded;
        }

        if (isDateExpired(document.expiration_date)) {
            state = DisplayDetailedSupplierDocumentStateEnum.Expired;
        }

        return {
            media_resources: [],
            approver: null,
            archived: false,
            documentation_type_code: value.documentation_type_code || '',
            documentation_type_description: transformTextWithTranslationV2toV1(value.documentation_type_description),
            documentation_type_id: value.documentation_type_id,
            documentation_type_name: transformTextWithTranslationV2toV1(value.documentation_type_name),
            id: document.file_resource?.id || '',
            rejector: null,
            approval_required: value.requires_approval,
            expiry_required: value.requires_expiry,
            responsible_groups: [],
            responsible_users: [],
            state: state,
            uploader: null,
            valid_from_date: document.start_date ? new Date(document.start_date) : null,
            valid_to_date: document.expiration_date ? new Date(document.expiration_date) : null,
            permissions: ["edit_supplier_documents"]
        };
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const closeDocumentViewer = () => {
        dispatch(supplierDocumentsSliceActions.hideDocumentViewer());
    }

    const handleViewDocument = (document: AuditCustomFieldDocumentValue) => {
        dispatch(supplierDocumentsSliceActions.showCustomFieldDocumentViewer({
            supplierDocument: mapToSupplierDocument(document)
        }));
    }
    const documentation = useSelector(getViewingSupplierDocumentSelector);
    const existingDocuments = value.existing_documents;
    const isViewingExistingDocument = existingDocuments.some(doc => doc.file_resource?.id == documentation?.media_resources[0]?.id);
    const isProductionUnitCustomField = documentationProductionUnitMap.productionUnit[documentation?.media_resources[0]?.id];

    const hasActiveDocuments = existingDocuments.some(isDocumentValid);

    const actions: DocumentViewerAction = {
        updateValidity: handleUpdateNewDocumentWithValidity,
        remove: () => {
            const index = value.new_documents.findIndex(doc => doc.file_resource?.id == documentation?.media_resources[0]?.id);
            handleDelete(index);
            closeDocumentViewer();
        }
    };

    return (
        <div className='sm:max-w-[40rem] pb-3'>
            {
                requiresInput && value.new_documents.length == 0 && !hasActiveDocuments &&
                <div className='text-status-rejected text-sm flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.no_supplier_documents' />
                </div>
            }

            {isEmpty ? (
                <div className={`flex flex-col relative w-full bg-default py-3`}>
                    <div className="flex mb-1">
                        <div className="text-normal text-sm text-secondary"><TranslateTextWithTranslation twt={value.documentation_type_name} /></div>
                    </div>
                    <ListItems className={'border rounded-md border-b-0'}>
                        <ListItem iconOnRight={true} icon={<Popup trigger={<NewIcon size={Sizes.XLarge} name='operation_get-info' />} on="hover">
                            {documentationTypeTooltipContent}
                        </Popup>} slim={true} graphicItemClass='min-w-10 border-r-0 border-l'>
                            <div className="flex items-center w-full px-3">
                                <div className='flex items-center text-secondary italic'>
                                    <FormattedMessage id={"production_units.supplier_document.status.no_active_document"} />
                                </div>
                                <div className='ml-auto'>
                                    <Button
                                        className="bg-surface-plain px-3 py-0 text-on-surface-plain text-normal text-xs"
                                        icon="operation_add"
                                        labelLeft={true}
                                        label="production_units.supplier_document.context_menu.add"
                                        onPress={() => setShowModal(true)}
                                    />
                                </div>
                            </div>
                        </ListItem>
                    </ListItems>
                </div>
            ) : (
                <div>
                    <div className="text-normal mb-1 text-sm text-secondary"><TranslateTextWithTranslation twt={value.documentation_type_name} /></div>
                    <ListItems className={'border rounded-md border-b-0'}>
                        <ListItem iconOnRight={true} icon={<Popup trigger={<NewIcon className='cursor-default' size={Sizes.XLarge} name='operation_get-info' />} on="hover">
                            {documentationTypeTooltipContent}
                        </Popup>} slim={true} graphicItemClass='min-w-10 border-r-0 border-l'>
                            <div className="flex items-center w-full px-3">
                                <div className='flex items-center'>
                                    {`${value.existing_documents.length + value.new_documents.length}` + ' '}
                                    <FormattedMessage id={"production_units.supplier_document.active_documents"} />
                                </div>
                                <div className='ml-auto'>
                                    <Button
                                        className="bg-surface-plain px-3 py-0 text-on-surface-plain text-normal text-xs"
                                        icon="operation_add"
                                        labelLeft={true}
                                        label="production_units.supplier_document.context_menu.add"
                                        onPress={() => setShowModal(true)}
                                    />
                                </div>
                            </div>
                        </ListItem>
                        {value.new_documents.map((document, i) => (
                            <DetailedSupplierDocument
                                documentationTypeTooltipContent={documentationTypeTooltipContent}
                                key={`new-${i}`}
                                detailedSupplierDocument={mapToDetailedSupplierDocument(document)}
                                isEditMode={true}
                                allowedToEdit={true}
                                isNewCheckpointDocument={true}
                                isCheckpointDocument={true}
                                onDelete={() => handleDelete(i)}
                                viewDocument={() => handleViewDocument(document)}
                            />
                        ))}
                        {value.existing_documents.map((document, i) => (
                            <DetailedSupplierDocument
                                documentationTypeTooltipContent={documentationTypeTooltipContent}
                                key={`existing-${i}`}
                                detailedSupplierDocument={mapToDetailedSupplierDocument(document)}
                                isEditMode={true}
                                allowedToEdit={true}
                                isCheckpointDocument={true}
                                viewDocument={() => handleViewDocument(document)}
                            />
                        ))}
                    </ListItems>
                </div>
            )}
            {
                documentation?.documentation_type_id == value.documentation_type_id &&
                <SupplierDocumentViewer
                    actions={actions}
                    linkedAudit={audit}
                    linkedSupplier={{
                        id: isProductionUnitCustomField ? audit.target_production_unit.id : audit.target_supplier?.id,
                        name: isProductionUnitCustomField ? audit.target_production_unit.name : audit.target_supplier?.name,
                        number: isProductionUnitCustomField ? audit.target_production_unit.number : audit.target_supplier?.number,
                        type: isProductionUnitCustomField ? 'production_unit' : 'supplier'
                    }}
                    readOnly={isViewingExistingDocument}
                    close={closeDocumentViewer}
                />
            }
            <FileUploadModal
                open={showModal}
                onClose={handleCloseModal}
                onUploadComplete={handleUploadComplete}
                fileUploadRef={fileUploadRef}
                isMobile={isMobile}
                allowMultiUpload={false}
            />
        </div>
    );
}

export function SupplierCustomFieldCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean,
    documentationProductionUnitMap: DocumentationProductionUnitMap
}) {
    const { audit, checkpoint, triedToSubmit, documentationProductionUnitMap } = props;
    const customFields = checkpoint?.supplier_custom_fields;
    const dispatch = useAppDispatch();
    const requiresInput = doesCheckpointRequireMandatoryInput(checkpoint);

    function updateCustomField(customField: AuditCustomFieldValue, newValue: (SetCustomFieldTextValue | SetCustomFieldDateValue | SetCustomFieldNumberValue | SetFieldMultipleChoiceValue)) {
        dispatch(updateSupplierCustomFieldCheckpoint({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            custom_field_id: customField.id,
            new_value: newValue
        }))
    }
    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
            <div>
                {
                    customFields?.map(customField => {
                        switch (customField.value.type) {
                            case AuditCustomFieldTextValue.type.TEXT:
                                return <TextCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldNumberValue.type.NUMBER:
                                return <NumberCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldDateValue.type.DATE:
                                return <DateCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldMultipleChoiceValue.type.MULTIPLE_CHOICE:
                                return <MultipleChoiceCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput} />
                            case AuditCustomFieldSupplierDocumentValue.type.SUPPLIER_DOCUMENT:
                                return <SupplierDocumentCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput} documentationProductionUnitMap={documentationProductionUnitMap} />
                        }
                    })
                }
            </div>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
        </div>
    )
}

function CFConfirmButton(props: {
    hasPreviousValue: boolean,
    hasNewValue: boolean,
    onClick: () => void,
    showingAlert: boolean
}) {
    const { hasPreviousValue, hasNewValue, onClick, showingAlert } = props;

    if (!hasPreviousValue) {
        return null;
    }

    if (hasNewValue) {
        return <Icon name='check_circle' className='text-brand text-xl' />
    }

    if (showingAlert) {
        return null;
    }

    return <ConfirmButton onClick={onClick} />
}

function ConfirmButton(props: {
    onClick: () => void
}) {
    const { onClick } = props;
    return <div className='flex flex-row text-link-color cursor-pointer rounded' onClick={onClick}>
        <FormattedMessage id='web_based_audit.buttons.confirm' />
    </div>
}

function getTextWithTranslation(text: TextWithTranslation, currentLocale: string): string {
    if (text.language == currentLocale) {
        return text.text;
    }

    return text.translations[currentLocale] || text.text;
}