/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DetailedGroup } from './DetailedGroup';
import type { DisplayUser } from './DisplayUser';
import type { FileResource } from './FileResource';
import type { TextWithTranslation } from './TextWithTranslation';
import type { UUIDRequired } from './UUIDRequired';

/**
 * Production Unit detailed supplier document.
 *
 */
export type DisplayDetailedSupplierDocument = {
    approval_required: boolean;
    approver: DisplayUser | null;
    archived: boolean;
    documentation_type_code: string;
    documentation_type_description: TextWithTranslation;
    documentation_type_id: string;
    documentation_type_name: TextWithTranslation;
    expiry_required: boolean;
    id: UUIDRequired;
    media_resources: Array<FileResource>;
    permissions: Array<string>;
    rejector: DisplayUser | null;
    responsible_groups: Array<DetailedGroup>;
    responsible_users: Array<DisplayUser>;
    state: DisplayDetailedSupplierDocument.state;
    uploader: DisplayUser | null;
    valid_from_date: string | null;
    valid_to_date: string | null;
};

export namespace DisplayDetailedSupplierDocument {

    export enum state {
        FILE_ADDED = 'file_added',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        ACTIVE = 'active',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

