import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'semantic-ui-react';
import Icon from '../../../base/ui/components/icons/Icon';
import Button from '../../../base/ui/components/buttons/Button';
import FileUpload from './FileUpload';
import { FileResource } from '../../../backend_api/models/FileResource';
import { Sizes } from '../../../base/ui/components/types';

interface FileUploadModalProps {
    open: boolean;
    onClose: () => void;
    onUploadComplete: (file: FileResource, allFiles?: FileResource[]) => void;
    fileUploadRef?: React.MutableRefObject<any>;
    isMobile?: boolean;
    customAcceptedFileTypes?: string[];
    allowMultiUpload?: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
    open,
    onClose,
    onUploadComplete,
    fileUploadRef,
    isMobile,
    customAcceptedFileTypes,
    allowMultiUpload = false
}) => {
    const intl = useIntl();

    const fileUploadStyleProps = {
        fileSelectorLabelText: intl.formatMessage({ id: 'audits.choose_file' }),
        fileSelectorLabelClass: 'bg-surface-brand text-on-surface-brand hover:bg-surface-brand-hover mt-4 mb-2 px-4 py-2 mx-auto rounded text-sm',
        fileSelectorCustomIconClass: 'text-on-surface-brand',
        dropClass: !isMobile && 'px-20 pt-4 pb-8 sm:block',
    };

    const fileUploadProps = {
        asFileResource: false,
        fileSelectorRef: fileUploadRef,
        hasDrop: !isMobile,
        autoClearAfterUpload: true,
        returnMultipleWhenAllDone: true,
        inline: true,
        acceptedFileTypes: customAcceptedFileTypes || ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png'],
        className: 'w-full',
        showButton: true
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
            className="!w-[480px] max-h-[300px]"
        >
            <Modal.Header>
                <div className="flex justify-between items-center">
                    <div className="text-2xl text-bold">
                        <FormattedMessage id="audits.edit.add_attachments" />
                    </div>
                    <Icon
                        size={Sizes.XLarge}
                        name="microinteraction_close"
                        onPress={onClose}
                        className="cursor-pointer"
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className="border-2 border-dashed border-gray-300 rounded-lg">
                    <FileUpload
                        useMediaResourceEndpoint={true}
                        fileSelectorCustomIcon="operation_add"
                        allowMultiUpload={allowMultiUpload}
                        {...fileUploadStyleProps}
                        {...fileUploadProps}
                        onUploadComplete={onUploadComplete}
                        dropPlacement='bottom'
                    >
                        {!isMobile && (
                            <div className='hidden sm:block text-center text-secondary mb-4 whitespace-nowrap'>
                                <FormattedMessage id="audits.or_drop_here" />
                            </div>
                        )}
                    </FileUpload>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    labelRight={true}
                    label="globals.cancel"
                    onPress={onClose}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default FileUploadModal;
