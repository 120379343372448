import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { SupplierListStatusEnum } from '../../../backend_api/models';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import NoHits from '../../../base/components/NoHits';
import SearchBox from '../../../base/components/SearchBox';
import ToTop from '../../../base/components/ToTop';
import Button from '../../../base/components/basic/Button';
import { Breakpoints } from '../../../base/config';
import { Context } from '../../../base/types';
import { getLocationEntries, preventDefaultAndStopPropagation, useIsMobileDevice, useMaxWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import AddFilters from '../../filters/components/AddFilters';
import FiltersList, { FiltersListTypes } from '../../filters/components/FiltersListNew';
import { hasActiveFiltersSelector } from '../../filters/selectors';
import { ListView } from '../../globals/components';
import PageContainer from '../../globals/components/PageContainer';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { ListViewHeader, ListViewLines } from '../../globals/components/views/list/ListView';
import { getSupplierCustomFieldsList, getSuppliersList, getSuppliersListSelector } from '../slice/suppliersSlice';
import { CreateSupplierModal } from './CreateSupplierModal';
import SupplierListItem from './SupplierListItem';
import { getPermissionsSelector } from '../../../base/selectors';
import { useCheckHasPermission } from '../../../base/hooks';

export const SUPPLIER_LIST_PR_PAGE = 50;
type Props = {
    query?: string;
}

const SuppliersList = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const locationParsed = qs.parse(location.search as string);
    const history = useHistory();
    const isMobileDevice = useIsMobileDevice();
    const suppliersData = useSelector(getSuppliersListSelector);
    const hasFilters = useSelector(hasActiveFiltersSelector);
    const currentFilters = locationParsed.scf_ids && (locationParsed.scf_ids && JSON.parse(locationParsed.scf_ids as string)) as any || null;
    const _suppliers = suppliersData.data && suppliersData.data.suppliers && suppliersData.data.suppliers || [];
    const hasSuppliers = _suppliers.length > 0;
    const [isFetching, setFetching] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const error = suppliersData.error;
    const [suppliers, setSuppliers] = useState(_suppliers);
    const [query, setQuery] = useState(null);
    const [total, setTotal] = useState<number>(null);
    const [count, setCount] = useState<number>(null);
    const [limit, setLimit] = useState<number>(SUPPLIER_LIST_PR_PAGE);
    const [offset, setOffset] = useState<number>(0);
    const params = getLocationEntries(location);

    const permissions = useSelector(getPermissionsSelector);
    const hasDocumentationTypesPermission = useCheckHasPermission(permissions, 'u_supplier_documentation_types');
    const filters: FiltersListTypes[] = [
        'supplier-custom-fields-filter',
    ];

    // Not included in MVP
    // if (hasDocumentationTypesPermission) {
    //     filters.push('documentation_type');
    //     filters.push('documentation_state');
    // }

    const [openSuppliers, setOpenSuppliers] = useState(params.id || []);
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);

    const clear = () => {
        history.push('/suppliers');
    };
    const search = (query?: string) => {
        setQuery(query);
        setCount(50);
        const cfs = currentFilters && Object.values(currentFilters);
        dispatch(getSuppliersList({ search: query, pagination: { limit, offset: 0 }, custom_fields: cfs as any }));
    }
    useEffect(() => {
        const l = qs.parse(location.search);
        const cfs = currentFilters && Object.values(currentFilters);
        let query;
        if (l['query']) {
            setQuery(l['query'] as string);
            query = l['query'];
        } else {
            setQuery(null);
        }

        setLimit(0);
        setFetching(true);
        dispatch(getSuppliersList({ pagination: { limit: limit || SUPPLIER_LIST_PR_PAGE, offset: 0 }, search: query, custom_fields: cfs as any }));
    }, [history.location.search]);

    useEffect(() => {
        setFetching(false);
        if (suppliersData.data) {
            setSuppliers(suppliersData.data.suppliers || []);
            setOffset(suppliersData.data.pagination.offset);
            setLimit(suppliersData.data.pagination.limit);
            setTotal(suppliersData.data.pagination.total);
            setCount(parseInt(suppliersData.data.pagination.limit as unknown as string, 10) + parseInt(suppliersData.data.pagination.offset as unknown as string, 10));
        }
    }, [suppliersData.data]);

    useEffect(() => {
        setFetching(suppliersData.status === SupplierListStatusEnum.Fetching);
    }, [suppliersData.status]);


    useEffect(() => {
        dispatch(getSupplierCustomFieldsList());
    }, []);

    const lines = useMemo(() => {
        return hasSuppliers && <ListViewLines className='border border-b-0'>
            {suppliers && suppliers.map((supplier, i) => <SupplierListItem
                supplier={supplier} key={'supplier_' + supplier.id + i}
                cnt={i}
            />)}
        </ListViewLines>;
    }, [suppliers, openSuppliers, params]);

    const createSupplierButton = (
        <HasFeatureAccess feature='u_add_suppliers'>
            <CreateSupplierModal
                onExistingSupplierIdSelected={(id: string) => history.push(`/suppliers/${id}`)}
                renderButton={() => <Button secondary><FormattedMessage id='suppliers.add_supplier' /></Button>} />
        </HasFeatureAccess>
    );



    const noHits = () => {
        let advice: string | React.ReactElement = <span className='space-x-1'><span><FormattedMessage id='suppliers.filters.no_hits.change_filters' /></span>
            <span className='link' onClick={(e) => {
                clear();
                preventDefaultAndStopPropagation(e);
            }}><FormattedMessage id='suppliers.filters.no_hits.clear' /></span></span>;
        if (query) {
            advice = hasFilters ? <span><FormattedMessage id='suppliers.filters.no_hits.change_your_query_or_clear_filters_1' /> <span className='link' onClick={(e) => {
                clear();
                preventDefaultAndStopPropagation(e);

            }}><FormattedMessage id='suppliers.filters.no_hits.clear' /></span></span> : <span><FormattedMessage id='suppliers.filters.no_hits.change_query' /></span>
        }
        if (!hasSuppliers) {
            return <NoHits
                heading={<FormattedMessage id='suppliers.list.search.no_suppliers' />}
                advice={<span>{advice}</span>}
            // advice={<span>Try changing your query or <span className='link' onClick={() => clear()}>clear your filters</span></span>}
            />
        }

    }
    return <PageContainer header={<FormattedMessage id='suppliers.list.title' />}>
        <DocumentTitle title={intl.formatMessage({ id: 'suppliers.list.title' })} />
        <div className='flex flex-col md:flex-row md:space-x-4 md:items-center mb-4'>
            <ListView className=''>
                <div className=' my-4 flex justify-between'>
                    <div className='flex w-full sm:w-2/3 space-x-2'>
                        <SearchBox
                            path='suppliers'
                            className='sm:w-2/3 w-full'
                            onSearch={(q) => {
                                search(q);
                            }}
                            isSearching={isFetching}
                            placeholderText={intl.formatMessage({ id: 'suppliers.list.search.label' })}
                            q={query}
                        />
                        {!isMaxMD && <AddFilters />}
                    </div>
                    {!isMaxMD && createSupplierButton}
                </div>
                {isMaxMD && <div className='p-2 justify-center w-full flex'><AddFilters />{createSupplierButton}</div>}
                <FiltersList
                    productionUnitOrSupplier='supplier'
                    context={Context.Suppliers}
                    filters={filters}
                    isFetching={isFetching}
                />
                <Loader active={!error && isFetching}>
                    <div>
                        <div className='flex flex-col'>
                            {_suppliers.length > 0 && <><ListViewHeader
                                className={twMerge('top-0')}
                                items={[
                                    { label: intl.formatMessage({ id: 'suppliers.list.header.number' }), className: 'w-3/16', key: 'product_number' },
                                    { label: intl.formatMessage({ id: 'suppliers.list.header.name' }), className: 'w-8/16', key: 'product_name' },
                                    { label: intl.formatMessage({ id: 'suppliers.list.header.cas' }), className: 'w-5/16', key: '' },
                                ]} />
                            </>}
                            {lines}
                        </div>
                    </div>
                </Loader>
                {total > 0 && <LoadMore
                    onLoadMore={(limit: number, offset: number, count: number, search?: string) => {
                        const cfs = currentFilters && Object.values(currentFilters);
                        dispatch(getSuppliersList({ pagination: { limit, offset }, search, custom_fields: cfs as any }));
                    }}
                    chunk={limit}
                    total={total}
                    search={query}
                    count={count}
                    isLoading={isFetching}
                    className='border border-t-0'
                />}
            </ListView>

            <ToTop left={isMobileDevice ? '35%' : '90%'} />
        </div>
        {noHits()}
    </PageContainer>
}
export default SuppliersList;
