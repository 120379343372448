import React, { useEffect, useState } from 'react';
import { DisplayCustomFieldGroup, InputCustomFieldValue } from '../../../backend_api/models';
import { objectHasKeys, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setSupplierCustomFieldValue } from '../../suppliers/slice/suppliersSlice';
import { setProductionUnitCustomFieldValue } from '../slice/productionUnitsSlice';
import DetailedProductionUnitCustomFieldGroup from './DetailedProductionUnitCustomFieldGroup';
import { FormattedMessage } from 'react-intl';

type Props = {
    className?: string;
    productionUnitId: string;
    type: 'productionUnit' | 'supplier';
    customFieldGroups: DisplayCustomFieldGroup[];
    allowedToEdit: boolean;
};
const  DetailedProductionUnitCustomFields = (props: Props): React.ReactElement => {
    const { className, productionUnitId, type } = props;
    const dispatch = useAppDispatch();
    const [customFieldGroups, setCustomFieldGroups] = useState<DisplayCustomFieldGroup[]>(props.customFieldGroups);
    const [okToSaveId, setOkToSaveId] = useState('');
    const handleSave = (customFieldDefinitionId, value: InputCustomFieldValue, byPass?: boolean) => {
        if (okToSaveId === customFieldDefinitionId || byPass) {
            dispatch(
                type === 'productionUnit'
                    ? setProductionUnitCustomFieldValue({ productionUnitId, customFieldDefinitionId, cf: value })
                    : setSupplierCustomFieldValue({ supplierId: productionUnitId, customFieldDefinitionId, cf: value })
            );
            setOkToSaveId('');
        }
    }
    useEffect(() => {
        setCustomFieldGroups(props.customFieldGroups);
    }, [props.customFieldGroups])

    return customFieldGroups?.length ? (
        <div className={twMerge('', className)}>
            <h2 className="text-primary font-bold text-2xl !mt-6 !mb-2"><FormattedMessage id="production_units.data_and_documentation" /></h2>
            {(objectHasKeys(customFieldGroups)) && customFieldGroups.map((group) => {
                return <DetailedProductionUnitCustomFieldGroup
                    productionUnitId={productionUnitId}
                    type={type}
                    allowedToEdit={props.allowedToEdit}
                    handleSave={handleSave}
                    setOkToSaveId={setOkToSaveId}
                    customFieldGroup={group}
                    key={'cf_' + group.id} />
            })}
        </div>
    ) : null
}
export default DetailedProductionUnitCustomFields;
