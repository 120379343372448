import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import DatePicker3 from '../../../components/basic/DatePicker3';
import { Sizes } from '../../../components/types';
import { DEFAULT_DATE_FORMAT2 } from '../../../config';
import Button, { ButtonTypes } from '../buttons/Button';
import Label from '../labels/Label';
import { ListItem, ListItems, MainActionEnum } from '../listitems/ListItem';
import { getPrettyDate } from '../../../utils';
import InvalidFieldMessage from '../labels/InvalidFieldMessage';
import { useSelector } from 'react-redux';
import { SupplierDocumentExtendedStatusEnum } from '../../../../backend_api/models';
import QModal, { ActionTypes } from '../modals/Modal';
import { getViewingSupplierDocumentSelector } from '../../../../modules/supplierDocuments/supplierDocumentsSlice';

type Props = {
    className?: string;
    validationError: string;
    readOnly?: boolean;
    setValidationError: (error: string) => void;
    updateValidity?: (startDate: string, expiredDate: string) => void;
    resetApproval?: () => void;
};

const DocumentValidity = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const documentation = useSelector(getViewingSupplierDocumentSelector);
    const { readOnly, validationError, setValidationError, updateValidity, resetApproval } = props;

    const [validAfter, setValidAfter] = useState(null);
    const [validBefore, setValidBefore] = useState(null);
    const state = documentation?.extended_status;
    const canEditDocument = documentation?.permissions?.includes('edit_supplier_documents');
    const isAllowedToSetExpiry = state != SupplierDocumentExtendedStatusEnum.Approved;
    const [showPickers, setShowPickers] = useState(documentation?.expiry_required && !documentation?.expiry_date && canEditDocument);

    const [showForceEditValidityModal, setShowForceEditValidityModal] = useState(false);

    React.useEffect(() => {
        if(!!documentation) {
            setValidAfter(documentation?.start_date);
            setValidBefore(documentation?.expiry_date);
        }
    }, [documentation]);

    const onUpdateValidityInterval = (startDate: string, endDate: string) => {
        updateValidity(startDate, endDate);
    };

    const doEditValidity = () => {
        resetApproval();
        setShowForceEditValidityModal(false);
        setShowPickers(true);
    };

    const getModalHeader = () => {
        if(state === SupplierDocumentExtendedStatusEnum.Active) {
            return intl.formatMessage({ id: 'document_viewer.approval.edit_validity.header_active' });
        } else if(state === SupplierDocumentExtendedStatusEnum.Approved) {
            return intl.formatMessage({ id: 'document_viewer.approval.edit_validity.header_approved' });
        } else {
            return intl.formatMessage({ id: 'document_viewer.approval.edit_validity.header_rejected' });
        }
    }

    return <div>
        <h4 className='mb-2'><FormattedMessage id='document_viewer.validity' /></h4>
        <ListItems aria-label="Document validity information" className={'border border-default border-b-0 rounded-md bg-default'}>
            { validationError && <ListItem>
                <InvalidFieldMessage message={validationError} />
            </ListItem>}
            {
                showPickers && !readOnly && canEditDocument ?
                <ListItem
                    icon={documentation?.expiry_required ? 'operation_pick-date' : 'expiry_never'}
                    className='flex rounded-md'
                    mainAction={!showPickers && {
                        type: MainActionEnum.Edit, onMainActionClick: () => {
                            if(isAllowedToSetExpiry) {
                                setShowPickers(true);
                            } else {
                                setShowForceEditValidityModal(true);
                            }
                        }
                    }}
                    inlineButton={showPickers && {
                        className: 'flex place-self-end justify-center pr-2 py-4',
                        button: <Button
                            className=''
                            size={Sizes.Small}
                            primary
                            stopPropagation={true}
                            onPress={() => {
                                if(!validAfter || (documentation?.expiry_required && !validBefore)) {
                                    setValidationError('document_viewer.validity.validity_required');
                                } else {
                                    setShowPickers(false);
                                    setValidationError('');
                                }
                            }}><FormattedMessage id='globals.ok' /></Button>
                    }}
                >
                    {showPickers ? <div className='flex flex-row gap-4 items-end grow justify-stretch flex-1'>
                        <div className='flex-1'>
                            <Label text={intl.formatMessage({ id: 'production_units.list.documentation.date.valid_from' })} />
                            <DatePicker3
                                showClose={false}
                                maxDate={new Date()}
                                date={validAfter} 
                                handleChange={(d) => {
                                    setValidAfter(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    onUpdateValidityInterval(d ? format(d, DEFAULT_DATE_FORMAT2) : null, validBefore);
                                }} />
                        </div>
                        <div className='flex-1'>
                            <Label text={intl.formatMessage({ id: 'production_units.list.documentation.date.valid_until' })} />
                            {
                                documentation?.expiry_required ?
                                <DatePicker3
                                    showClose={false}
                                    minDate={validAfter && new Date(validAfter)}
                                    date={validBefore} 
                                    handleChange={(d) => {
                                        setValidBefore(d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                        onUpdateValidityInterval(validAfter, d ? format(d, DEFAULT_DATE_FORMAT2) : null);
                                    }} 
                                />
                                :
                                <div className='text-primary bg-secondary border-none p-3 rounded-md'>
                                    {intl.formatMessage({ id: 'document_viewer.expiry.never' })}
                                </div>
                            }
                        </div>
                    </div> :
                        <div className='gap-2 flex'><FormattedMessage id='compliance.document.validity.document_valid' /> <span className='font-bold'>{getPrettyDate(validAfter)}</span>-<span className='font-bold'>{getPrettyDate(validBefore)}</span></div>
                    }
                </ListItem> :
                <ListItem
                    icon={documentation?.expiry_required ? 'operation_pick-date' : 'expiry_never'}
                    className='flex rounded-md'
                    mainAction={!readOnly && canEditDocument && {
                        type: MainActionEnum.Edit, onMainActionClick: () => {
                            if(isAllowedToSetExpiry) {
                                setShowPickers(true);
                            } else {
                                setShowForceEditValidityModal(true);
                            }
                        }
                    }}
                >
                    {
                        documentation?.expiry_required ?
                        <div className='gap-2 flex'>
                            <FormattedMessage id='document_viewer.validity.document_valid' /> <span className='font-bold'>{getPrettyDate(validAfter)}</span>-<span className='font-bold'>{getPrettyDate(validBefore)}</span>
                        </div>
                        :
                        <div className='gap-2 flex'>
                            <span className='font-bold'><FormattedMessage id='document_viewer.validity.never_expires' /></span> {validAfter && <><FormattedMessage id='document_viewer.validity.valid_from' /> {getPrettyDate(validAfter)}</>}
                        </div>
                    }
                </ListItem>
            }
        </ListItems>
        <QModal
            width={500}
            isOpen={showForceEditValidityModal}
            header={getModalHeader()}
            content={
                <FormattedMessage 
                    id={state === SupplierDocumentExtendedStatusEnum.Active ? "document_viewer.approval.edit_validity_active" : "document_viewer.approval.edit_validity"}
                    values={{
                        b: (chunks) => <b>{chunks}</b>
                    }}
                />
            }
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'document_viewer.approval.edit_validity.yes' }), buttonType: ButtonTypes.Primary, event: doEditValidity },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'globals.cancel' }), buttonType: ButtonTypes.Plain, event: () => setShowForceEditValidityModal(false) },
            ]}
        />
    </div>
};
export default DocumentValidity;