import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    DisplayCustomField,
    DisplayCustomFieldGroup as DisplayCustomFieldGroupType,
    DisplayCustomFieldMultipleChoiceValue,
    DisplayCustomFieldNumberValue,
    InputCustomFieldDateValueTypeEnum,
    InputCustomFieldMultipleChoiceValueTypeEnum,
    InputCustomFieldNumberValueTypeEnum,
    InputCustomFieldTextValueTypeEnum,
    InputCustomFieldValue
} from '../../../backend_api/models';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import Input from '../../../base/components/basic/Input';
import { translateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { deepCopy, getLocaleLanguageString, getPrettyDate, isUndefinedOrNull, twMerge } from '../../../base/utils';
import ContentSection from '../../pageLayouts/components/ContentSection';
import DisplayCustomFieldsMultipleChoice from './DetailedProductionUnitCustomFieldsMultipleChoice';
import DocumentationTypeWithSupplierDocuments from './DocumentationTypeWithSupplierDocuments';
import EditContextMenuButton from '../../../base/components/basic/EditContextMenuButton';
import Button from '../../../base/components/basic/Button';

type Props = {
    className?: string;
    allowedToEdit: boolean;
    customFieldGroup: DisplayCustomFieldGroupType;
    // handleChange(customFieldDefinitionId: string, value1: any);
    handleSave(customFieldDefinitionId: string, value: InputCustomFieldValue, byPass?: boolean);
    setOkToSaveId(id: string);
    productionUnitId: string;
    type: 'productionUnit' | 'supplier';
};


const DisplayCustomFieldGroup = (props: Props): React.ReactElement => {
    const { className, handleSave, setOkToSaveId, productionUnitId, type } = props;
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>();
    const [customFieldGroup, setCustomFieldGroup] = useState<DisplayCustomFieldGroupType>(props.customFieldGroup);

    useEffect(() => {
        setCustomFieldGroup(props.customFieldGroup);
    }, [props.customFieldGroup]);

    const handleChange = (customFieldDefinitionId: string, value: any) => {
        const customFieldsGroup: DisplayCustomFieldGroupType = deepCopy(customFieldGroup);
        customFieldsGroup.custom_fields.forEach((cf) => {
            if (cf.value.type === 'MultipleChoice') {
                if (customFieldDefinitionId === cf.id) {
                    const cfTemp: DisplayCustomFieldMultipleChoiceValue = { ...cf.value };
                    cfTemp.options.map((opt) => {
                        opt.selected = false;
                        value.option_ids && value.option_ids.map((optId) => {
                            if (opt.option_id === optId) {
                                opt.selected = true;
                            }
                        });
                    });
                    setOkToSaveId(cf.id);
                }
            } else {
                if (customFieldDefinitionId === cf.id) {
                    cf.value = value;
                    setOkToSaveId(cf.id);
                }
            }
        });
        setCustomFieldGroup(customFieldsGroup);
    };
    const getInput = (customField: DisplayCustomField, edit: boolean) => {
        if (customField.value.type === 'MultipleChoice') {
            return <><DisplayCustomFieldsMultipleChoice
                edit={edit}
                customField={customField}
                handleMCChange={(selectedIds: string[]) => {
                    setOkToSaveId(customField.id);
                    handleSave(customField.id, { value: { option_ids: selectedIds, type: InputCustomFieldMultipleChoiceValueTypeEnum.MultipleChoice } }, true)
                    handleChange(customField.id, { option_ids: selectedIds, type: InputCustomFieldMultipleChoiceValueTypeEnum.MultipleChoice });
                }}
            /></>
        }
        if (customField.value.type === 'Text') {
            return edit ? <Input
                value={customField.value.text}
                onChange={(e) => handleChange(customField.id, { text: e.target.value, type: InputCustomFieldTextValueTypeEnum.Text })}
                onBlur={(e) => handleSave(customField.id, { value: { text: e.target.value, type: InputCustomFieldTextValueTypeEnum.Text } })}
                type='text' /> : <div className='break-words'>{customField.value.text || '-'}</div>;
        }
        if (customField.value.type === 'Number') {
            return edit
                ? <SpecialNumberInput customField={customField}
                    handleChange={handleChange}
                    handleSave={handleSave}
                />
                : <div className='break-words'>{customField.value.number || '-'}</div>;
        }
        if (customField.value.type === 'Date') {
            return edit ? <DatePicker3
                date={customField.value.date}
                handleChange={(d) => {
                    handleChange(customField.id, { date: !isUndefinedOrNull(d) ? format(d, DEFAULT_DATE_FORMAT2) : null, type: InputCustomFieldDateValueTypeEnum.Date });
                    handleSave(customField.id, { value: { date: !isUndefinedOrNull(d) ? format(d, DEFAULT_DATE_FORMAT2) : null, type: InputCustomFieldDateValueTypeEnum.Date } }, true);
                }} /> : <span>{customField.value.date && getPrettyDate(customField.value.date.toString())}</span>
        }

    }
    const customFieldsContent = <div className='pb-2 flex w-full flex-col' key={'cf_' + customFieldGroup.id}>
        {customFieldGroup.custom_fields.length > 0 && 
            <div className='flex w-full flex-wrap px-4 pb-4'>
                {customFieldGroup.custom_fields.map((customField, i) => {
                    return <>
                        {edit && <div className={twMerge('py-2 flex w-full', i == customFieldGroup.custom_fields.length - 1 ? '' : 'pb-4')} key={'def_' + customField.id}>
                            <div className='w-1/4 pr-4'>{translateTextWithTranslation(customField.name, getLocaleLanguageString(intl.locale))}</div>
                            <div className='w-2/4'>{getInput(customField, edit)}
                            </div>
                        </div>}
                        {!edit &&
                            <div className={twMerge('w-1/2 flex ', i % 2 === 0 ? 'border-r' : 'px-4', i == customFieldGroup.custom_fields.length - 1 ? '' : 'pb-4')}>
                                <div className='w-1/2 font-semibold'>{translateTextWithTranslation(customField.name, getLocaleLanguageString(intl.locale))}</div>
                                <div className='w-1/2 px-2'>{getInput(customField, edit)}</div>
                            </div>
                        }
                    </>
                })}
            </div>
        }
        {
            customFieldGroup.documentation_types.length > 0 && 
            <div className={`border-t border-default ${edit ? 'px-4' : '-mb-10'}`}>
                <div className={`flex flex-col ${edit ? 'items-start w-full sm:max-w-[32rem] gap-4 mt-4' : 'items-center w-full'}`}>
                    {customFieldGroup.documentation_types.map((documentationType, i) => {
                        return <>
                            <DocumentationTypeWithSupplierDocuments 
                                showEmptyFields={true}  
                                isEditMode={edit} 
                                documentationType={documentationType} 
                                productionUnitId={productionUnitId} 
                                allowedToEdit={props.allowedToEdit} 
                                type={type}
                            />
                        </>
                    })}
                </div>
            </div>
        }
        
        <div className='px-4'>
            {edit && <Button
                className="w-fit"
                data-test-id='btn-done-editing'
                primary={true}
                onClick={(): void => setEdit(false)}>
                <FormattedMessage id='production_units.done_editing' />
            </Button>}
        </div>
    </div>

    return <ContentSection
        contentClassName={'pt-1'}
        noHeaderBorder={true}
        content={customFieldsContent}
        noPadding={true}
        headerText={<div className='justify-between flex w-full'>{translateTextWithTranslation(customFieldGroup.name, getLocaleLanguageString(intl.locale))}
            {props.allowedToEdit && <EditContextMenuButton edit={edit} onEdit={setEdit} />}
        </div>}
        key={'cs_' + customFieldsContent.key}
    />
}


/* This component is an input field, that only lets you enter numbers that match a special regexp. */
function SpecialNumberInput(props: {
    customField: DisplayCustomField,
    handleChange(id: string, any),
    handleSave(id: string, any),
}) {

    const asNumber = props.customField.value as DisplayCustomFieldNumberValue

    const [numberValue, setNumberValue] = React.useState(asNumber.number);

    useEffect(() => {
        if (props.customField.value.type === 'Number') {
            setNumberValue(props.customField.value.number);
        }
    }, [props.customField, props.customField.value])

    /* This component can not be used for anything but Number custom fields! */
    if (props.customField.value.type !== 'Number') {
        return null;
    }

    function pushChangeToParent(tValue: string | null) {
        const t = {
            number: tValue,
            type: InputCustomFieldNumberValueTypeEnum.Number
        }

        props.handleChange(props.customField.id, t);
    }


    function pushSaveToParent(tValue: string) {
        const t = {
            value: {
                number: tValue,
                type: InputCustomFieldNumberValueTypeEnum.Number
            }
        }

        props.handleSave(
            props.customField.id,
            t
        )
    }

    function onInputChange(e) {
        if (e.target.value === '' || e.target.value == null) {
            setNumberValue('')
            pushChangeToParent('')
        } else {
            const v = e.target.value;

            setNumberValue(v)

            if (validateNumberString(v)) {
                pushChangeToParent(v)
            }
        }
    }

    function onInputBlur() {
        if (numberValue == '' || numberValue == null) {
            setNumberValue('')
            pushSaveToParent('')
        } else if (validateNumberString(numberValue)) {
            pushSaveToParent(numberValue)
        }
    }

    return (
        <>
            <Input
                value={numberValue}
                onChange={onInputChange}
                onBlur={onInputBlur}
                type='text'
            />
            {
                validateNumberString(numberValue) ? null : (<InvalidNumberWarning />)
            }
        </>
    )
}

function InvalidNumberWarning() {
    return (
        <div className='pt-1'>
            <div className='inline mt-2 bg-attention text-sm py-0 pl-1'>
                <FormattedMessage id='custom_fields.invalid_number' />
            </div>
        </div>
    );
}

function validateNumberString(n: string) {
    if (n === null || n === undefined) {
        return true
    }

    if (n.match(/^-?((0|[1-9]\d*)(\.\d+)?)$/)) {
        return true;
    }

    return false;

}

export default DisplayCustomFieldGroup;
