import React from 'react';
import { DisplayDetailedSupplierDocument } from '../../../backend_api/models/DisplayDetailedSupplierDocument';
import { useIsMobileDevice } from '../../../base/utils';
import { ViewAllDocumentsModal } from './ViewAllDocumentsModal';
import { getDetailedProductionUnitListSelector } from '../slice/productionUnitsSlice';
import { useAppDispatch } from '../../../store';
import { FileResource } from '../../../backend_api/models';
import FileUploadModal from '../../files/components/FileUploadModal';
import { useSelector } from 'react-redux';
import DeleteDocumentConfirmModal from './DeleteDocumentConfirmModal';
import { archiveSupplierDocument, deleteSupplierDocument, createSupplierDocumentOnProductionUnit, createSupplierDocumentOnSupplier } from '../../supplierDocuments/supplierDocumentsSlice';
import { getDetailedSupplierSelector } from '../../suppliers/slice/suppliersSlice';
import SupplierDocumentItemViewMode from './SupplierDocumentItemViewMode';
import SupplierDocumentItemEditMode from './SupplierDocumentItemEditMode';

type Props = {
    allowedToEdit: boolean;
    isEditMode: boolean;
    detailedSupplierDocument: DisplayDetailedSupplierDocument;
    documentationTypeTooltipContent?: React.ReactElement;
    isCheckpointDocument?: boolean;
    isNewCheckpointDocument?: boolean;
    onDelete?: () => void;
    viewDocument?: () => void;
};


const DetailedSupplierDocument = (props: Props): React.ReactElement => {
    const { isCheckpointDocument = false, isNewCheckpointDocument = false, detailedSupplierDocument, allowedToEdit, isEditMode = false, documentationTypeTooltipContent, viewDocument } = props;
    const dispatch = useAppDispatch();
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const supplier = useSelector(getDetailedSupplierSelector);
    const isMobile = useIsMobileDevice();

    const [showUploadModal, setShowUploadModal] = React.useState(false);
    const [showDeleteDocumentConfirmModal, setShowDeleteDocumentConfirmModal] = React.useState(false);
    const [showAllDocuments, setShowAllDocuments] = React.useState(false);

    const uploadFilesToDocument = (file: FileResource) => {
        if (productionUnit?.id) {
            dispatch(createSupplierDocumentOnProductionUnit({
                documentation_type_id: detailedSupplierDocument?.documentation_type_id,
                media_resource_id: file?.id,
                production_unit_id: productionUnit?.id
            }));
        } else if (supplier?.id) {
            dispatch(createSupplierDocumentOnSupplier({
                documentation_type_id: detailedSupplierDocument?.documentation_type_id,
                media_resource_id: file?.id,
                supplier_id: supplier?.id
            }));
        }
        setShowUploadModal(false);
    }

    const archiveDocument = () => {
        dispatch(archiveSupplierDocument({ id: detailedSupplierDocument?.id }));
    };

    const deleteDocument = () => {
        dispatch(deleteSupplierDocument({ id: detailedSupplierDocument?.id }));
        setShowDeleteDocumentConfirmModal(false);
    };

    const uploadFileModal = <FileUploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUploadComplete={(file) => {
            setShowUploadModal(false);
            uploadFilesToDocument(file);
        }}
        isMobile={isMobile}
        allowMultiUpload={false}
    />

    const deleteDocumentConfirmModal = <DeleteDocumentConfirmModal
        open={showDeleteDocumentConfirmModal}
        close={() => setShowDeleteDocumentConfirmModal(false)}
        archiveDocument={detailedSupplierDocument?.archived || isCheckpointDocument ? null : archiveDocument}
        deleteDocument={isCheckpointDocument ? props.onDelete : deleteDocument}
    />


    return (
        <>
            {isEditMode ? 
                <SupplierDocumentItemEditMode
                    allowedToEdit={allowedToEdit}
                    detailedSupplierDocument={detailedSupplierDocument}
                    documentationTypeTooltipContent={documentationTypeTooltipContent}
                    isCheckpointDocument={isCheckpointDocument}
                    isNewCheckpointDocument={isNewCheckpointDocument}
                    viewDocument={viewDocument}
                    archiveDocument={archiveDocument}
                    deleteDocument={() => setShowDeleteDocumentConfirmModal(true)}
                />
                : 
                <SupplierDocumentItemViewMode
                    className={"border-b"}
                    detailedSupplierDocument={detailedSupplierDocument}
                    documentationTypeTooltipContent={documentationTypeTooltipContent}
                    allowedToEdit={allowedToEdit}
                    viewDocument={viewDocument}
                    addNewDocument={() => setShowUploadModal(true)}
                    archiveDocument={archiveDocument}
                    deleteDocument={() => setShowDeleteDocumentConfirmModal(true)}
                    showAllDocuments={() => setShowAllDocuments(true)}
                />
            }
            {uploadFileModal}
            {deleteDocumentConfirmModal}
            {showAllDocuments && <ViewAllDocumentsModal
                filters={[detailedSupplierDocument.documentation_type_id]}
                productionUnitId={productionUnit?.id}
                supplierId={supplier?.id}
                setShowing={setShowAllDocuments}
                showing={showAllDocuments}
                addDocumentation={() => {
                    // TODO Chris Handle add documentation for supplier
                }} />}
        </>
    );
}
export default DetailedSupplierDocument;