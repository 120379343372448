import {
    DisplayDetailedSupplierDocument,
    DisplayDetailedSupplierDocumentStateEnum,
} from "../../../backend_api/models/DisplayDetailedSupplierDocument";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ContextMenu, {
    ContextMenuActionItemType,
    ContextMenuOverflowItemType,
} from "../../../base/ui/components/contextMenu/ContextMenu";
import Button from "../../../base/ui/components/buttons/Button";
import Icon from "../../../base/ui/components/icons/Icon";
import {
    ArrowTypeEnum,
    ListItem,
} from "../../../base/ui/components/listitems/ListItem";
import { Sizes } from "../../../base/ui/components/types";
import {
    getSupplierDocumentStatusColor,
    renderDocumentationStateDescription,
} from "./SupplierDocumentItemViewMode";

type Props = {
    allowedToEdit: boolean;
    detailedSupplierDocument: DisplayDetailedSupplierDocument;
    documentationTypeTooltipContent?: React.ReactElement;
    isCheckpointDocument?: boolean;
    isNewCheckpointDocument?: boolean;
    viewDocument?: () => void;
    archiveDocument?: () => void;
    deleteDocument?: () => void;
};

const SupplierDocumentItemEditMode = (props: Props): React.ReactElement => {
    const {
        isCheckpointDocument = false,
        allowedToEdit,
        isNewCheckpointDocument = false,
        detailedSupplierDocument,
        viewDocument,
        archiveDocument,
        deleteDocument,
    } = props;

    const archiveDocumentContextMenuItem: ContextMenuOverflowItemType =
        allowedToEdit &&
            detailedSupplierDocument.state !=
                DisplayDetailedSupplierDocumentStateEnum.Archived && {
                label: "production_units.supplier_document.context_menu.archive_document",
                icon: "operation_archive",
                onPress: archiveDocument,
            };
    const deleteContextMenuItem: ContextMenuOverflowItemType =
        allowedToEdit && {
            label: "production_units.supplier_document.context_menu.delete",
            icon: "operation_delete",
            className: "text-danger",
            onPress: deleteDocument,
        };

    const DeleteContextMenuItemAction: ContextMenuActionItemType = {
        icon: "operation_delete",
        label: "production_units.supplier_document.context_menu.delete",
        className: "bg-default px-0",
        buttonSize: Sizes.XLarge,
        onPress: deleteDocument,
    };

    const contextMenuItems = [
        archiveDocumentContextMenuItem,
        deleteContextMenuItem,
    ].filter((item) => !!item);

    if (isCheckpointDocument) {
        return (
            <ListItem
                className="border-b"
                onPress={viewDocument}
                arrow={{ type: ArrowTypeEnum.Open }}
                slim={true}
                graphicItemClass="min-w-10"
                icon={
                    isNewCheckpointDocument && (
                        <ContextMenu
                            trigger={
                                <Button
                                    stopPropagation
                                    ghost
                                    className="px-2 py-1 bg-default"
                                >
                                    <Icon
                                        size={Sizes.XLarge}
                                        name="operation_delete"
                                    />
                                </Button>
                            }
                            actions={[DeleteContextMenuItemAction]}
                            className="h-fit-content"
                        />
                    )
                }
                iconOnRight={true}
            >
                <div className="flex items-center relative w-full bg-default pl-2">
                    <div className="flex gap-4">
                        <div
                            className={`${getSupplierDocumentStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-normal text-sm text-on-status px-3 py-1 flex items-center justify-center rounded-md`}
                        >
                            <FormattedMessage
                                id={
                                    "production_units.supplier_document.status." +
                                    (detailedSupplierDocument?.archived
                                        ? "archived"
                                        : detailedSupplierDocument.state)
                                }
                            />
                        </div>

                        <div className="flex items-center">
                            <span className="text-normal text-base text-primary">
                                {renderDocumentationStateDescription({
                                    detailedSupplierDocument,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            </ListItem>
        );
    }

    return (
        <ListItem
            className="border-b"
            onPress={viewDocument}
            arrow={{ type: ArrowTypeEnum.Open }}
            slim={true}
            graphicItemClass="min-w-10"
            icon={
                <ContextMenu
                    trigger={
                        <Button
                            stopPropagation
                            ghost
                            className="px-2 py-1 bg-default"
                        >
                            <Icon name="microinteraction_context-menu" />
                        </Button>
                    }
                    overflowItems={contextMenuItems}
                    className="h-fit-content"
                ></ContextMenu>
            }
            iconOnRight={true}
        >
            <div className="flex items-center relative w-full bg-default pl-2">
                <div className="flex gap-4">
                    <div className={`${getSupplierDocumentStatusColor(detailedSupplierDocument.state, detailedSupplierDocument.archived)} text-normal text-sm text-on-status px-3 py-1 flex items-center justify-center rounded-md`}>
                        <FormattedMessage
                            id={
                                "production_units.supplier_document.status." +
                                (detailedSupplierDocument?.archived
                                    ? "archived"
                                    : detailedSupplierDocument.state)
                            }
                        />
                    </div>

                    <div className="flex items-center">
                        <span className="text-normal text-base text-primary">
                            {renderDocumentationStateDescription({
                                detailedSupplierDocument,
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </ListItem>
    );
};
export default SupplierDocumentItemEditMode;
