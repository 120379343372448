import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../store';
import { updateDetailedSupplier } from '../slice/suppliersSlice';
import ContentSection from '../../pageLayouts/components/ContentSection';
import QInput from '../../inspections/components/edit/formelements/QInput';
import EditContextMenuButton from '../../../base/components/basic/EditContextMenuButton';
import { TextItem } from '../../globals/components';

type Props = {
    supplierId: string;
    className?: string;
    disabled?: boolean;
    name: string;
    number: string;
    allowedToEdit: boolean
};

const DetailedSupplierInfo = (props: Props): React.ReactElement => {
    const { className, disabled, supplierId, allowedToEdit } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [edit, setEdit] = useState(null);
    const [name, setName] = useState(props.name);
    const [number, setNumber] = useState(props.number);

    const save = (name: string, number: string) => {
        dispatch(updateDetailedSupplier({ supplierId, obj: { name, number } }))
    }

    useEffect(() => {
        setName(props.name)
    }, [props.name]);

    useEffect(() => {
        setNumber(props.number)
    }, [props.number]);

    return <ContentSection
        content={<div className="flex">
            <div className="flex-1">
                {!edit && <div className='space-y-3'>
                    <TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.name' })} text={props.name || '-'} />
                    <TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.number' })} text={props.number || '-'} />
                </div>}
                {edit && <div className='flex flex-col space-y-2'>
                    <div className='flex pb-4'>
                        <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.name' /></div>
                        <div className='w-2/4'>
                            <QInput
                                type='text'
                                name='name'
                                value={name}
                                handleBlur={(e, item) => {
                                    save(item.value, number)
                                }} />
                        </div>
                    </div>
                    <div className='flex pb-4'>
                        <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.number' /></div>
                        <div className='w-2/4'>
                            <QInput
                                type='text'
                                name='number'
                                value={number}
                                handleBlur={(e, item) => {
                                    save(name, item.value)
                                }} />
                        </div>
                    </div>
                </div>}
            </div>
            {allowedToEdit && <div>
                <EditContextMenuButton onEdit={setEdit} />
            </div>}
        </div>}
    />
}
export default DetailedSupplierInfo;
