import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import Button from '../../../base/ui/components/buttons/Button';
import ContextMenu from '../../../base/ui/components/contextMenu/ContextMenu';
import Icon from '../../../base/ui/components/icons/Icon';
import { ListItem, ListItems, SecondaryActionEnum } from '../../../base/ui/components/listitems/ListItem';
import { Sizes } from '../../../base/ui/components/types';
import { preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { DetailedDocumentAppliesNoExpirationTypeEnum, DetailedProductRequiredDocumentRequiredAtOrderTypeEnum, DetailedProductRequiredDocumentRequiredOfProductTypeEnum, DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum, DocumentAppliesToProduct, DocumentAppliesToProductTypeEnum, DocumentAppliesToSupplier, DocumentAppliesToSupplierTypeEnum, DocumentContext, ExpirationFromFileTypeEnum, FileResource, GetDetailedDocument, OrganizedProductRequiredDocument, OrganizedProductRequiredDocumentStateEnum, TimeFrameFrequency, TimeFrameFrequencyTypeEnum, TimeFrameInterval, TimeFrameIntervalTypeEnum, TimeFrameNoExpiration, TimeFrameNoExpirationTypeEnum, TimeFrameOrder, TimeFrameOrderTypeEnum } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { CreateNewDocumentType, complianceAddFileToDocument, complianceClearDocument, complianceCreateDocument, getComplianceDetailedDocument } from '../complianceSlice';
import { getComplianceCurrentDocumentDataSelector, getComplianceDetailedDocumentSelector, getCurrentComplianceProductSelector } from '../selectors/ComplianceSelectors';
import AddFilesToDocument from './AddFilesToDocument';
import ComplianceDisplayResponsibles from './ComplianceDisplayResponsibles';
import ComplianceIgnoreRequirement from './ComplianceIgnoreRequirement';
import ComplianceProductEditDueDate, { DueDateTypeEnum } from './ComplianceProductEditDueDate';
import ComplianceProductRequirementDocumentState from './ComplianceProductRequirementDocumentState';
import ComplianceDocumentViewer from './document-viewer/ComplianceDocumentViewer';

type Props = {
    className?: string;
    requiredDocument: OrganizedProductRequiredDocument;
    requirementId: string;
    requirementName: string;
    productId: string;
    supplierId: string;
    orderId: string;
    index: number;
    editDueDate: boolean;
    date: string;
};

const ComplianceProductRequirementDocument = (props: Props): React.ReactElement => {
    const { className, requiredDocument, productId, supplierId, requirementId, requirementName, index, editDueDate, date } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const fileUploadRef = useRef(null);
    const _document = useSelector(getComplianceDetailedDocumentSelector);
    const [document, setDocument] = useState(_document);
    const [documentId, setDocumentId] = useState(null);
    const [modalId, setModalId] = useState(requiredDocument.deadline_id + index);
    const [filesLoaded, setFilesLoaded] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const [ignoreId] = useState(uuid());
    const modalData = useSelector(getShowModalSelector);
    const product = useSelector(getCurrentComplianceProductSelector);
    const modal = modalData && modalData['compliance_document_viewer_' + modalId]
    const modalOpen = modal && modal.show || false;
    const isIgnored = requiredDocument.document_type === 'ignored';
    const userCanAddAndRemoveFiles = requiredDocument?.permissions?.includes('documents_set_files');
    const userCanRequestAndSubmitApproval = requiredDocument?.permissions?.includes('documents_request_approval');
    const userCanApproveRejectReset = requiredDocument?.permissions?.includes('documents_set_approval');
    const userCanIgnoreRequirement = requiredDocument?.permissions?.includes('documents_ignorations');
    const userCanEditProduct = product?.permissions?.includes('product_edit');
    const context: DocumentContext = { product_id: productId, deadline_id: requiredDocument.deadline_id };
    const documentData = useSelector(getComplianceCurrentDocumentDataSelector);

    const andLabel = intl.formatMessage({ id: 'globals.and_lower_case' });

    useEffect(() => {
        setDocument(_document);
    }, [_document]);

    useEffect(() => {
        // console.log('index ', index, documentIdParam, requiredDocument.document_id)
        /* if (documentIdParam && (modal && modal.data && modal.data !== requiredDocument.deadline_id) && documentIdParam === requiredDocument.document_id) {
            getDocument(documentIdParam);
        } */
    }, [index])
    useEffect(() => {
        if (filesLoaded) {
            dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: true, data: requiredDocument.deadline_id }))
            setDocumentId(null);
            setFilesLoaded(false);
        }
    }, [filesLoaded]);

    const appliesTo: DocumentAppliesToProduct = {
        type: 'document_applies_to_product',
        product_id: ''
    }

    const handleAddFileToDocument = (_files: FileResource[]) => {
        const fileIds = _files.map((file) => file.id);
        dispatch(complianceAddFileToDocument({
            appliesTo,
            requirementId: documentData?.document?.requirement_specification_id,
            documentTemplateId: documentData?.document?.document_specification_id,
            productId: documentData?.context?.product_id,
            documentId: documentData?.documentId,
            fileIds,
            supplierId: '',
            orderId: '',
            supplierType: 'supplier',
            context: documentData?.context,
        }));
    }
    const upload = <AddFilesToDocument
        className='px-4'
        hideUploadActions
        fileUploadRef={fileUploadRef}
        onUploadComplete={(files) => {
            handleAddFileToDocument(files as FileResource[]);
            setFilesLoaded(true);
        }}
    />
    const getDocument = (existingId: string, addFiles: boolean) => {
        let id: string;
        if (!modalOpen) {
            if (requiredDocument.document_has_files || !userCanAddAndRemoveFiles) {
                dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: true, data: requiredDocument.deadline_id }));
                setDocumentId(null);
            } else {
                if (requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval) {
                    fileUploadRef?.current.click();
                }
            }
        }
        if (existingId) {
            id = existingId;
            const req: GetDetailedDocument = { context, document_id: id }
            dispatch(getComplianceDetailedDocument(req));
        } else {
            const newId = uuid();
            id = newId;
            let timeframe: TimeFrameNoExpiration | TimeFrameOrder | TimeFrameInterval | TimeFrameFrequency;
            let applies_to: DocumentAppliesToProduct | DocumentAppliesToSupplier;
            let order_id: string;

            if (requiredDocument.required_of.type === DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum.RequiredOfSupplier) {
                applies_to = {
                    supplier_id: requiredDocument.required_of.supplier_id,
                    type: DocumentAppliesToSupplierTypeEnum.DocumentAppliesToSuppliers,
                }
            }
            if (requiredDocument.required_of.type === DetailedProductRequiredDocumentRequiredOfProductTypeEnum.RequiredOfProduct) {
                applies_to = {
                    product_id: requiredDocument.required_of.product_id,
                    type: DocumentAppliesToProductTypeEnum.DocumentAppliesToProduct,
                }
            }
            if (requiredDocument.required_at.type === DetailedProductRequiredDocumentRequiredAtOrderTypeEnum.Order) {
                order_id = requiredDocument.required_at.order_id;
                timeframe = {
                    type: TimeFrameOrderTypeEnum.TimeFrameOrder,
                    order_id,
                }
            }
            if (requiredDocument.required_at.type === DetailedDocumentAppliesNoExpirationTypeEnum.NoExpiration) {
                timeframe = {
                    type: TimeFrameNoExpirationTypeEnum.TimeFrameNoExpiration,
                    from: date,
                }
            }
            if (requiredDocument.required_at.type === ExpirationFromFileTypeEnum.ExpirationFromFile) {
                timeframe = {
                    type: TimeFrameIntervalTypeEnum.TimeFrameInterval,
                    from: date,
                    until: null,
                }
            }
            if (requiredDocument.required_at.type === 'frequency') {
                timeframe = {
                    type: TimeFrameFrequencyTypeEnum.TimeFrameFrequency,
                    from: date,
                }
            }
            const req: CreateNewDocumentType = {
                applies_to,
                document_id: newId,
                document_specification_id: requiredDocument.document_template_id,
                requirement_specification_id: requiredDocument.requirement_id,
                supplier: {
                    supplier_id: supplierId,
                    type: 'supplier',
                },
                productId,
                timeframe,
                context: { product_id: productId, deadline_id: requiredDocument.deadline_id }
            }
            dispatch(complianceCreateDocument(req));
        }
        setDocumentId(id);
        return id;
    }

    const closeDocument = () => {
        dispatch(complianceClearDocument());
        dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: false }))
        setDocumentId(null);
        setDocument(null);
        setShowDocument(false);
    }

    const displayIgnoreRequirement = () => {
        dispatch(showModal({ id: 'complianceIgnoreRequirement', show: true, data: { id: ignoreId } }));
    }
    const dv = useMemo(() => {
        return <ComplianceDocumentViewer
            documentId={requiredDocument.document_id}
            productId={productId}
            products={null}
            document={document}
            name={requiredDocument.name}
            show={(modalOpen)}
            onClose={closeDocument}
            orderId={props.orderId}
            deadlineId={requiredDocument.deadline_id}
        />
    }, [document, modalData, filesLoaded]);

    const actionClass = 'w-[105px] items-center justify-center self-center py-1';
    const isApproved = requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.Approved;

    const getState = (state: OrganizedProductRequiredDocumentStateEnum) => {
        switch (state) {
            case OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval: {
                if (!requiredDocument.document_has_files) {
                    return userCanAddAndRemoveFiles ?
                        <Button size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.add_files' /></Button>
                        :
                        <span className='flex justify-center text-secondary w-full'><FormattedMessage id='compliance.requirement_document.state.awaiting_files' /></span>
                }
                if (requiredDocument.document_has_files) {
                    return userCanRequestAndSubmitApproval ? <Button size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.submit' /></Button>
                        :
                        <span className='flex justify-center text-secondary w-full'><FormattedMessage id='compliance.requirement_document.state.awaiting_submit' /></span>
                }
            }
            case OrganizedProductRequiredDocumentStateEnum.ReadyForApproval: {
                return userCanRequestAndSubmitApproval ? <Button onPress={(e) => {
                    e.continuePropagation();
                }} context size={Sizes.Small} className={actionClass}><FormattedMessage id='compliance.requirement_document.state.review' /></Button>
                    :
                    <span className='flex justify-center text-secondary w-full'><FormattedMessage id='compliance.requirement_document.state.awaiting_review' /></span>
            }
            case OrganizedProductRequiredDocumentStateEnum.Approved: {
                return <span className={twMerge(actionClass, 'flex gap-1 text-secondary')}><Icon name='status_approved-circle' size={Sizes.XSmall} /><FormattedMessage id='compliance.requirement_document.state.done' /></span>
            }
            case OrganizedProductRequiredDocumentStateEnum.Rejected: {
                return userCanApproveRejectReset ?
                    <Button onPress={(e) => e.continuePropagation()} size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.replace_files' /></Button>
                    :
                    <span className='flex justify-center text-secondary w-full'><FormattedMessage id='compliance.requirement_document.state.awaiting_approval' /></span>
            }
        }
    }
    return <div className={twMerge('', className)} >
        {dv}
        <ListItems>
            <ListItem className={twMerge('flex cursor-pointer', (isIgnored && !userCanIgnoreRequirement) && 'cursor-default', isApproved && 'pr-[40px]')}
                secondaryAction={!isIgnored && !isApproved && userCanIgnoreRequirement && {
                    type: SecondaryActionEnum.Custom, customContent: !isIgnored && <div className='border-l-1'
                        onClick={(e) => preventDefaultAndStopPropagation(e)}
                    >
                        <ContextMenu
                            className=''
                            trigger={<Button stopPropagation ghost className='-mx-2 -my-3 px-3 py-[14px]  outline-none border'><Icon name='microinteraction_context-menu' /></Button>}
                            overflowItems={[{
                                label: 'Ignore requirement',
                                icon: 'toggle_visibility-off',
                                onPress: displayIgnoreRequirement,
                            }]}
                        />
                    </div>
                }}
                onPress={(e) => {
                    if (isIgnored) {
                        displayIgnoreRequirement();
                        return false;
                    }
                    const id = getDocument(requiredDocument.document_id, requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval);
                    history.push('?docId=' + id);
                    e.continuePropagation();
                }}
                icon={<ComplianceProductRequirementDocumentState state={requiredDocument.state} hasFiles={requiredDocument.document_has_files} isIgnored={isIgnored} />}>
                <div className='flex w-full'>
                    <div className={twMerge('w-5/12 flex items-center self-center', isIgnored && 'text-secondary')}>
                        {requiredDocument.name?.text}
                    </div>
                    <div className='w-5/12 text-left items-center self-center text-sm'>
                        <ComplianceDisplayResponsibles
                            users={requiredDocument.responsible.users}
                            groups={requiredDocument.responsible.groups}
                            supplier={requiredDocument.responsible.supplier}
                        />                        
                    </div>
                    <div className='w-2/12 h-full flex text-sm justify-end'>
                        {!isIgnored && <>{getState(requiredDocument.state)}</>}
                    </div>
                    {userCanIgnoreRequirement && <ComplianceIgnoreRequirement
                        products={[]}
                        supplierId={supplierId}
                        orderId={props.orderId}
                        documentSpecificationId={requiredDocument && requiredDocument.document_template_id}
                        documentName={requiredDocument.name.text}
                        documentId={requiredDocument.document_id}
                        requirementName={requirementName}
                        isIgnored={isIgnored}
                        id={ignoreId}
                    />}
                </div>
            </ListItem>
        </ListItems>
        {upload}
        {(editDueDate && userCanEditProduct) && <div className='px-4 pt-2 pb-1'>
            <ComplianceProductEditDueDate type={DueDateTypeEnum.Edit} productId={productId} deadlineId={requiredDocument.deadline_id} />
        </div>}
    </div >;
}
export default ComplianceProductRequirementDocument;
