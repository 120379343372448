import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CustomFieldGroupsDocumentationTypes, DocumentationType, ListableProductionUnit, SimpleSupplierDocument } from '../../../backend_api/models';
import { Feature } from '../../../backend_api/models';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { getFeaturesSelector, getPermissionsSelector } from '../../../base/selectors';
import { getLocaleLanguageString, getPrettyDate, getTranslation, isDateExpired, isUndefinedOrNull, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { ListView, ListViewHeader, ListViewHeaderItem, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import ListProductionUnitStatus from '../ListProductionUnitStatus';
import { getListableProductionUnitsSelector, getProductionUnitDocumentTypeGroups, getProductionUnitDocumentTypeGroupsSelector } from '../slice/productionUnitsSlice';
import { Sizes } from '../../../base/ui/components/types';
import { Popup } from 'semantic-ui-react';
import Icon from '../../../base/ui/components/icons/Icon';
import Button from '../../../base/ui/components/buttons/Button';
import { useAppDispatch } from '../../../store';
import { activateSupplierDocument, approveSupplierDocument, archiveSupplierDocument, deleteSupplierDocument, fetchSupplierDocument, getViewingSupplierDocumentSelector, rejectSupplierDocument, resetSupplierDocumentStatus, submitForApproval, supplierDocumentsSliceActions, unarchiveSupplierDocument, unsubmitSupplierDocument, updateValidityInterval } from '../../supplierDocuments/supplierDocumentsSlice';
import { getDocumentationTypeById } from '../../../base/ui/components/documentViewer/SupplierDocumentApprovalAndValidity';
import { getSupplierDocumentStatusColor } from './SupplierDocumentItemViewMode';
import SupplierDocumentViewer, { DocumentViewerAction } from '../../../base/ui/components/documentViewer/SupplierDocumentViewer';
import { showSnackbar } from '../../../base/ui/uiSlice';

type Props = {
    productionUnits?: ListableProductionUnit[];
    containerClassName?: string;
    listClassName?: string;
    confirmDeleteTextId: string;
    removeProductionUnit?(productionUnitId: string): void;
    isInsideSupplierPage?: boolean;
}

export const BasicProductionUnitList = (props: Props): React.ReactElement => {
    const { containerClassName, listClassName, isInsideSupplierPage } = props;
    const intl = useIntl();
    const canShowStatus = useSelector(getFeaturesSelector).includes(Feature.SupplierCompliance);
    const permissions = useSelector(getPermissionsSelector);
    const canShowDocumentation = permissions.includes('u_production_unit_documentation_types') && !isInsideSupplierPage;
    const productionUnits = !isUndefinedOrNull(props.productionUnits) ? props.productionUnits : useSelector(getListableProductionUnitsSelector);
    const hasProductionUnits = productionUnits.length > 0;
    const formatMessage = intl.formatMessage;
    const dispatch = useAppDispatch();

    const supplierDocumentMap: {[documentId: string]: ListableProductionUnit} = {};
    productionUnits.forEach(productionUnit => {
        productionUnit.supplier_documents.forEach(supplierDocument => {
            supplierDocumentMap[supplierDocument.id] = productionUnit;
        });
    });

    useEffect(() => {
        dispatch(getProductionUnitDocumentTypeGroups());
    }, []);

    const documentTypeGroups = useSelector(getProductionUnitDocumentTypeGroupsSelector);
    const documentation = useSelector(getViewingSupplierDocumentSelector);

    const documentActions: DocumentViewerAction = {
        archive: () => {
            dispatch(archiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        unarchive: () => {
            dispatch(unarchiveSupplierDocument({ id: documentation?.supplier_document_id }));
        },
        remove: () => {
            dispatch(deleteSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.deleted' /></div>,
            }));
        },
        approve: () => {
            dispatch(approveSupplierDocument({ id: documentation?.supplier_document_id }));
            const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='conclusion.lowercase.approved' /></div>,
                action: <Button onPress={() => openDocumentViewer(documentation?.supplier_document_id)} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button>,
                icon: 'status_approved-circle',
            }))
        },
        reject: (reason: string) => {
            dispatch(rejectSupplierDocument({ id: documentation?.supplier_document_id, body: { reason } }));
            closeDocumentViewer();
        },
        updateValidity: (startDate, expiredDate) => {
            dispatch(updateValidityInterval({ id: documentation?.supplier_document_id, interval: { start_date: startDate, expiry_date: expiredDate } }));
        },
        reset: () => {
            dispatch(resetSupplierDocumentStatus({ id: documentation?.supplier_document_id }));
        },
        activate: () => {
            dispatch(activateSupplierDocument({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
        },
        submit: () => {
            dispatch(submitForApproval({ id: documentation?.supplier_document_id }));
            closeDocumentViewer();
            const documentationType = getDocumentationTypeById(documentTypeGroups, documentation?.documentation_type_id);
            dispatch(showSnackbar({
                hideAfter: 5000,
                content: <div><b>{documentationType?.name?.text + " "}</b><FormattedMessage id='production_units.document_viewer.submitted_for_review' /></div>,
                action: <Button onPress={() => openDocumentViewer(documentation?.supplier_document_id)} className='px-3 py-1 text-sm'><FormattedMessage id='document_viewer.view' /></Button>,
            }));
        },
        unsubmit: () => {
            dispatch(unsubmitSupplierDocument({ id: documentation.supplier_document_id }));
            closeDocumentViewer();
        },
    };

    const openDocumentViewer = (id: string) => {
        dispatch(fetchSupplierDocument({ id }));
    };

    const closeDocumentViewer = () => {
        dispatch(supplierDocumentsSliceActions.hideDocumentViewer());
    };

    let headerItems: ListViewHeaderItem[] = [];

    if (canShowStatus) {
        headerItems = [
            { label: formatMessage({ id: 'production_units.input_label.status' }), className: "sm:pr-4 sm:w-2/16", key: 'spacer.2' },
            { label: formatMessage({ id: 'production_units.input_label.unit_number' }), className: canShowDocumentation ? "w-3/16" : "w-6/16", key: 'item_number' },
            { label: formatMessage({ id: 'production_units.input_label.unit_name' }), className: canShowDocumentation ? "w-5/16" : "w-8/16", key: 'item_name' },
        ];
    } else {
        headerItems = [
            { label: formatMessage({ id: 'production_units.input_label.unit_number' }), className: canShowDocumentation ? "w-4/16" : "w-7/16", key: 'item_number' },
            { label: formatMessage({ id: 'production_units.input_label.unit_name' }), className: canShowDocumentation ? "w-6/16" : "w-9/16", key: 'item_name' },
        ];
    }

    if (canShowDocumentation) {
        headerItems.push({
            label: formatMessage({ id: 'production_units.input_label.documentation' }),
            className: props.removeProductionUnit ? 'w-5/16' : 'w-6/16',
            key: 'item_documentation'
        });
    }

    if (props.removeProductionUnit) {
        headerItems.push({ label: undefined, className: 'w-1/16 justify-end', key: 'spacer.delete' });
    }
        
    return (
        <>
            {!isInsideSupplierPage &&
            <SupplierDocumentViewer 
                linkedSupplier={{
                    id: supplierDocumentMap[documentation?.supplier_document_id]?.id,
                    name: supplierDocumentMap[documentation?.supplier_document_id]?.name,
                    number: supplierDocumentMap[documentation?.supplier_document_id]?.number,
                    type: 'production_unit'
                }}
                actions={documentActions}
                close={closeDocumentViewer}
            />} 
            <ListView className={twMerge('mt-0 rounded-md', containerClassName)}>
                {hasProductionUnits && <ListViewHeader items={headerItems} />}
                {hasProductionUnits && <ListViewLines className={listClassName}>
                    {productionUnits.map((productionUnit, i) => {
                        return <ProductionUnitItem 
                            key={i}
                            index={i}
                            productionUnit={productionUnit}
                            removeProductionUnit={props.removeProductionUnit}
                            confirmDeleteButtonId={props.confirmDeleteTextId}
                            openDocumentViewer={openDocumentViewer}
                            isInsideSupplierPage={isInsideSupplierPage}
                        />
                    })}
                </ListViewLines>}
            </ListView>
        </>
    )
}

function ProductionUnitItem(props: {
    index: number;
    productionUnit: ListableProductionUnit,
    removeProductionUnit: (id: string) => void,
    confirmDeleteButtonId: string,
    openDocumentViewer: (id: string, productionUnit?: ListableProductionUnit) => void,
    isInsideSupplierPage: boolean
}) {
    const {index, productionUnit, removeProductionUnit, confirmDeleteButtonId, openDocumentViewer, isInsideSupplierPage} = props;
    const intl = useIntl();

    const canShowStatus = useSelector(getFeaturesSelector).includes(Feature.SupplierCompliance);
    const permissions = useSelector(getPermissionsSelector);
    const canShowDocumentation = permissions.includes('u_production_unit_documentation_types') && !isInsideSupplierPage;
    const documentTypeGroups = useSelector(getProductionUnitDocumentTypeGroupsSelector);

    return (<>
        <ListViewLine link={'/production_units/' + productionUnit.id} className={twMerge('border-t sm:border-t-0 justify-start pb-6 pt-6 hover:bg-highlight-green')} key={'production_units_' + productionUnit.id + '_' + index}>
            {canShowStatus && <ListViewItem className='pr-1 sm:pr-4 sm:w-2/16 w-[100px]'><ListProductionUnitStatus status={productionUnit.status} /></ListViewItem>}
            <ListViewItem className={`${canShowStatus ? (canShowDocumentation ? 'w-3/16' : 'w-6/16') : (canShowDocumentation ? 'w-4/16' : 'w-7/16')} pr-1 pl-3 sm:pl-0 font-bold`}>{productionUnit.number}</ListViewItem>
            <ListViewItem className={`${canShowStatus ? (canShowDocumentation ? 'w-5/16' : 'w-8/16') : (canShowDocumentation ? 'w-6/16' : 'w-9/16')} grow pr-1`}>{productionUnit.name}</ListViewItem>
            {canShowDocumentation &&
                <ListViewItem className={removeProductionUnit ? 'w-5/16' : 'w-6/16 grow sm:grow-0'}>
                    <DocumentationPopup
                        documentTypeGroups={documentTypeGroups}
                        viewDocument={openDocumentViewer}
                        productionUnit={productionUnit}
                        trigger={<div className='flex flex-wrap gap-1 pt-3 sm:pt-0'>
                            {
                                productionUnit.supplier_documents.map((doc, i) => {
                                    return <div className={twMerge('justify-center inline px-1 min-w-[2rem] text-on-status rounded text-sm flex', getSupplierDocumentStatusColor(doc.state, doc.archived))}>{doc.documentation_type_code}</div>;
                                })
                            }
                        </div>}
                    />
                </ListViewItem>
            }
            {
                removeProductionUnit &&
                <ListViewItem className='w-1/16 justify-end'>
                    <div onClick={(event) => preventDefaultAndStopPropagation(event)}>
                        <DeleteConfirm
                            deleteText={intl.formatMessage({ id: confirmDeleteButtonId })}
                            deleteFunction={() => removeProductionUnit(productionUnit.id)}
                            trigger={<Button size={Sizes.Small}>
                                <FormattedMessage id={confirmDeleteButtonId} />
                            </Button>} />
                    </div>
                </ListViewItem>
            }
        </ListViewLine>
        </>
    )
}

function DocumentationPopup(props: { productionUnit: ListableProductionUnit, trigger: React.ReactElement, viewDocument: (id: string) => void, documentTypeGroups: CustomFieldGroupsDocumentationTypes[] }): React.ReactElement {
    const { productionUnit, trigger, viewDocument, documentTypeGroups } = props;
    const [showPopup, setShowPopup] = React.useState(false);

    const handleViewDocument = (id: string) => {
        viewDocument(id);
        setShowPopup(false);
    }

    return <Popup
        on="hover"
        trigger={trigger}
        position="top center"
        hideOnScroll={true}
        closeOnPortalMouseLeave={true}
        closeOnTriggerMouseLeave={true}
        closeOnDocumentClick={true}
        closeOnTriggerClick={true}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
    >
        <Popup.Content>
            <div className='text-primary font-bold text-lg'>{<FormattedMessage id='production_units.input_label.documentation' />}</div>
            <div className="mb-4">
                <div className='text-secondary text-sm flex flex-row gap-1 mb-4'>
                    <span className='font-bold whitespace-nowrap'>{productionUnit.number}</span>
                    <span>{productionUnit.name}</span>
                </div>
                {productionUnit.supplier_documents.map((doc, i) => {
                    const documentationType = getDocumentationTypeById(documentTypeGroups, doc.documentation_type_id);
                    return (
                        <SimpleDocumentItem 
                            key={doc.id}
                            simpleDocument={doc}
                            documentationType={documentationType}
                            openDocumentViewer={handleViewDocument}
                        />
                    )
                })}
                {productionUnit.supplier_documents.length === 0 &&
                    <div className='text-secondary border flex justify-center items-center p-4 sm:px-9 whitespace-nowrap mt-4 italic rounded-md text-secondary'>
                        <FormattedMessage id='production_units.popup.no_documents' />
                    </div>
                }
            </div>
        </Popup.Content>
    </Popup>
}

function renderSimpleDocumentationState(props: {
    documentation: SimpleSupplierDocument,
    documentationType: DocumentationType
}) {
    const { documentation, documentationType } = props;
    if (!documentation) {
        return null;
    }

    if (!documentation.valid_from_date || (documentationType?.requires_expiry && !documentation.valid_to_date)) {
        return <FormattedMessage id="production_units.documentation.validity_not_set" />;
    }

    if (documentation.valid_to_date) {
        const isExpired = isDateExpired(documentation.valid_to_date);
        if (isExpired) {
            return <FormattedMessage id="production_units.list.documentation.date.expired" values={{ date: getPrettyDate(documentation.valid_to_date) }} />;
        }
        return <FormattedMessage id="production_units.list.documentation.date.expires" values={{ date: getPrettyDate(documentation.valid_to_date) }} />;
    }

    if (!documentationType?.requires_expiry && documentation.valid_from_date) {
        return <FormattedMessage id="production_units.list.documentation.date.active_from" values={{ date: getPrettyDate(documentation.valid_from_date) }} />;
    }
}

function SimpleDocumentItem(props: { simpleDocument: SimpleSupplierDocument, documentationType: DocumentationType, openDocumentViewer: (id: string) => void }) {
    const { simpleDocument, documentationType, openDocumentViewer } = props;
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);

    return (
        <div className='flex flex-row justify-between gap-4 sm:gap-16 my-2 items-center cursor-pointer' onClick={(e) => {
            preventDefaultAndStopPropagation(e);
            openDocumentViewer(simpleDocument.id)
        }}>
            <div className='flex flex-row gap-4 items-center'>
                <div className={`${getSupplierDocumentStatusColor(simpleDocument.state, simpleDocument.archived)} text-on-status min-w-[6.75rem] h-[1.5rem] px-3 flex items-center justify-center rounded-md`}>
                    <FormattedMessage id={"production_units.supplier_document.status." + (simpleDocument?.archived ? "archived" : simpleDocument.state)} />
                </div>
                <div className='flex flex-row gap-1 items-center'>
                    <div className='font-bold whitespace-nowrap'>{getTranslation(simpleDocument.documentation_type_name, lang)}</div>
                    <Icon name='microinteraction_open' size={Sizes.Small} />
                </div>
            </div>
            <div className='whitespace-nowrap'>{renderSimpleDocumentationState({ documentation: simpleDocument, documentationType })}</div>
        </div>
    );
}