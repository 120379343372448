import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import DocumentTitle from 'react-document-title';
import { Modal } from 'semantic-ui-react';
import { DocumentationType } from '../../../backend_api/models/DocumentationType';
import { getDocumentationTypesList, getDocumentationTypesListSelector } from '../supplierDocumentsSlice';
import { useAppDispatch } from '../../../store';
import {
    ListView,
    ListViewHeader,
    ListViewHeaderItem,
    ListViewItem,
    ListViewLine,
    ListViewLines
} from '../../globals/components/views/list/ListView';
import { getLocaleLanguageString, getTranslation } from '../../../base/utils';
import PageContainer from '../../globals/components/PageContainer';
import Header from '../../../base/ui/components/headers/Header';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import UserPopup from '../../users/components/UserPopup';
import GroupMembersPopup from '../../groups/components/GroupMembersPopup';
import SupplierPopup from '../../suppliers/components/SupplierPopup';
import { Group } from '../../../backend_api/models';
import { BasicUser } from '../../../backend_api_2';
import DocumentationTypeApprovalResponsiblesSelector from './DocumentationTypeApprovalResponsiblesSelector';
import ContextMenu from '../../../base/ui/components/contextMenu/ContextMenu';
import { Sizes } from '../../../base/ui/components/types';

type Props = {
    className?: string;
};

const DocumentationApproval = (props: Props): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const lang = getLocaleLanguageString(intl.locale);
    const [selectedDocType, setSelectedDocType] = useState<number | string | null>(null);
    const [selectedDocTypeObject, setSelectedDocTypeObject] = useState<DocumentationType | null>(null);

    const allDocumentationTypes = useSelector(getDocumentationTypesListSelector);
    const allDocumentationTypesNoDuplicates = removeDocumentationTypeDuplicates(allDocumentationTypes);
    const documentationTypes = allDocumentationTypesNoDuplicates.filter(docType => docType.requires_approval);

    const hasDocumentationTypes = documentationTypes.length > 0;

    const headerItems: ListViewHeaderItem[] = [
        {
            label: formatMessage({ id: 'production_units.supplier_document.documentation_types' }),
            className: "w-full md:w-[41%] pb-4 sm:pb-0",
            key: 'documentation_type'
        },
        {
            label: formatMessage({ id: 'production_units.supplier_document.responsible_for_approval' }),
            className: "w-full md:w-[100%] pb-4 sm:pb-0",
            key: 'responsible_for_approval'
        },
    ];

    useEffect(() => {
        dispatch(getDocumentationTypesList());
    }, [dispatch]);

    // Update selectedDocTypeObject when selectedDocType or documentationTypes change
    useEffect(() => {
        if (selectedDocType && documentationTypes.length > 0) {
            const docType = documentationTypes.find(dt => dt.id === selectedDocType);
            setSelectedDocTypeObject(docType || null);
        } else {
            setSelectedDocTypeObject(null);
        }
    }, [selectedDocType, documentationTypes]);

    const handleCloseModal = () => {
        setSelectedDocType(null);
        setSelectedDocTypeObject(null);
    };

    const items = <ListViewLines className='flex flex-col'>
        {documentationTypes.map((documentationType, index) => {
            const isLast = index === documentationTypes.length - 1;

            const getDocTypeName = () => {
                if (!documentationType.name) {
                    return 'Unknown';
                }

                // Check if name is a string
                if (typeof documentationType.name === 'string') {
                    return documentationType.name;
                }

                try {
                    return getTranslation(documentationType.name, lang);
                } catch (error) {
                    console.error('Error translating name:', error);
                    // If we can access text property, it's likely a TextWithTranslation object
                    if (documentationType.name && typeof documentationType.name === 'object' && 'text' in documentationType.name) {
                        return documentationType.name.text || 'Unknown';
                    }
                    // Last resort fallback
                    return String(documentationType.name) || 'Unknown';
                }
            };

            // Create components array for ComponentsSeparatedBy
            const responsibleComponents = [
                ...documentationType.responsible_groups.map((group, groupIndex) =>

                    group.is_supplier_group ? (
                        <SupplierPopup
                            key={`supplier-${group.id}-${groupIndex}`}
                            supplier={{
                                id: group.supplier_id,
                                name: group.name
                            }}
                        />
                    ) : (
                        <GroupMembersPopup
                            key={`group-${group.id}-${groupIndex}`}
                            group={{ ...group, is_supplier_group: false } as Group}
                        />
                    )
                ),
                ...documentationType.responsible_users.map((user, userIndex) => {
                    return (
                        <UserPopup
                            key={`user-${user.id}-${userIndex}`}
                            user={{
                                ...user,
                                disabled: false,
                                last_activity: null,
                                supplier_user: false
                            } as BasicUser}
                        />
                    )
                })
            ];

            return (
                <Modal
                    key={`documentation_type_${documentationType.id}_${index}`}
                    open={selectedDocType === documentationType.id}
                    closeOnEscape
                    closeIcon
                    style={{ margin: 'auto', width: '35rem' }}
                    trigger={
                        <ListViewLine
                            className={twMerge('cursor-pointer justify-start pb-6 pt-6 hover:bg-highlight-green ' + (isLast ? 'md:border-b-0' : 'border-b'))}
                        >
                            <div className='flex flex-col md:flex-row w-full'>
                                <ListViewItem className='w-full md:w-[42%] pb-4 md:pb-0 text-primary text-normal text-base'>
                                    {getDocTypeName()}
                                </ListViewItem>
                                <ListViewItem className='w-full md:w-[100%] text-primary text-normal text-base'>
                                    {(documentationType.responsible_groups.length > 0 || documentationType.responsible_users.length > 0) ? (
                                        <ComponentsSeparatedBy
                                            components={responsibleComponents}
                                            separateBy=", "
                                        />
                                    ) : (
                                        <div className="text-normal text-base text-secondary italic">
                                            <FormattedMessage id={"production_units.supplier_document.not_set"} />
                                        </div>
                                    )}
                                </ListViewItem>
                                <ListViewItem className='w-full md:w-[3%] text-primary text-normal text-base'>
                                    <ContextMenu className='h-fit-content'
                                        actions={[
                                            {
                                                icon: 'operation_edit',
                                                label: 'globals.edit',
                                                className: '',
                                                labelLeft: false,
                                                onPress: () => setSelectedDocType(documentationType.id),
                                                buttonSize: Sizes.Small
                                            }
                                        ]} />
                                </ListViewItem>
                            </div>
                        </ListViewLine>
                    }
                    onOpen={() => setSelectedDocType(documentationType.id)}
                    onClose={handleCloseModal}
                >
                    <Modal.Header>{getDocTypeName()}</Modal.Header>
                    <Modal.Content>
                        {selectedDocTypeObject && (
                            <DocumentationTypeApprovalResponsiblesSelector
                                className="mt-4"
                                documentationType={selectedDocTypeObject}
                                onClose={handleCloseModal}
                            />
                        )}
                    </Modal.Content>
                </Modal>
            );
        })}
    </ListViewLines>

    return (
        <PageContainer>
            <DocumentTitle title={intl.formatMessage({ id: 'production_units.supplier_document.documentation_approval' })} />
            <Header
                pageTitle={intl.formatMessage({ id: 'production_units.supplier_document.documentation_approval' })}
                entity={intl.formatMessage({ id: 'globals.system_settings' })}
            />

            <div className='flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4'>
                <ListView className={twMerge('mt-0 w-full', className)}>
                    {hasDocumentationTypes && (
                        <>
                            <ListViewHeader className='justify-start hidden md:flex' items={headerItems} />
                            <div className="rounded-md overflow-hidden border">
                                {items}
                            </div>
                        </>
                    )}
                </ListView>
            </div>
        </PageContainer>
    );
}

function removeDocumentationTypeDuplicates(documentationTypes: DocumentationType[]): DocumentationType[] {
    const documentationTypeMap: Record<string, DocumentationType> = {};
    documentationTypes.forEach(docType => {
        documentationTypeMap[docType.id] = docType;
    });
    return Object.values(documentationTypeMap);
}

export default DocumentationApproval;
