import React, { useEffect } from "react";
import QModal from "../../../base/ui/components/modals/Modal";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import DocumentationTypesFilter from "../../filters/components/DocumentationTypesFilter";
import {
    DisplayDetailedSupplierDocument,
    FileResource,
} from "../../../backend_api/models";
import { twMerge, useIsMobileDevice } from "../../../base/utils";
import Button, {
    ButtonTypes,
} from "../../../base/ui/components/buttons/Button";
import { useAppDispatch } from "../../../store";
import DeleteDocumentConfirmModal from "./DeleteDocumentConfirmModal";
import { getPermissionsSelector } from "../../../base/selectors";
import {
    archiveSupplierDocument,
    createSupplierDocumentOnProductionUnit,
    createSupplierDocumentOnSupplier,
    deleteSupplierDocument,
    fetchSupplierDocument,
    getAllDocumentsForProductionUnit,
    getAllDocumentsForSupplier,
    getAllSupplierDocumentsSelector,
    getFetchingSupplierDocumentsSelector,
    unarchiveSupplierDocument,
} from "../../supplierDocuments/supplierDocumentsSlice";
import SupplierDocumentItemViewMode from "./SupplierDocumentItemViewMode";
import FileUploadModal from "../../files/components/FileUploadModal";
import DocumentationTypeTooltipContent from "../../supplierDocuments/components/DocumentationTypeTooltipContent";
import { useCheckHasAnyOfThesePermissions } from "../../../base/hooks";
export function ViewAllDocumentsModal(props: {
    showing: boolean;
    setShowing: (showing: boolean) => void;
    addDocumentation: () => void;
    filters?: string[];
    productionUnitId?: string;
    supplierId?: string;
}) {
    const {
        showing,
        setShowing,
        filters,
        addDocumentation,
        productionUnitId,
        supplierId,
    } = props;
    const dispatch = useAppDispatch();

    const documents = useSelector(getAllSupplierDocumentsSelector);
    const isLoading = useSelector(getFetchingSupplierDocumentsSelector);
    const permissions = useSelector(getPermissionsSelector);
    const userHasDocumentationTypes = useCheckHasAnyOfThesePermissions(permissions, ['u_production_unit_documentation_types', 'u_supplier_documentation_types']);
    const [documentFilters, setDocumentFilters] = React.useState<string[]>(filters || []);
    const showingDocuments =
        documentFilters.length > 0
            ? documents.filter((doc) =>
                documentFilters.includes(doc.documentation_type_id)
            )
            : documents;

    useEffect(() => {
        if (showing && (productionUnitId || supplierId)) {
            if (productionUnitId) {
                dispatch(getAllDocumentsForProductionUnit({
                    production_unit_id: productionUnitId,
                }));
            } else if (supplierId) {
                dispatch(getAllDocumentsForSupplier({
                    supplier_id: supplierId,
                }));
            }
        }
    }, [showing, productionUnitId, supplierId, dispatch]);

    const emptyState = (!documents ||
        documents?.length == 0 ||
        showingDocuments.length == 0) && (
            <div className="border py-6 flex flex-col justify-center items-center rounded-md">
                <div className="italic text-secondary border-b pb-6">
                    {documents?.length == 0 ? (
                        <FormattedMessage
                            id={
                                productionUnitId
                                    ? "production_units.detailed_page.view_all_documents.no_documents"
                                    : "supplier.detailed_page.view_all_documents.no_documents"
                            }
                        />
                    ) : (
                        <FormattedMessage
                            id={
                                productionUnitId
                                    ? "production_units.detailed_page.view_all_documents.no_matched_documents"
                                    : "supplier.detailed_page.view_all_documents.no_matched_documents"
                            }
                        />
                    )}
                </div>
                {
                    userHasDocumentationTypes && documentFilters.length == 0 && (
                        <Button
                            className="mt-6"
                            buttonType={ButtonTypes.Primary}
                            onPress={() => {
                                setShowing(false);
                                addDocumentation();
                            }}
                        >
                            <FormattedMessage id="compliance.documentation.waiting_for_documentation.add" />
                        </Button>
                    )
                }
                {documentFilters.length > 0 &&
                    <div className="mt-6">
                        <FormattedMessage id="production_units.detailed_page.view_all_documents.try_to_clear_filters" />
                    </div>
                }
            </div>
        );

    return (
        <QModal
            isOpen={showing}
            width={883}
            header={
                <div className="flex flex-row justify-between items-center w-full font-normal pr-4">
                    <div className="font-bold">
                        <FormattedMessage id="production_units.detailed_page.all_documents" />
                    </div>
                </div>
            }
            content={
                <div className="min-h-[60vh]">
                    <DocumentationTypesFilter
                        type={productionUnitId ? "production_unit" : "supplier"}
                        label="filters.type"
                        placeholder="filters.any_type"
                        className="max-w-[340px]"
                        onFilterSet={(selectedTypes) => {
                            setDocumentFilters(selectedTypes);
                        }}
                        filters={filters}
                        allowMultipleChoice={true}
                    />
                    <div className="mt-6">
                        {isLoading ? (
                            <div className="border py-6 flex flex-col justify-center items-center rounded-md">
                                <div className="italic text-secondary border-b pb-6">
                                    <FormattedMessage id="globals.loading" />
                                </div>
                            </div>
                        ) : (
                            <>
                                {showingDocuments.map((doc, index) => {
                                    return (
                                        <DocumentItem
                                            key={doc.id}
                                            documentation={doc}
                                            productionUnitId={productionUnitId}
                                            supplierId={supplierId}
                                            isLastItem={index === showingDocuments.length - 1}
                                            isFirstItem={index === 0}
                                            closeModal={() => setShowing(false)}
                                        />
                                    );
                                })}
                                {emptyState}
                            </>
                        )}
                    </div>
                </div>
            }
            onOpenChange={open => {
                setShowing(open);
            }}
            onClose={() => {
                setShowing(false);
            }}
        />
    );
}

function DocumentItem(props: {
    documentation: DisplayDetailedSupplierDocument;
    productionUnitId?: string;
    supplierId?: string;
    isFirstItem: boolean;
    isLastItem: boolean;
    closeModal: () => void;
}) {
    const { documentation, productionUnitId, supplierId, isFirstItem, isLastItem, closeModal } = props;
    const dispatch = useAppDispatch();
    const [showUploadModal, setShowUploadModal] = React.useState({
        showing: false,
        doc: null,
    });
    const [showDeleteDocumentConfirmModal, setShowDeleteDocumentConfirmModal] =
        React.useState(false);
    const isMobile = useIsMobileDevice();

    const doArchive = () => {
        dispatch(archiveSupplierDocument({ id: documentation?.id }));
    };

    const doUnarchive = () => {
        dispatch(unarchiveSupplierDocument({ id: documentation?.id }));
    };

    const doDelete = () => {
        dispatch(deleteSupplierDocument({ id: documentation?.id }));
    };

    const handleViewDocument = () => {
        dispatch(fetchSupplierDocument({ id: documentation?.id }));
    };

    const uploadFilesToDocument = (file: FileResource) => {
        if (!!productionUnitId) {
            dispatch(
                createSupplierDocumentOnProductionUnit({
                    documentation_type_id:
                        showUploadModal.doc?.documentation_type_id,
                    media_resource_id: file?.id,
                    production_unit_id: productionUnitId,
                })
            );
        } else if (!!supplierId) {
            dispatch(
                createSupplierDocumentOnSupplier({
                    documentation_type_id:
                        showUploadModal.doc?.documentation_type_id,
                    media_resource_id: file?.id,
                    supplier_id: supplierId,
                })
            );
        }
        setShowUploadModal({ showing: false, doc: null });
        closeModal();
    };

    const uploadFileModal = (
        <FileUploadModal
            open={showUploadModal.showing}
            onClose={() => setShowUploadModal({ showing: false, doc: null })}
            onUploadComplete={(file) => {
                setShowUploadModal({ showing: false, doc: null });
                uploadFilesToDocument(file);
            }}
            isMobile={isMobile}
            allowMultiUpload={false}
        />
    );

    const deleteDocumentConfirmModal = (
        <DeleteDocumentConfirmModal
            open={showDeleteDocumentConfirmModal}
            close={() => setShowDeleteDocumentConfirmModal(false)}
            archiveDocument={doArchive}
            deleteDocument={doDelete}
        />
    );

    const documentationTypeTooltipContent = <DocumentationTypeTooltipContent documentationType={documentation as any} />

    return (
        <>
            {uploadFileModal}
            {deleteDocumentConfirmModal}
            <SupplierDocumentItemViewMode
                className={twMerge("border-t border-x", isLastItem ? "border-b rounded-b-md" : "", isFirstItem ? "rounded-t-md" : "")}
                detailedSupplierDocument={documentation}
                documentationTypeTooltipContent={documentationTypeTooltipContent}
                allowedToEdit={false}
                addNewDocument={() => {
                    setShowUploadModal({ showing: true, doc: documentation });
                }}
                deleteDocument={() => setShowDeleteDocumentConfirmModal(true)}
                archiveDocument={doArchive}
                unarchiveDocument={doUnarchive}
                viewDocument={handleViewDocument}
            />
        </>
    );
}
