import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListableProductionUnit } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { twMerge } from '../../../base/utils';
import { BasicProductionUnitList } from '../../productionUnits/components/BasicProductionUnitList';
import { CreateOrSelectProductionUnitModal } from '../../productionUnits/components/CreateOrSelectProductionUnitModal';
import { NewProductionUnitParams } from '../../productionUnits/components/NewProductionUnitParams';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';

type Props = {
    productionUnits: ListableProductionUnit[];
    className?: string;
    disabled?: boolean;
    isUserAllowedToCreateProductionUnits: boolean;
    confirmDeleteText: string;
    onUserWantsToLinkProductionUnit(productionUnitId: string): void;
    onUserWantsToUnlinkProductionUnit(productionUnitId: string): void;
    onUserWantsToCreateAndLinkProductionUnit(params: NewProductionUnitParams): void;
    allowedToLinkProductionUnits: boolean;
};

const DetailedSupplierProductionUnits = (props: Props): React.ReactElement => {
    const { className, disabled } = props;

    /* TODO Lukas: In old-mode, we should still hint about existing production units FOR THIS SUPPLIER ! */

    /* TODO: push this boolean up to parent component */
    const isUserAllowedToChooseExistingProductionUnits = useHasIndependentProductionUnits();
    const allowedToCreate = props.isUserAllowedToCreateProductionUnits
    const allowedToAddSomething = props.allowedToLinkProductionUnits && (allowedToCreate || isUserAllowedToChooseExistingProductionUnits)

    return <div className={twMerge('', className)}>
        <BasicProductionUnitList
            confirmDeleteTextId={props.confirmDeleteText}
            removeProductionUnit={props.onUserWantsToUnlinkProductionUnit}
            productionUnits={props.productionUnits}
            isInsideSupplierPage={true} />
        {allowedToAddSomething &&
            <CreateOrSelectProductionUnitModal
                allowCreatingNewProductionUnits={allowedToCreate}
                renderOpenButton={addButton}
                excludeProductionUnitIds={props.productionUnits && props.productionUnits.map(a => a.id)}
                allowExistingProductionUnits={isUserAllowedToChooseExistingProductionUnits}
                onProductionUnitIdSelected={props.onUserWantsToLinkProductionUnit}
                onUserWantedToCreateProductionUnit={props.onUserWantsToCreateAndLinkProductionUnit}
                groupsEnabled={true}
            />
        }
    </div >
}

function addButton() {
    return (
        <Button primary><FormattedMessage id='suppliers.add_production_unit' /></Button>
    )
}

export default DetailedSupplierProductionUnits
