/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileResource } from './FileResource';

/**
 *
 *
 */
export type AuditReportCustomFieldsDocumentationDocument = {
    approval_required: boolean;
    expiry_date: string;
    expiry_required: boolean;
    file_url: string;
    media_resources: Array<FileResource>;
    permissions: Array<string>;
    previously_added: boolean;
    start_date: string;
    status: AuditReportCustomFieldsDocumentationDocument.status;
    supplier_document_id: string;
};

export namespace AuditReportCustomFieldsDocumentationDocument {

    export enum status {
        FILE_ADDED = 'file_added',
        ACTIVE = 'active',
        WAITING_FOR_APPROVAL = 'waiting_for_approval',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        EXPIRED = 'expired',
        ARCHIVED = 'archived',
        DELETED = 'deleted',
    }


}

