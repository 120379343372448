import { IDocument as IDocumentBase } from '@cyntler/react-doc-viewer';
import React, { useState } from 'react';
import { getDetailedProductionUnitListSelector, getProductionUnitDocumentTypeGroupsSelector } from '../../../../modules/productionUnits/slice/productionUnitsSlice';
import { useAppDispatch } from '../../../../store';
import { useSelector } from 'react-redux';
import SupplierDocumentApprovalAndValidity, { getDocumentationTypeById } from './SupplierDocumentApprovalAndValidity';
import DocumentViewer from './DocumentViewer';
import { MicroInteractionContextMenu } from '../MicroInteractions';
import { FormattedMessage } from 'react-intl';
import ContextMenu, { ContextMenuOverflowItemType } from '../contextMenu/ContextMenu';
import Button from '../buttons/Button';
import { Sizes } from '../types';
import DeleteDocumentConfirmModal from '../../../../modules/productionUnits/components/DeleteDocumentConfirmModal';
import { ListItems, ListItem, ArrowTypeEnum } from '../listitems/ListItem';
import { SupplierDocumentExtendedStatusEnum } from '../../../../backend_api/models';
import DocumentResponsibles from './DocumentResponsibles';
import { Audit } from '../../../../backend_api_2';
import { getDocumentViewerVisibilitySelector, getViewingSupplierDocumentSelector, supplierDocumentsSliceActions } from '../../../../modules/supplierDocuments/supplierDocumentsSlice';
import { getDetailedSupplierSelector, getSupplierDocumentTypeGroupsSelector } from '../../../../modules/suppliers/slice/suppliersSlice';

type Props = {
    linkedSupplier: LinkedSupplier,
    linkedAudit?: Audit,
    actions: DocumentViewerAction,
    readOnly?: boolean,
    fromAuditReport?: boolean,
    close:() => void
};

export type LinkedSupplier = { 
    id: string;
    name: string;
    number: string;
    type: 'production_unit' | 'supplier';
};

export type DocumentViewerAction = {
    archive?: () => void;
    unarchive?: () => void;
    remove?: () => void;
    approve?: () => void;
    reject?: (reason: string) => void;
    submit?: () => void;
    unsubmit?: () => void;
    activate?: () => void;
    reset?: () => void;
    updateValidity?: (startDate: string, expiredDate: string) => void;
};

export interface IDocument extends IDocumentBase { fileResourceId?: string };

const SupplierDocumentViewer = (props: Props): React.ReactElement => {
    const { readOnly, linkedSupplier, linkedAudit, actions, fromAuditReport } = props;
    const dispatch = useAppDispatch();
    const documentViewer = useSelector(getViewingSupplierDocumentSelector);
    const showingDocumentViewer = useSelector(getDocumentViewerVisibilitySelector);
    const productionUnit = useSelector(getDetailedProductionUnitListSelector);
    const supplier = useSelector(getDetailedSupplierSelector);
    const supplierDocumentTypeGroups = useSelector(getSupplierDocumentTypeGroupsSelector);
    const productionUnitDocumentTypeGroups = useSelector(getProductionUnitDocumentTypeGroupsSelector);
    const documentationType = getDocumentationTypeById(productionUnitDocumentTypeGroups.concat(supplierDocumentTypeGroups), documentViewer?.documentation_type_id);
    const [showDeleteDocumentConfirmModal, setShowDeleteDocumentConfirmModal] = useState(false);
    const canEditDocument = documentViewer?.permissions?.includes('edit_supplier_documents');

    const handleClose = () => {
        dispatch(supplierDocumentsSliceActions.hideDocumentViewer());
    };

    const goToProductionUnit = () => {
        if(linkedSupplier.id == supplier?.id || linkedSupplier.id == productionUnit?.id) {
            handleClose();
        } else {
            if(linkedSupplier.type === 'production_unit') {
                const productionUnitUrl = document.location.origin.concat("/q/app/production_units/" + linkedSupplier.id);
                window.open(productionUnitUrl, '_blank');
            } else {
                const supplierUrl = document.location.origin.concat("/q/app/suppliers/" + linkedSupplier.id)
                window.open(supplierUrl, '_blank')
            }
        }
    }

    const coveredProductionUnit = <div>
        <h4 className='mb-2'><FormattedMessage id={linkedSupplier.type === 'production_unit' ? 'document_viewer.validity.covered_production_unit' : 'document_viewer.validity.covered_supplier'}/></h4>
        <ListItems className={'border border-default border-b-0 rounded-md bg-default'}>
            <ListItem className='rounded-t-md'
                arrow={{ type: ArrowTypeEnum.External, onArrowClick: goToProductionUnit }}
                onPress={goToProductionUnit}>
                <div className='gap-2 flex'><b>{linkedSupplier.name}</b> {linkedSupplier.number}</div>
            </ListItem>
        </ListItems>
    </div>;

    const contextMenuItems = [
        canEditDocument && actions?.archive && documentViewer?.extended_status !== SupplierDocumentExtendedStatusEnum.Archived && { label: 'Archive document', icon: 'operation_archive', onPress: actions.archive },
        canEditDocument && actions?.unarchive && documentViewer?.extended_status == SupplierDocumentExtendedStatusEnum.Archived && { label: 'Unarchive document', icon: 'operation_unarchive', onPress: actions.unarchive },
        canEditDocument && actions?.remove && { label: 'Delete document', icon: 'operation_delete', className: 'text-danger', onPress: () => setShowDeleteDocumentConfirmModal(true) }
    ].filter(item => !!item) as ContextMenuOverflowItemType[];

    return React.useMemo(() => {
        return <>
            <DocumentViewer
                userCanAddAndRemoveFiles={!!linkedAudit && !readOnly}
                documentName={documentationType?.name?.text}
                show={showingDocumentViewer}
                onClose={handleClose}
                fileResources={documentViewer?.media_resources}
                deleteFile={() => setShowDeleteDocumentConfirmModal(true)}
                approvalAndValidity={<SupplierDocumentApprovalAndValidity actions={{
                    ...actions,
                    remove: () => setShowDeleteDocumentConfirmModal(true)
                }} readOnly={readOnly} linkedSupplier={linkedSupplier} fromAuditReport={fromAuditReport}/>}
                itemsList={coveredProductionUnit}
                contextMenu={!readOnly && (actions.archive || actions.remove) &&
                    <ContextMenu
                        trigger={
                            <Button size={Sizes.XSmall} ghost className='bg-transparent p-0'>
                                <MicroInteractionContextMenu className='text-white text-2xl hover:text-gray-300' />
                                <span className='visually-hidden'><FormattedMessage id='context_menu.trigger.hidden_label' /></span>
                            </Button>
                        }
                        overflowItems={contextMenuItems}
                    />
                }
                responsibles={<DocumentResponsibles documentationType={documentationType}/>}
            />
            <DeleteDocumentConfirmModal
                open={showDeleteDocumentConfirmModal}
                close={() => setShowDeleteDocumentConfirmModal(false)}
                archiveDocument={documentViewer?.extended_status === SupplierDocumentExtendedStatusEnum.Archived ? null : actions?.archive}
                deleteDocument={actions?.remove}
            />
        </>
    }, [showingDocumentViewer, documentViewer, linkedSupplier, readOnly, showDeleteDocumentConfirmModal]);
    
}
export default SupplierDocumentViewer;
